import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SwitchButton } from './SwitchButton';
import { SelectKaizenVenue } from './SelectKaizenVenue';
import { AddEmployeeButton } from './AddEmployeeButton';
import { EmployeeSelect } from './EmployeeSelect';
import { RoleSelect } from './RoleSelect';
import { NotificationButton } from './NotificationButton';
import { DeleteEmployeeButton } from './DeleteEmployeeButton';

import {
    getKaizenRole,
    getKaizenUsers,
    getKaizenVenues,
    bms_kaizen_venues as bms_kaizen_venues_state,
    bms_kaizen_users as bms_kaizen_users_state,
    bms_kaizen_roles as bms_kaizen_roles_state,
} from '../../../../../redux/slices/bms/bmsSliceVenues';

export const KaizenSection = ({ setVenue, venue }) => {
    const dispatch = useDispatch();
    const bms_kaizen_venues = useSelector(bms_kaizen_venues_state);
    const bms_kaizen_users = useSelector(bms_kaizen_users_state);
    const bms_kaizen_roles = useSelector(bms_kaizen_roles_state);

    const [roleOptions, setRoleOptions] = useState([]);
    const [employeesOption, setEmployeesOption] = useState([]);
    const [chief, setChief] = useState(false);

    useEffect(() => {
        if (!bms_kaizen_venues.length) {
            dispatch(getKaizenVenues());
        }
        if (!bms_kaizen_roles.length) {
            dispatch(getKaizenRole());
        }
        if (venue.kaizen_venue !== '') {
            dispatch(getKaizenUsers({ venue_id: venue.kaizen_venue?.value }));
        } else {
            dispatch(getKaizenUsers({ venue_id: null }));
        }
    }, []);

    useEffect(() => {
        if (venue.newVenue) {
            dispatch(getKaizenUsers({ venue_id: null }));
        }
    }, [venue.newVenue]);

    useEffect(() => {
        if (roleOptions.length === 0 && bms_kaizen_roles.length > 0) {
            setRoleOptions(bms_kaizen_roles);
        }
        const chiefEngineer = venue.employees.find(employee => employee.employee_role_id?.value === 2);
        if (!chief && chiefEngineer && roleOptions.length > 0) {
            const chiefIndex = roleOptions.findIndex(el => el.value === 2);
            const newRoles = [...roleOptions];
            newRoles.splice(chiefIndex, 1);

            setRoleOptions(newRoles);
            setChief(true);
        } else if (!chiefEngineer) {
            setRoleOptions(bms_kaizen_roles);
            setChief(false);
        }
    }, [venue.employees, roleOptions, bms_kaizen_roles]);

    useEffect(() => {
        if (!venue.employees.length) {
            setEmployeesOption(bms_kaizen_users);
        } else {
            const acceptEmployeesId = venue.employees.map(employee => employee.employee_data.value);
            setEmployeesOption(bms_kaizen_users.filter(employee => !acceptEmployeesId.includes(employee.value)));
        }
    }, [bms_kaizen_users, venue.employees]);

    const setKaizenVenue = val => {
        setVenue({ ...venue, kaizen_venue: val, employees: [] });
        if (val) {
            dispatch(getKaizenUsers({ venue_id: val.value }));
        }
    };

    const addEmployeeHandler = () => {
        const employee_state = {
            employeeId: Date.now(),
            employee_data: '',
            employee_role_id: '',
            notification: [],
        };
        setVenue({
            ...venue,
            employees: [...venue.employees, employee_state],
        });
    };

    const setEmployee = (val, id) => {
        const employees = venue.employees.map(employee =>
            employee.employeeId === id
                ? { ...employee, employee_data: { value: val.value, label: val.label }, employee_role_id: val.role.value ? { ...val.role } : '' }
                : employee
        );

        setVenue({ ...venue, employees });
    };

    const setEmployeeRole = (val, id) => {
        const employees = venue.employees.map(employee => (employee.employeeId === id ? { ...employee, employee_role_id: val } : employee));
        setVenue({ ...venue, employees });
    };

    const setEmployeeNotification = (type, id) => {
        let notification;

        const employees = venue.employees.map(employee => {
            if (employee.employeeId === id) {
                if (employee.notification.indexOf(type) === -1) {
                    notification = [...employee.notification, type];
                } else {
                    notification = [...employee.notification];
                    notification.splice(notification.indexOf(type), 1);
                }
                return { ...employee, notification: notification };
            } else {
                return employee;
            }
        });

        setVenue({ ...venue, employees });
    };

    const removeEmployee = id => {
        const employees = venue.employees.filter(employee => employee.employeeId !== id);
        setVenue({ ...venue, employees });
    };

    return (
        <div className="mt-5">
            <div className="flex justify-center">
                <SwitchButton title="Выбрать объект из списка" venue={venue} setVenue={setVenue} newVenue={false} />
                <SwitchButton title="Создать новый объект" venue={venue} setVenue={setVenue} newVenue={true} />
            </div>

            <div>
                {/* object */}
                {!venue.newVenue && <SelectKaizenVenue venue={venue} callback={setKaizenVenue} options={bms_kaizen_venues} />}

                {/* executors */}
                <div>
                    <AddEmployeeButton disabled={!venue.kaizen_venue && !venue.newVenue} title="Добавить сотрудника" callback={addEmployeeHandler} />

                    <div>
                        {venue.employees.map(employee => (
                            <div key={employee.employeeId} className="flex my-3 items-center">
                                <EmployeeSelect options={employeesOption} employee={employee} callback={setEmployee} />

                                <RoleSelect options={roleOptions} employee={employee} callback={setEmployeeRole} />

                                <div className="flex flex-col text-xs ml-3">
                                    <NotificationButton title="e-mail" callback={setEmployeeNotification} employee={employee} />
                                    <NotificationButton title="push" callback={setEmployeeNotification} employee={employee} />
                                </div>
                                <div>
                                    <DeleteEmployeeButton title="удалить" callback={removeEmployee} employee={employee} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
