import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../api/functions';
import { bmsAPI } from '../../../api/modules/bmsAPI';
import { store } from '../../store.ts';
import { setUserFullName, update_user_roles } from '../loginSlice.js';
import { add_bms_venueName_in_server, remove_bms_venue_from_server } from './bmsSliceServers.js';

export const bmsSliceVenues = createSlice({
    name: 'bmsVenues',

    initialState: {
        bms_venues_list: [],
        bms_kaizen_roles: [],
        bms_kaizen_users: [],
        bms_kaizen_venues: [],
        venues_fetching: false,
        user_info_fetching: true,
    },

    reducers: {
        set_bms_kaizen_roles: (state, action) => {
            state.bms_kaizen_roles = action.payload;
        },
        set_bms_kaizen_users: (state, action) => {
            state.bms_kaizen_users = action.payload;
        },
        set_bms_venues_list: (state, action) => {
            state.bms_venues_list = action.payload;
        },
        add_bms_venue: (state, action) => {
            state.bms_venues_list = [...state.bms_venues_list, action.payload];
        },
        remove_bms_venue: (state, action) => {
            state.bms_venues_list = state.bms_venues_list.filter(venue => venue.id !== action.payload);
        },
        edit_bms_venue: (state, action) => {
            state.bms_venues_list = state.bms_venues_list.map(venue => (venue.id === action.payload.id ? action.payload : venue));
        },
        set_bms_kaizen_venues: (state, action) => {
            state.bms_kaizen_venues = action.payload;
        },
        set_venues_fetching: (state, action) => {
            state.venues_fetching = action.payload;
        },
        set_user_info_fetching: (state, action) => {
            state.user_info_fetching = action.payload;
        },
    },
});

export const {
    add_bms_venue,
    edit_bms_venue,
    remove_bms_venue,
    set_venues_fetching,
    set_bms_venues_list,
    set_bms_kaizen_roles,
    set_bms_kaizen_users,
    set_bms_kaizen_venues,
    set_user_info_fetching,
} = bmsSliceVenues.actions;

// получение ролей пользователей
export const getKaizenRole = () => async dispatch => {
    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;

    try {
        const response = await bmsAPI.getKaizenRole.fetch(username, auth_key_calendar);

        if (response.status === 200) {
            const result = await response.json();

            if (result.code === 0) {
                const kaizenRoles = result.data.map(el => ({ value: el.id, label: el.name }));
                dispatch(set_bms_kaizen_roles(kaizenRoles));
            }
        }
    } catch (e) {
        toast.error(`Method getKaizenRole \n ${e}`, toastOptions);
    }
};

// Получение списка ролей
export const getUserInfo = () => async dispatch => {
    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;

    try {
        const response = await bmsAPI.getUserInfo.fetch(username, auth_key_calendar);

        if (response.status === 200) {
            const res = await response.json();

            if (res.code === 0) {
                dispatch(
                    update_user_roles({
                        bms: {
                            role: res.data.role,
                            actions: res.data.can,
                        },
                    })
                );
                dispatch(setUserFullName(`${res.data.fname} ${res.data.iname}`));
            } else {
                toast.error(res.msg, toastOptions);
            }
        }
    } catch (e) {
        toast.error(`Method getUserInfo \n ${e}`, toastOptions);
    } finally {
        dispatch(set_user_info_fetching(false));
    }
};

// получение пользователей кайдзен для назначения в объекте
export const getKaizenUsers =
    ({ venue_id }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.getKaizenUsers.fetch(username, auth_key_calendar, venue_id);

            if (response.status === 200) {
                const result = await response.json();
                if (result.code === 0) {
                    const kaizenUsers = result.data.map(el => ({
                        value: el.id,
                        label: `${el.last_name} ${el.first_name} ${el.middle_name}`,
                        role: {
                            value: el.role_id,
                            label: el.role_name,
                        },
                    }));
                    dispatch(set_bms_kaizen_users(kaizenUsers));
                }
            }
        } catch (e) {
            toast.error(`Method getKaizenUsers \n ${e}`, toastOptions);
        }
    };

// Получение списка объектов
export const getBMSUserVenuesList = () => async dispatch => {
    dispatch(set_venues_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;

    try {
        const response = await bmsAPI.getBMSUserVenues.fetch(username, auth_key_calendar, lang);

        if (response.status === 200) {
            const res = await response.json();

            if (res.code === 0) {
                const venues = res.data.map(venue => {
                    const servers = venue.servers.map(server => ({ label: server.title, value: server.id }));
                    return { id: venue.id, title: venue.title, servers, ...JSON.parse(venue.data) };
                });
                dispatch(set_bms_venues_list(venues));
            } else {
                toast.error(res.msg, toastOptions);
            }
        }
    } catch (e) {
        toast.error(`Method getBMSUserVenuesList \n ${e}`, toastOptions);
    } finally {
        dispatch(set_venues_fetching(false));
    }
};

// получение объектов доступных для привязки бмс к кайдзен
export const getKaizenVenues = () => async dispatch => {
    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;

    try {
        const response = await bmsAPI.getBMSVenues.fetch(username, auth_key_calendar, lang);

        if (response.status === 200) {
            const result = await response.json();

            if (result.code === 0) {
                const kaizenVenues = result.data.map(el => ({ value: el.id, label: el.name }));
                dispatch(set_bms_kaizen_venues(kaizenVenues));
            }
        }
    } catch (e) {
        toast.error(`Method getKaizenVenues \n ${e}`, toastOptions);
    }
};

// Создание объекта
export const addBMSVenue =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        const lang = store.getState().login.lang;

        try {
            const newData = { ...data, servers: data.servers.map(server => server.value) };
            const response = await bmsAPI.addBMSVenue.fetch(username, auth_key_calendar, newData, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    const { kaizen_venue, newVenue } = res.data;
                    dispatch(
                        add_bms_venue({
                            ...data,
                            id: res.data.id,
                            kaizen_venue,
                            newVenue,
                        })
                    );

                    const newData = { title: data.title, servers: data.servers.map(el => el.value) };
                    dispatch(add_bms_venueName_in_server(newData));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method addBMSVenue \n ${e}`, toastOptions);
        }
    };

// Редактирование объекта
export const editBMSVenue =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const newData = { ...data, servers: data.servers.map(server => server.value) };
            const response = await bmsAPI.editBMSVenue.fetch(username, auth_key_calendar, newData);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(edit_bms_venue(data));

                    const newData = { title: data.title, servers: data.servers.map(el => el.value) };
                    dispatch(add_bms_venueName_in_server(newData));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method editBMSVenue \n ${e}`, toastOptions);
        }
    };

// Удаление объекта
export const removeBMSVenue =
    ({ id, title }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.removeBMSVenue.fetch(username, auth_key_calendar, id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(remove_bms_venue(id));
                    dispatch(remove_bms_venue_from_server(title));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method addBMSVenue \n ${e}`, toastOptions);
        }
    };

export const bms_venues_list = state => state.bmsVenues.bms_venues_list;
export const venues_fetching = state => state.bmsVenues.venues_fetching;
export const bms_kaizen_roles = state => state.bmsVenues.bms_kaizen_roles;
export const bms_kaizen_users = state => state.bmsVenues.bms_kaizen_users;
export const bms_kaizen_venues = state => state.bmsVenues.bms_kaizen_venues;
export const userInfoFetchingState = state => state.bmsVenues.user_info_fetching;

export default bmsSliceVenues.reducer;
