import { useTranslation } from 'react-i18next';

import { SubHeader } from '../../common/subheader/SubHeader';
import { SubHeaderLeft } from '../../common/subheader/SubHeaderLeft';
import { Title } from '../../common/subheader/Title';

export const Header = () => {
    const { t } = useTranslation();

    return (
        <SubHeader>
            <SubHeaderLeft>
                <Title>{t('NEWS_AND_POLLS')}</Title>
            </SubHeaderLeft>
        </SubHeader>
    );
};
