import ReactDatePicker from 'react-datepicker';

export const CalendarSelect = ({ data, setData }) => {
    const [startDate, endDate] = data.calendar;

    return (
        <div className="flex items-center text-sm chartCalendar">
            <span className="mr-2">Календарь</span>
            <ReactDatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={update => {
                    if (!update[0]) {
                        setData({ ...data, calendar: [] });
                    } else {
                        setData({ ...data, calendar: update });
                    }
                }}
                isClearable={true}
                dateFormat="dd.MM.yyyy"
                placeholderText="Период дат"
                // отключил проверку отключения календаря от маленького шага
                // className={`${data.step.value <= 4 ? '!cursor-default' : '!cursor-pointer'} `}
                // disabled={data.step.value <= 4 ? true : false}
            />
            <div className="ml-2" data-tip="Доступен при выборе интервала от 1 часа">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                        stroke="#1890FF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </div>
    );
};
