import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import DatePickerReact from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';

export const DatePicker = ({ selectedDate = null, callback, minDate = null, name = 'datepicker', showTimeSelect = false, disabled = false }) => {
    const [cookie] = useCookies();
    const { t } = useTranslation();

    const [date, setDate] = useState(null);

    const lang = useMemo(() => cookie.i18next, [cookie]);

    useEffect(() => {
        setDate(selectedDate);
    }, [selectedDate]);

    return (
        <DatePickerReact
            name={name}
            dateFormat={showTimeSelect ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'}
            disabledKeyboardNavigation
            selected={date}
            onChange={value => {
                setDate(value);
                callback(value);
            }}
            minDate={minDate}
            locale={lang === 'ru' ? ru : en}
            onKeyDown={e => {
                e.preventDefault();
            }}
            showTimeSelect={showTimeSelect}
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption={t('TIME')}
            readOnly={disabled}
        />
    );
};
