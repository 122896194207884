import { NewsAndAgreementsSection } from './sections/newsAndAgreementsSection/NewsAndAgreementsSection';
import { PollsSection } from './sections/pollsSection/PollsSection';
import { ReportsSection } from './sections/reportsSection/ReportsSection';

export const MainSection = ({ activeSectionId, setItem }) => {
    return (
        <>
            {activeSectionId === 1 && <NewsAndAgreementsSection setItem={setItem} />}
            {activeSectionId === 2 && <PollsSection setItem={setItem} />}
            {activeSectionId === 3 && <ReportsSection />}
        </>
    );
};
