import { useState, useEffect, useMemo, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Popup } from '../../../../common/popup/Popup';
import { ButtonRectangular } from '../../../../common/btn/ButtonRectangular';
import { NodeStatusForm } from './nodeStatusForm/NodeStatusForm';
import { PopupHead } from './PopupHead';
import { PopupTitle } from './PopupTitle';
import { NodeDataOutput } from './NodeDataOutput';
import { NodeDataText } from './NodeDataText';
import { toastOptions, checkDataPointValue, throwException } from '../../../../../api/functions';
import { SelectNodeStatus } from './SelectNodeStatus';
import { NodeStatusFormNew } from './nodeStatusForm/NodeStatusFormNew';
import { WithoutDataPointCheckbox } from './WithoutDataPointCheckbox';

import {
    addBMSDocNode,
    add_bms_document_node,
    getBMSNodesList,
    updateNodeData,
    nodes_list as nodes_list_state,
    checkCopyNode,
} from '../../../../../redux/slices/bms/bmsSliceDocuments';

const position = {
    left: 0,
    top: 0,
    leftPercent: 0,
    topPercent: 0,
    height: 'auto',
    width: 'auto',
    degree: 0,
    fontSize: '16px',
};
export const CreateNodePopup = ({ setIsOpen, node, docId }) => {
    const { lib_data } = node;
    const { states } = node.lib_data;

    const dispatch = useDispatch();
    const nodes_list = useSelector(nodes_list_state);

    const [libElemTitle, setLibElemTitle] = useState(node.name);
    const [nodeStates, setNodeStates] = useState(node.data?.states ? node.data.states : []);
    const [disabled, setDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [checkPopupFields, setCheckPopupFields] = useState(false);
    const [isUseWithoutDataPoint, setIsUseWithoutDataPoint] = useState(
        node.data?.isUseWithoutDataPoint ? node.data.isUseWithoutDataPoint : false
    );

    useEffect(() => {
        if (node.data && node.data.isNew) {
            dispatch(checkCopyNode(node.cn_id));
        }
    }, []);

    useEffect(() => {
        if (!nodes_list.length) {
            dispatch(getBMSNodesList({ docId }));
        }
    }, [nodes_list]);

    useEffect(() => {
        if (isUseWithoutDataPoint) {
            setDisabled(false);
            return;
        } else {
            setDisabled(true);
        }
        // проверка элемента без состояний
        if (states.length === 0 && libElemTitle.length > 0 && !lib_data.hasOwnProperty('subtype')) {
            setDisabled(false);
            // проверка элемента с состояниями
        } else if (states.length !== 0 && libElemTitle.length > 0 && checkPopupFields) {
            setDisabled(false);
            // проверка элемента вывода и текста
        } else if (
            states.length === 0 &&
            libElemTitle.length > 0 &&
            lib_data.hasOwnProperty('subtype') &&
            checkPopupFields
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [libElemTitle, checkPopupFields, isUseWithoutDataPoint]);

    const nodeOptions = useMemo(
        () =>
            nodes_list.length
                ? nodes_list.map(node => {
                      return {
                          value: node.id,
                          label: node.name,
                          datatype: node.datatype,
                          value_from_data_point: node.value,
                          bitsize: node.bitsize,
                      };
                  })
                : [],
        [nodes_list]
    );

    const clickHandler = async () => {
        try {
            setIsLoading(true);

            // проверка одинаковых значений у точки данных/проверка соответствия min и max
            const error = checkDataPointValue(nodeStates);
            if (error) {
                throwException(error);
            }

            const data = {
                title: libElemTitle,
                states: nodeStates,
                position,
                write_point:
                    nodeStates.length > 0 && nodeStates[0].hasOwnProperty('writePoint')
                        ? nodeStates[0].writePoint.value
                        : '',
                isUseWithoutDataPoint,
            };

            const nodeObj = {
                doc_id: docId,
                node_id: node.id,
                data: JSON.stringify(data),
            };

            if (node.hasOwnProperty('cn_id')) {
                const nodesArr = [];
                nodesArr.push({
                    ...node,
                    title: libElemTitle,
                    data: JSON.stringify(data),
                    position: JSON.stringify(node.position),
                });

                const result = await dispatch(updateNodeData({ nodesArr }));

                if (result.error) {
                    throwException('Что-то пошло не так. Пожалуйста, обновите страницу и попробуйте еще раз');
                }
            } else {
                const { cn_id, width, height } = await dispatch(addBMSDocNode({ nodeObj }));
                const resultNode = {
                    cn_id,
                    data,
                    title: libElemTitle,
                    lib_data: node.lib_data,
                    lib_title: node.title,
                    name: data.title,
                    position: { ...position, width: `${width}px`, height: `${height}px`, zIndex: 0 },
                };

                dispatch(add_bms_document_node(resultNode));
            }

            setIsOpen(false);
        } catch (error) {
            toast.error(error.message, toastOptions);
        } finally {
            setIsLoading(false);
        }
    };

    if (!lib_data.default_pic) {
        return (
            <Popup isOpen={true} setIsOpen={setIsOpen} className="w-[750px]">
                <div className="text-center p-4">Элемент без изображения добавить на холст нельзя</div>
            </Popup>
        );
    }
    return (
        <Popup
            isOpen={true}
            setIsOpen={setIsOpen}
            className={`w-[1400px] max-w-[90%] max-h-[600px] overflow-auto px-4 py-6 ${lib_data.hasOwnProperty('subtype') && lib_data.subtype === 'output' ? '' : 'overflow-auto'}`}
        >
            <PopupTitle title={node.title} setLibElemTitle={setLibElemTitle} />

            {states.length !== 0 && (
                <WithoutDataPointCheckbox
                    isUseWithoutDataPoint={isUseWithoutDataPoint}
                    setIsUseWithoutDataPoint={setIsUseWithoutDataPoint}
                />
            )}

            {!!states.length && (
                <>
                    {!!nodeStates.length && (
                        <div className="flex flex-col gap-4 mb-10">
                            <PopupHead nodeStates={nodeStates} />

                            {/* два варианта, так как есть старые схемы, где нет возможности добавлять состояния */}
                            {nodeStates.map(el =>
                                el.id ? (
                                    <Fragment key={el.id}>
                                        <NodeStatusFormNew
                                            setCheckPopupFields={setCheckPopupFields}
                                            nodeOptions={nodeOptions}
                                            node={el}
                                            statuses={states}
                                            setNodeStates={setNodeStates}
                                            nodeStates={nodeStates}
                                            statusInfo={nodeStates.find(ell => ell.id === el.id)}
                                            isUseWithoutDataPoint={isUseWithoutDataPoint}
                                        />
                                    </Fragment>
                                ) : (
                                    <Fragment key={el.name}>
                                        <NodeStatusForm
                                            setCheckPopupFields={setCheckPopupFields}
                                            nodeOptions={nodeOptions}
                                            node={el}
                                            states={states}
                                            setNodeStates={setNodeStates}
                                            nodeStates={nodeStates}
                                            statusInfo={nodeStates.find(ell => ell.name === el.name)}
                                            isUseWithoutDataPoint={isUseWithoutDataPoint}
                                        />
                                    </Fragment>
                                )
                            )}
                        </div>
                    )}

                    <SelectNodeStatus
                        setNodeStates={setNodeStates}
                        states={states}
                        isDisabled={isUseWithoutDataPoint || nodeStates.length > 9}
                    />
                </>
            )}

            {lib_data.hasOwnProperty('subtype') && lib_data.subtype === 'output' && (
                <NodeDataOutput
                    setNodeStates={setNodeStates}
                    setCheckPopupFields={setCheckPopupFields}
                    nodeOptions={nodeOptions}
                    node={node}
                />
            )}

            {node.lib_data.hasOwnProperty('subtype') && lib_data.subtype === 'text' && (
                <NodeDataText setNodeStates={setNodeStates} setCheckPopupFields={setCheckPopupFields} node={node} />
            )}

            <ButtonRectangular
                disabled={disabled}
                loading={isLoading}
                className="w-40 shrink-0 ml-auto mr-auto justify-center"
                onClick={clickHandler}
            >
                Применить
            </ButtonRectangular>
        </Popup>
    );
};
