import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { assignWorkerToSlot, resetTasksForSlot, tasksFetchingState, tasksState } from '../../../../redux/slices/hospitalitySlice';
import { toastOptions } from '../../../../api/functions';

import { Droppable } from '../../../common/dnd/Droppable';
import { EditButton } from '../components/editSection/EditButton';
import { EditPopup } from '../components/editSection/EditPopup';
import { List } from '../components/list/List';
import { CleanerSelectionSection } from '../components/cleanerSelectionSection/CleanerSelectionSection';
import { Loader } from '../../../common/Loader';
import { SvgComponent } from '../../../common/svgComponent/SvgComponent';

export const CleanerWithTasksSlot = ({ item, isOpenMenu, setIsOpenMenu, isOpenCleanerSelection, setIsOpenCleanerSelection, activeMenuRef }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const tasks = useSelector(tasksState);
    const tasksFetching = useSelector(tasksFetchingState);

    const cleanerTasks = useMemo(() => tasks.filter(task => task.slotId === item.id), [tasks, item]);

    const [isOpenTasks, setIsOpenTasks] = useState(false);
    const [assignWorkerToSlotFetching, setAssignWorkerToSlotFetching] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const clearTasksHandler = async () => {
        setIsLoading(true);

        const res = await dispatch(resetTasksForSlot({ id: item.id }));

        if (res.error) {
            toast.error(res.message, toastOptions);
        }

        setIsLoading(false);
        setIsOpenMenu(null);
    };

    const setCleanerHandler = async e => {
        setAssignWorkerToSlotFetching(true);
        setIsOpenCleanerSelection(null);
        const res = await dispatch(assignWorkerToSlot({ userId: e.currentTarget.value, slotId: item.id }));
        setAssignWorkerToSlotFetching(false);

        if (res.error) {
            toast.error(res.messageKey ? t(res.messageKey) : res.message, toastOptions);
        }
    };

    return (
        <div
            className={`w-56 bg-white relative rounded box-border ${isOpenTasks ? 'shadow-3xl' : ''} ${isOpenCleanerSelection === item.id || isOpenTasks ? 'h-fit' : 'h-28'} ${isOpenMenu === item.id || isOpenCleanerSelection === item.id ? 'z-20' : 'z-10'}`}
        >
            <Droppable id={item.id}>
                <div className="w-full h-28 pl-3 flex flex-col">
                    <div className="flex flex-col border-b border-b-gray-10 h-[72px] pr-3 pt-3 shrink-0">
                        <div className="flex flex-row items-center w-full">
                            {assignWorkerToSlotFetching ? (
                                <Loader width={12} height={12} />
                            ) : item.supervisorName ? (
                                <div className="w-full font-semibold text-xs text-gray-20 line-clamp-1">{item.supervisorName}</div>
                            ) : (
                                <div className="w-full font-medium text-xs text-gray-500 line-clamp-1">{t('HOSPITALITY_SUPERVISOR')}</div>
                            )}

                            <div className="h-full shrink-0" ref={isOpenMenu === item.id ? activeMenuRef : null}>
                                <EditButton onClick={() => setIsOpenMenu(isOpenMenu !== item.id ? item.id : null)} />

                                <EditPopup isOpen={isOpenMenu === item.id}>
                                    <button
                                        className="flex justify-between items-center text-xs text-left px-3 py-2 whitespace-nowrap hover:text-blue"
                                        onClick={clearTasksHandler}
                                        disabled={isLoading}
                                    >
                                        {t('HOSPITALITY_CLEAR_REQUESTS')}

                                        {isLoading && <Loader width={14} height={14} />}
                                    </button>
                                </EditPopup>
                            </div>
                        </div>

                        <button
                            className="flex flex-row justify-start items-center w-full h-full gap-2 text-gray-500"
                            onClick={() => {
                                setIsOpenTasks(false);
                                setIsOpenCleanerSelection(item.id);
                            }}
                        >
                            <div className="shrink-0 w-3.5 h-3.5">
                                <SvgComponent name="search" />
                            </div>

                            {assignWorkerToSlotFetching ? (
                                <Loader width={12} height={12} />
                            ) : item.cleanerName ? (
                                <span className="font-semibold text-xs text-gray-20 line-clamp-1 text-left">{item.cleanerName}</span>
                            ) : (
                                <span className="font-medium text-xs text-gray-500 line-clamp-1 text-left">{t('HOSPITALITY_ADD_MAID')}</span>
                            )}
                        </button>
                    </div>

                    <button className="w-full h-full flex flex-row items-center text-blue" onClick={() => setIsOpenTasks(!isOpenTasks)}>
                        <div className="flex text-xs">
                            {t('HOSPITALITY_REQUESTS')}:{' '}
                            {tasksFetching ? (
                                <div className="ml-1">
                                    <Loader height={12} width={12} />
                                </div>
                            ) : (
                                cleanerTasks.length
                            )}
                        </div>

                        <div className="w-4 h-4 ml-auto mr-3">
                            <SvgComponent name="chevron-down" />
                        </div>
                    </button>
                </div>

                <List isOpen={isOpenTasks} setIsOpen={setIsOpenTasks} items={cleanerTasks} itemsTitle={t('HOSPITALITY_REQUESTS')} loading={tasksFetching} />
            </Droppable>

            <CleanerSelectionSection
                setCleanerHandler={setCleanerHandler}
                isOpen={isOpenCleanerSelection === item.id}
                setClose={() => setIsOpenCleanerSelection(null)}
                cleanerId={item.cleanerId}
            />
        </div>
    );
};
