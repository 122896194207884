import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '../../../../common/Loader';
import { LibraryDirection } from './LibraryDirection';
import { getBMSLib, lib as lib_state, lib_fetching as lib_fetching_state } from '../../../../../redux/slices/bms/bmsSliceDocuments';

export const LibrarySection = ({ itemLibraryHandler }) => {
    const dispatch = useDispatch();

    const lib = useSelector(lib_state);
    const lib_fetching = useSelector(lib_fetching_state);

    useEffect(() => {
        dispatch(getBMSLib());
    }, [dispatch]);

    return (
        <div className="mt-12">
            <p className="mb-6 uppercase text-xl text-black font-semibold">Библиотека</p>
            {lib_fetching ? (
                <Loader />
            ) : (
                <div className="flex flex-col">
                    {lib.length ? (
                        lib.map(direction => <LibraryDirection key={direction.title} direction={direction} itemLibraryHandler={itemLibraryHandler} />)
                    ) : (
                        <p>Нет элементов в библиотеке</p>
                    )}
                </div>
            )}
        </div>
    );
};
