import { useState, useEffect } from 'react';

import { BitPopup } from '../BitPopup';
import { DataPointSelect } from './DataPointSelect';
import { DataPointPicture } from './DataPointPicture';
import { BitCheckbox } from './BitCheckbox';
import { RangeCheckbox } from './RangeCheckbox';
import { EditBitValueButton } from './EditBitValueButton';
import { ValueInput } from './ValueInput';
import { BooleanValueSelect } from './BooleanValueSelect';
import { ValueMaxInput } from './ValueMaxInput';
import { TitleInput } from './TitleInput';
import { RemoveStatusButton } from './RemoveStatusButton';
import { CopyStatusButton } from './CopyStatusButton';
import { NotificationCheckbox } from './NotificationCheckbox';
import { NodeStatusSelect } from './NodeStatusSelect';

const initState = {
    node: null,
    title: '',
    range: false,
    value: '',
    valueMax: '',
    bit: false,
    bitValue: '',
    operation: '=',
    notifications: {
        push: false,
        email: false,
        application: false,
    },
};

export const NodeStatusFormNew = ({
    setCheckPopupFields,
    nodeOptions,
    node,
    nodeStates,
    setNodeStates,
    statusInfo,
    isUseWithoutDataPoint,
    statuses,
}) => {
    const [nodeStatusInfo, setNodeStatusInfo] = useState(statusInfo ? statusInfo : initState);
    const [selectValue, setSelectValue] = useState([]);
    const [bitPopupIsOpen, setBitPopupIsOpen] = useState(false);

    useEffect(() => {
        if (isUseWithoutDataPoint) {
            setSelectValue([]);
            setNodeStatusInfo(initState);
            setNodeStates([]);
        }
    }, [isUseWithoutDataPoint]);

    useEffect(() => {
        if (statusInfo && nodeOptions && !isUseWithoutDataPoint) {
            setSelectValue(nodeOptions.find(el => el.value === statusInfo.node));
        }
    }, [statusInfo, nodeOptions]);

    useEffect(() => {
        if (selectValue && selectValue.datatype === 'boolean') {
            setNodeStatusInfo({
                ...nodeStatusInfo,
                node: selectValue,
                value: statusInfo.value
                    ? statusInfo.value === 'true'
                        ? { value: 1, label: 'true' }
                        : { value: 0, label: 'false' }
                    : '',
            });
        }
    }, [selectValue]);

    useEffect(() => {
        for (const el of nodeStates) {
            if (el.node && el.node.length !== 0 && el.title.length !== 0) {
                if (!el.bit && !el.range) {
                    if (el.value?.length === 0) {
                        return setCheckPopupFields(false);
                    } else {
                        setCheckPopupFields(true);
                    }
                } else if (el.bit) {
                    if (el.bitValue.length === 0) {
                        return setCheckPopupFields(false);
                    } else {
                        setCheckPopupFields(true);
                    }
                } else if (el.range) {
                    if (el.value.length === 0 || el.valueMax.length === 0) {
                        return setCheckPopupFields(false);
                    } else {
                        setCheckPopupFields(true);
                    }
                }
            } else {
                return setCheckPopupFields(false);
            }
        }
    }, [nodeStates]);

    const handlerInputValueChange = (value, type) => {
        // проверка на ввод числа больше чем с десятыми
        if (nodeStatusInfo.node.datatype === 'float') {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length > 1) {
                return;
            }
        }
        // проверка на целое число
        if (nodeStatusInfo.node.datatype === 'int') {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length >= 1) {
                setNodeStatusInfo({ ...nodeStatusInfo, [type]: floatCheck[0] });
                setNodeStates(() => nodeStates.map(el => (el.id === node.id ? { ...el, [type]: floatCheck[0] } : el)));
                return;
            }
        }

        //   проверка на более двух нулей подряд
        if (value.length > 1 && value[0] === '0' && value[1] !== '.') {
            setNodeStatusInfo({ ...nodeStatusInfo, [type]: value[1] });
            setNodeStates(() => nodeStates.map(el => (el.id === node.id ? { ...el, [type]: value[1] } : el)));
            return;
        }
        // проверка отрицательного числа на ввод более двух нулей подряд
        if (value.length === 3 && value[0] === '-' && value[1] === '0' && value[2] !== '.') {
            const val = `${value[0]}${value[2]}`;

            setNodeStatusInfo({ ...nodeStatusInfo, [type]: val });
            setNodeStates(() => nodeStates.map(el => (el.id === node.id ? { ...el, [type]: val } : el)));
            return;
        }

        setNodeStatusInfo({ ...nodeStatusInfo, [type]: value });
        setNodeStates(() => nodeStates.map(el => (el.id === node.id ? { ...el, [type]: value } : el)));
    };

    const handlerDataPointSelect = val => {
        if (val === null) {
            return;
        }

        setNodeStatusInfo({
            ...nodeStatusInfo,
            ...initState,
            title: nodeStatusInfo.title,
            notifications: nodeStatusInfo.notifications,
            node: val,
            type: val.datatype,
            bitsize: val.bitsize,
        });

        setNodeStates(prevState =>
            prevState.map(el =>
                el.id === node.id
                    ? {
                          ...el,
                          node: val.value,
                          type: val.datatype,
                          bitsize: val.bitsize,
                      }
                    : el
            )
        );
    };

    const handlerCheckboxChange = (type, val) => {
        if (type === 'bit') {
            setNodeStatusInfo({ ...nodeStatusInfo, [type]: val, value: '', valueMax: '', range: false, bitValue: '' });
            setNodeStates(() =>
                nodeStates.map(el =>
                    el.id === node.id ? { ...el, [type]: val, value: '', valueMax: '', range: false, bitValue: '' } : el
                )
            );
            if (val) {
                setBitPopupIsOpen(true);
            } else {
                setBitPopupIsOpen(false);
            }
        } else {
            setNodeStatusInfo({ ...nodeStatusInfo, [type]: val, value: '', valueMax: '', bit: false, bitValue: '' });
            setNodeStates(() =>
                nodeStates.map(el =>
                    el.id === node.id ? { ...el, [type]: val, value: '', valueMax: '', bit: false, bitValue: '' } : el
                )
            );
        }
    };

    const handlerBooleanValueChange = val => {
        setNodeStatusInfo({ ...nodeStatusInfo, val });
        setNodeStates(() => nodeStates.map(el => (el.id === node.id ? { ...el, value: val.label } : el)));
    };

    const handlerTitleChange = val => {
        setNodeStatusInfo({ ...nodeStatusInfo, title: val });
        setNodeStates(() => nodeStates.map(el => (el.id === node.id ? { ...el, title: val } : el)));
    };

    const handlerBitValueChange = (type, val) => {
        if (type === 'setValue') {
            setNodeStatusInfo({ ...nodeStatusInfo, bitValue: val });
            setNodeStates(() => nodeStates.map(el => (el.id === node.id ? { ...el, bitValue: val } : el)));
        } else {
            setNodeStatusInfo({ ...nodeStatusInfo, bit: false });
            setNodeStates(() => nodeStates.map(el => (el.id === node.id ? { ...el, bit: false } : el)));
        }
    };

    const handlerStatusChange = val => {
        setNodeStatusInfo(prevState => ({ ...prevState, pic: val.pic, name: val.name }));

        setNodeStates(prevState =>
            prevState.map(item => (item.id === node.id ? { ...item, pic: val.pic, name: val.name } : item))
        );
    };

    return (
        <div className="flex items-center text-sm">
            <NodeStatusSelect node={node} statuses={statuses} callback={handlerStatusChange} />

            <DataPointPicture node={node} />

            <DataPointSelect
                callback={handlerDataPointSelect}
                nodeOptions={nodeOptions}
                selectValue={selectValue}
                isUseWithoutDataPoint={isUseWithoutDataPoint}
            />

            {/* bit */}
            <BitCheckbox nodeStatusInfo={nodeStatusInfo} callback={handlerCheckboxChange} />

            {/* range */}
            <RangeCheckbox nodeStatusInfo={nodeStatusInfo} callback={handlerCheckboxChange} />

            {/* value/min */}
            <div className="w-[9%] shrink-0 px-1">
                {nodeStatusInfo?.type !== 'boolean' ? (
                    nodeStatusInfo.bit ? (
                        <EditBitValueButton setBitPopupIsOpen={setBitPopupIsOpen} />
                    ) : (
                        <ValueInput nodeStatusInfo={nodeStatusInfo} callback={handlerInputValueChange} />
                    )
                ) : (
                    <BooleanValueSelect nodeStatusInfo={nodeStatusInfo} callback={handlerBooleanValueChange} />
                )}
            </div>

            {/* value max */}
            <ValueMaxInput nodeStatusInfo={nodeStatusInfo} callback={handlerInputValueChange} />

            {/* title */}
            <TitleInput nodeStatusInfo={nodeStatusInfo} callback={handlerTitleChange} />

            <NotificationCheckbox
                nodeStatusInfo={nodeStatusInfo}
                setNodeStatusInfo={setNodeStatusInfo}
                setNodeStates={setNodeStates}
                initState={initState}
                node={node}
                type="application"
            />

            <NotificationCheckbox
                nodeStatusInfo={nodeStatusInfo}
                setNodeStatusInfo={setNodeStatusInfo}
                setNodeStates={setNodeStates}
                initState={initState}
                node={node}
                type="email"
            />

            <NotificationCheckbox
                nodeStatusInfo={nodeStatusInfo}
                setNodeStatusInfo={setNodeStatusInfo}
                setNodeStates={setNodeStates}
                initState={initState}
                node={node}
                type="push"
            />

            <div className="w-[5%] shrink-0 flex justify-end items-center gap-3">
                <CopyStatusButton setNodeStates={setNodeStates} node={node} />
                <RemoveStatusButton setNodeStates={setNodeStates} node={node} />
            </div>

            {/* bits popup */}
            {bitPopupIsOpen && (
                <BitPopup
                    nodeStatusInfo={nodeStatusInfo}
                    setBitPopupIsOpen={setBitPopupIsOpen}
                    callback={handlerBitValueChange}
                />
            )}
        </div>
    );
};
