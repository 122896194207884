export const getVenueStatusColorByServerStatus = (venueId, servers) => {
    const venueServers = servers
        .filter(server => server.objects_list.some(object => object.id === venueId))
        .map(server => server.cur_state);

    // CONNECTION ERROR: 3
    if (venueServers.includes(3)) {
        return 'bms-no-connection';
    }

    // HAVE ALERTS: 5
    if (venueServers.includes(5)) {
        return 'bms-error';
    }

    // ENABLED: 2, CONNECTED: 4
    if (venueServers.includes(2) || venueServers.includes(4)) {
        return 'bms-connected';
    }

    return 'bms-default';
};
