import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { objectsFetchingState, objectsState, positionsState, usersState } from '../../../../../redux/slices/newsAndPollsSlice';
import { addUniqueElements } from '../../../../../api/functions';

import { SvgComponent } from '../../../../common/svgComponent/SvgComponent';
import { Checkbox } from '../../../../ui/checkbox/Checkbox';
import { Loader } from '../../../../common/Loader';

export const ObjectsSection = ({ selectedObjects, setSelectedObjects, setSelectedPositions, setSelectedUsers, isDisabled }) => {
    const { t } = useTranslation();

    const loading = useSelector(objectsFetchingState);
    const objects = useSelector(objectsState);
    const positions = useSelector(positionsState);
    const users = useSelector(usersState);

    const [searchLine, setSearchLine] = useState('');
    const [onlySelected, setOnlySelected] = useState(false);

    const objectsMemo = useMemo(() => objects.filter(item => item.name.toLowerCase().indexOf(searchLine.toLowerCase()) >= 0), [objects, searchLine]);

    const selectAllItemsHandler = e => {
        if (e.target.checked) {
            setSelectedObjects(objectsMemo);
            setSelectedPositions(positions);
            setSelectedUsers(users);
        } else {
            setSelectedObjects([]);
            setSelectedPositions([]);
            setSelectedUsers([]);
        }
    };

    const selectItemHandler = e => {
        const object = objectsMemo.find(el => el.id === e.target.value);

        if (e.target.checked) {
            setSelectedObjects(prevState => [...prevState, object]);

            setSelectedPositions(prevState => [
                ...addUniqueElements(
                    prevState,
                    positions.filter(position => position.objects.includes(object.id)),
                    'id'
                ),
            ]);

            setSelectedUsers(prevState => [
                ...addUniqueElements(
                    prevState,
                    users.filter(user => user.objects.find(obj => obj.id === object.id)),
                    'id'
                ),
            ]);
        } else {
            setSelectedObjects(prevState => prevState.filter(el => el.id !== object.id));

            setSelectedPositions(prevState =>
                prevState.filter(position => [...new Set(selectedObjects.filter(el => el.id !== object.id).flatMap(el => el.positions))].includes(position.id))
            );

            setSelectedUsers(prevState =>
                prevState.filter(user => [...new Set(selectedObjects.filter(el => el.id !== object.id).flatMap(el => el.users))].includes(user.id))
            );
        }
    };

    return (
        <div className="w-[calc(33%-30px)]">
            <div className="mb-3 text-xs text-neutral-900 font-medium">{t('NEWS_AND_POLLS_SELECT_OBJECT')}</div>

            <div className="mb-3 text-xs">
                <Checkbox
                    title={`${t('NEWS_AND_POLLS_SHOW_ONLY_SELECTED')} (${selectedObjects.length})`}
                    onClick={e => {
                        setOnlySelected(e.target.checked);
                    }}
                    checked={onlySelected}
                />
            </div>

            <div className="flex-1 h-[420px] border border-gray-200 rounded overflow-y-auto overflow-x-hidden">
                <div className="sticky top-0 p-1 bg-white">
                    <div className="w-full h-10 relative">
                        <div className="text-gray absolute left-2.5 top-3 w-4 h-4">
                            <SvgComponent name="search" />
                        </div>

                        <input
                            type="text"
                            placeholder={t('NEWS_AND_POLLS_SEARCH_BY_OBJECTS')}
                            className="box-border w-full h-full text-sm rounded border-gray-200 pl-8"
                            value={searchLine}
                            onChange={e => setSearchLine(e.target.value)}
                            disabled={isDisabled}
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-2 px-3 py-2 text-sm">
                    {loading ? (
                        <div className="flex justify-center">
                            <Loader width={24} />
                        </div>
                    ) : (
                        !!objectsMemo.length && (
                            <>
                                {!searchLine.length && !onlySelected && (
                                    <div className="flex items-center py-px">
                                        <Checkbox
                                            name="object"
                                            value="all"
                                            title={t('NEWS_AND_POLLS_ALL_OBJECTS')}
                                            onClick={selectAllItemsHandler}
                                            checked={selectedObjects.length === objectsMemo.length && objectsMemo.length > 0}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                )}

                                {objectsMemo.map(item => {
                                    const isChecked = selectedObjects.findIndex(el => el.id === item.id) > -1;

                                    return (
                                        <div key={item.id} className={`inline-flex items-center py-px ${onlySelected && !isChecked && 'hidden'}`}>
                                            <Checkbox
                                                name="object"
                                                value={item.id}
                                                title={item.name}
                                                checked={isChecked}
                                                onClick={selectItemHandler}
                                                disabled={isDisabled}
                                            />
                                        </div>
                                    );
                                })}
                            </>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};
