export const Output = ({ node }) => {
    let outputValue;
    if (node && node.data.states[0].value && node.data.states[0].value.includes('.') && node.data.states[0].value.split('.').length > 1) {
        outputValue = Number(node.data.states[0].value).toFixed(2);
    } else if (node && node.data.states[0].value && node.data.states[0].value.includes(',') && node.data.states[0].value.split(',').length > 1) {
        outputValue = Number(node.data.states[0].value).toFixed(2);
    } else {
        outputValue = node.data.states[0].value;
    }
    return (
        <div>
            <span>{outputValue}</span>
            <span className="ml-0.5">{node.data.states[0].unit.label ? node.data.states[0].unit.label : node.data.states[0].unitUser}</span>
        </div>
    );
};
