import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getTasksStatuses, tasksStatusesState, tasksStatusesFetchingState } from '../../../../redux/slices/hospitalitySlice';

import { MultiSelect } from '../../../common/widgets/MultiSelect';

export const StatusesFilter = ({ setFilter, value }) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const tasksStatuses = useSelector(tasksStatusesState);
    const tasksStatusesFetching = useSelector(tasksStatusesFetchingState);

    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        if (!tasksStatuses.length) {
            dispatch(getTasksStatuses());
        }
    }, []);

    useEffect(() => {
        if (tasksStatuses && tasksStatuses.length && i18n.language) {
            setStatuses(
                tasksStatuses.map(status => ({
                    value: status.id.toString(),
                    label: t(status.code),
                }))
            );
        }
    }, [tasksStatuses, i18n.language]);

    return (
        <MultiSelect
            options={statuses}
            placeholder={t('HOSPITALITY_REQUEST_STATUS')}
            isLoading={tasksStatusesFetching}
            isDisabled={tasksStatusesFetching}
            callback={val => setFilter({ type: 'statuses', val: val.map(v => v.value).sort((a, b) => a - b) })}
            value={statuses.filter(status => value.includes(status.value))}
        />
    );
};
