export const SimpleDataPointValue = ({ data, setData, setValues }) => {
    const handlerInputChange = (value, type) => {
        // проверка на ввод числа больше чем с десятыми
        if (data.dataPointControl.dataPointControlObj && data.dataPointControl.dataPointControlObj.datatype === 'float' && type.includes('Value')) {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length > 1) {
                return;
            }
        }
        // проверка на целое число
        if (data.dataPointControl.dataPointControlObj && data.dataPointControl.dataPointControlObj.datatype === 'int' && type.includes('Value')) {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length >= 1) {
                setData({ ...data, dataPointControl: { ...data.dataPointControl, [type]: floatCheck[0] } });
                return;
            }
        }

        //   проверка на более двух нулей подряд
        if (type.includes('Value') && value.length > 1 && value[0] === '0' && value[1] !== '.') {
            setValues([{ ...data, dataPointControl: { ...data.dataPointControl, [type]: value[1] } }]);
            setData({ ...data, dataPointControl: { ...data.dataPointControl, [type]: value[1] } });
            return;
        }
        // проверка отрицательного числа на ввод более двух нулей подряд
        if (type.includes('value') && value.length === 3 && value[0] === '-' && value[1] === '0' && value[2] !== '.') {
            const val = `${value[0]}${value[2]}`;

            setData({ ...data, dataPointControl: { ...data.dataPointControl, [type]: val } });
            setValues([{ ...data, dataPointControl: { ...data.dataPointControl, [type]: val } }]);
            return;
        }

        setData({ ...data, dataPointControl: { ...data.dataPointControl, [type]: value } });
        setValues([{ ...data, dataPointControl: { ...data.dataPointControl, [type]: value } }]);
    };
    return (
        <>
            <span className="text-sm">Значение</span>
            <input
                type="number"
                className="h-8 ml-2 text-sm w-44"
                value={data.dataPointControl.dataPointControlValue}
                onChange={e => handlerInputChange(e.target.value, 'dataPointControlValue')}
                disabled={!data.dataPointControl.dataPointControlObj}
            />
        </>
    );
};
