import { FC, ReactNode, RefObject } from 'react';
import { twMerge } from 'tailwind-merge';

import { Loader } from '../../common/Loader';

type Props = {
    type: 'success' | 'info' | 'link' | 'light' | 'cancel';
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => void;
    children: ReactNode;
    width?: number;
    className?: string;
    buttonRef?: RefObject<HTMLButtonElement>;
};

export const Button: FC<Props> = ({ type, onClick, children, disabled = false, loading = false, className, buttonRef = null }) => {
    const paddingButtonClasses = Array.isArray(children) && typeof children[0] === 'object' ? 'pl-4 pr-6' : 'px-6';
    const defaultButtonClasses = 'relative overflow-hidden h-10 text-sm font-medium whitespace-nowrap transition-colors duration-200 rounded-md';
    const disabledButtonClasses = disabled ? 'border-gray-30 hover:border-gray-30 bg-gray-30 hover:bg-gray-30 text-white hover:text-white' : '';
    let typeButtonClasses = '';
    let loaderButtonColors = '';
    let customButtonClasses = className || '';
    let loaderColor = '';

    if (type === 'success') {
        typeButtonClasses = 'text-white bg-green hover:bg-green-600 border border-green hover:border-green-600';
        loaderButtonColors = loading ? 'bg-green-600 border-green-600' : '';
        loaderColor = '#ffffff';
    } else if (type === 'info') {
        typeButtonClasses = 'text-white bg-blue hover:bg-blue-700 border border-blue hover:border-blue-700';
        loaderButtonColors = loading ? 'bg-blue-700 border-blue-700' : '';
        loaderColor = '#ffffff';
    } else if (type === 'link') {
        typeButtonClasses = 'text-gray-500 hover:text-blue';
        loaderColor = '#7e7e7e';
    } else if (type === 'light') {
        typeButtonClasses = 'text-gray-500 bg-white hover:bg-neutral-50 border border-gray-70 hover:border-neutral-300';
        loaderButtonColors = loading ? 'bg-neutral-50 border-neutral-300' : '';
        loaderColor = '#7e7e7e';
    } else if (type === 'cancel') {
        typeButtonClasses = 'text-red-600 bg-white hover:bg-neutral-50 border border-red-300 hover:border-red-600';
        loaderButtonColors = loading ? 'bg-neutral-50 border-red-600' : '';
        loaderColor = '#dc2626';
    }

    const buttonClasses = twMerge(
        defaultButtonClasses,
        paddingButtonClasses,
        customButtonClasses,
        typeButtonClasses,
        loaderButtonColors,
        disabledButtonClasses
    );

    return (
        <button onClick={onClick} disabled={disabled || loading} className={buttonClasses} ref={buttonRef}>
            {loading && (
                <div className="absolute left-0 top-0 flex justify-center items-center w-full h-full">
                    <Loader height={16} width={16} color={disabled ? '#ffffff' : loaderColor} />
                </div>
            )}

            <div className={`flex items-center justify-center gap-2 ${loading ? 'opacity-0' : ''}`}>{children}</div>
        </button>
    );
};
