import { useTranslation } from 'react-i18next';

import { Button } from '../../../ui/button/Button.tsx';

export const CreateTaskButton = ({ setTaskPaneIsOpen }) => {
    const { t } = useTranslation();

    return (
        <Button type="info" onClick={() => setTaskPaneIsOpen(true)}>
            <svg width="20" height="20" viewBox="0 0 20 20" className="fill-current">
                <path d="M4.2168 10.0645C4.2168 9.82292 4.30339 9.61556 4.47656 9.44238C4.64974 9.26921 4.8571 9.18262 5.09863 9.18262H9.125V5.16309C9.125 4.92155 9.20931 4.71419 9.37793 4.54102C9.54655 4.36784 9.75391 4.28125 10 4.28125C10.2415 4.28125 10.4489 4.36784 10.6221 4.54102C10.7952 4.71419 10.8818 4.92155 10.8818 5.16309V9.18262H14.9082C15.1452 9.18262 15.3503 9.26921 15.5234 9.44238C15.6966 9.61556 15.7832 9.82292 15.7832 10.0645C15.7832 10.3105 15.6966 10.5202 15.5234 10.6934C15.3503 10.862 15.1452 10.9463 14.9082 10.9463H10.8818V14.9727C10.8818 15.2096 10.7952 15.4147 10.6221 15.5879C10.4489 15.7611 10.2415 15.8477 10 15.8477C9.75391 15.8477 9.54655 15.7611 9.37793 15.5879C9.20931 15.4147 9.125 15.2096 9.125 14.9727V10.9463H5.09863C4.8571 10.9463 4.64974 10.862 4.47656 10.6934C4.30339 10.5202 4.2168 10.3105 4.2168 10.0645Z" />
            </svg>

            {t('HOSPITALITY_CREATE_TASK')}
        </Button>
    );
};
