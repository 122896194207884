import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setReportsFilter } from '../../../../redux/slices/newsAndPollsSlice';

import { SvgComponent } from '../../../common/svgComponent/SvgComponent';

export const ReportsSearchSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [searchLine, setSearchLine] = useState('');
    const [isTouched, setIsTouched] = useState(false);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (isTouched) {
                dispatch(setReportsFilter({ searchLine }));
            }
        }, 500);

        return () => clearTimeout(timeOutId);
    }, [searchLine]);

    return (
        <div className="relative w-44 h-10">
            <div className="text-gray absolute left-2.5 top-3 w-4 h-4">
                <SvgComponent name="search" />
            </div>

            <input
                type="text"
                placeholder={t('SEARCH')}
                className="box-border w-full h-full text-sm rounded bg-gray-100 border-gray-10 pl-9"
                value={searchLine}
                onChange={e => {
                    setSearchLine(e.target.value);
                    setIsTouched(true);
                }}
            />
        </div>
    );
};
