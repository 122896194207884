import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getRoomStatus } from '../../../../redux/slices/hospitalitySlice';

import { Loader } from '../../../common/Loader';

export const RoomStatus = ({ roomName }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            if (roomName) {
                const res = await dispatch(getRoomStatus({ data: { roomName } }));

                if (res) {
                    setStatus(res);
                } else {
                    setStatus('');
                }
            } else {
                setStatus('');
            }

            setIsLoading(false);
        })();
    }, [roomName]);

    if (isLoading) {
        return (
            <div className="flex flex-row items-center gap-1.5 text-sky-500 text-sm">
                <Loader width={14} height={14} />
                {t('ROOM_STATUS_LOADING')}
            </div>
        );
    }

    return !!status && <div className={`text-sm ${status === 'ROOM_STATUS_CLEAN' ? 'text-green-500' : 'text-red-500'}`}>{t(status)}</div>;
};
