import { useState, useEffect } from 'react';

import { Toggle } from '../../../../common/widgets/Toggle';

export const ToggleControl = ({ control, handlerToggleChange }) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (control.value === control.data.values[0].value_on) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [control]);

    return (
        <div className="flex items-center">
            <span className="mr-2 text-xs">{control.data.values[0].title_off}</span>
            <Toggle
                checked={checked}
                onChange={() => {
                    handlerToggleChange(control, checked);
                }}
            />
            <span className="ml-2 text-xs">{control.data.values[0].title_on}</span>
        </div>
    );
};
