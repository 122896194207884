import { useState, useEffect } from 'react';

import { ButtonRectangular } from '../../../../common/btn/ButtonRectangular';

export const BitPopup = ({ nodeStatusInfo, setBitPopupIsOpen, callback }) => {
    const [bitObj, setBitObj] = useState({});
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (nodeStatusInfo.bitValue) {
            const charArray = nodeStatusInfo.bitValue.split('').reverse();
            const resultObject = {};

            for (let i = 0; i < charArray.length; i++) {
                if (charArray[i] === '*') {
                    resultObject[i.toString()] = '';
                } else {
                    resultObject[i.toString()] = charArray[i];
                }
            }

            setBitObj(resultObject);
        }
    }, [nodeStatusInfo]);

    useEffect(() => {
        if (Object.keys(bitObj).length === 0) {
            return setDisabled(true);
        }

        if (Object.values(bitObj).find(el => el === '1' || el === '0')) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [bitObj]);

    const row = nodeStatusInfo.bitsize / 8;
    const rowArr = new Array(row).fill(0);
    const bitArr = new Array(8).fill(0);

    const bitInputHandler = (val, idx, id) => {
        const regex = /^(?:[01]|)$/;
        const bitKey = `${idx * 8 + id}`;
        if (regex.test(val)) {
            setBitObj({ ...bitObj, ...{ [bitKey]: val } });
        }
    };

    const bitAcceptHandler = () => {
        const str = '*'.repeat(nodeStatusInfo.bitsize);
        const inputArray = str.split('');

        for (const key in bitObj) {
            if (bitObj.hasOwnProperty(key)) {
                const index = parseInt(key);
                if (bitObj[key] !== '') {
                    inputArray[index] = bitObj[key];
                }
            }
        }

        const replacedString = inputArray.reverse().join('');
        callback('setValue', replacedString, nodeStatusInfo?.field);
        setBitPopupIsOpen(false);
    };

    const closePopupHandler = () => {
        if (nodeStatusInfo.bitValue) {
            setBitPopupIsOpen(false);
            return;
        } else {
            callback('cancelValue');
            setBitPopupIsOpen(false);
        }
    };

    return (
        <div className="fixed flex items-center justify-center inset-0 bg-slate-900 bg-opacity-30 z-40 transition-opacity duration-200 opacity-100">
            <div className="absolute w-full h-full z-40" onClick={closePopupHandler}></div>
            <div className="absolute w-[350px] bg-slate-100 z-50 p-4 rounded-lg">
                {/* title */}
                <div>
                    <div className="text-center mb-2">Выбрать бит</div>
                    <button
                        onClick={() => closePopupHandler()}
                        className="absolute right-1 top-1 w-7 h-7 p-0 rounded-full bg-transparent hover:bg-gray-200 flex items-center justify-center"
                    >
                        <svg className="w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"></path>
                        </svg>
                    </button>
                </div>
                {/* bits */}
                <div>
                    {rowArr.map((_, idx) => (
                        <div key={idx} className="flex justify-between">
                            {/* bit */}
                            <div>
                                {idx * 8} .. {8 * (idx + 1) - 1}
                            </div>
                            {/* bit input */}
                            <div className="flex gap-2 mb-2 border-b border-green-500 pb-2">
                                {bitArr.map((_, id) => (
                                    <div
                                        className={`${(id + 1) % 4 === 0 ? 'border-r border-green-500 pr-2' : ''} ${id % 8 === 0 ? 'border-l border-green-500 pl-2' : ''} `}
                                        key={id}
                                    >
                                        <input
                                            value={bitObj[idx * 8 + id] ? bitObj[idx * 8 + id] : ''}
                                            onChange={e => bitInputHandler(e.target.value, idx, id)}
                                            className="p-0 w-5 text-center h-6 text-xs rounded text-black font-bold placeholder:font-normal placeholder:text-gray-30"
                                            type="number"
                                            placeholder={id + idx * 8}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                {/* button */}
                <div>
                    <ButtonRectangular
                        disabled={disabled}
                        onClick={() => bitAcceptHandler()}
                        className="ml-auto mr-auto mt-2 block h-6 py-0 px-2"
                        type="button"
                    >
                        Применить
                    </ButtonRectangular>
                </div>
            </div>
        </div>
    );
};
