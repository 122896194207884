import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { settingsState } from '../../../../redux/slices/hospitalitySlice';

import { MultiSelect } from '../../../common/widgets/MultiSelect';

export const ClearTypesFilter = ({ setFilter, value }) => {
    const { t } = useTranslation();

    const settings = useSelector(settingsState);

    const clearTypes = useMemo(
        () =>
            settings && settings.pms_task_types
                ? settings.pms_task_types.filter(type => type.used && !type.for_supervisor).map(type => ({ value: type.id.toString(), label: type.use_title }))
                : [],
        [settings]
    );

    return (
        <MultiSelect
            options={clearTypes}
            placeholder={t('HOSPITALITY_WORK_TYPES')}
            callback={val => setFilter({ type: 'clearTypes', val: val.map(v => v.value).sort((a, b) => a - b) })}
            value={clearTypes.filter(type => value.includes(type.value))}
        />
    );
};
