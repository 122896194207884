import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { settingsState } from '../../../redux/slices/hospitalitySlice';

const getStatusColor = statusId => {
    switch (statusId) {
        case '2':
            return 'bg-status-new';
        case '3':
            return 'bg-status-at-work';
        case '8':
            return 'bg-status-completed';
        default:
            return 'bg-status-default';
    }
};

export const Card = ({ children, statusId = '', typeId = '', description = '', repeated = false }) => {
    const settings = useSelector(settingsState);

    const [color, setColor] = useState('#b3b3b3');

    useEffect(() => {
        if (typeId || statusId) {
            if (settings && settings.hasOwnProperty('pms_task_types')) {
                const workType = settings.pms_task_types.find(el => el.id.toString() === typeId);

                if (workType) {
                    setColor(workType.color);
                }
            }
        } else {
            setColor('#188fff');
        }
    }, [settings]);

    return (
        <div
            className="flex flex-row items-center gap-2 overflow-hidden w-full h-9 border border-gray-70 rounded-md pr-3 box-border bg-white select-none shrink-0"
            data-tip={description}
        >
            <div className="w-[3px] shrink-0 h-full" style={{ backgroundColor: color }}></div>
            <div className="w-full text-left text-xs line-clamp-1">{children}</div>
            {!!statusId && (
                <div className="shrink-0 flex flex-row items-center gap-4">
                    {repeated && (
                        <svg width="16" height="16" viewBox="0 0 16 16" className="fill-transparent stroke-red-600 stroke-[1.33px]">
                            <path
                                d="M6.86001 2.57347L1.21335 12.0001C1.09693 12.2018 1.03533 12.4303 1.03467 12.6631C1.03402 12.896 1.09434 13.1249 1.20963 13.3272C1.32492 13.5294 1.49116 13.698 1.69182 13.816C1.89247 13.9341 2.12055 13.9976 2.35335 14.0001H13.6467C13.8795 13.9976 14.1076 13.9341 14.3082 13.816C14.5089 13.698 14.6751 13.5294 14.7904 13.3272C14.9057 13.1249 14.966 12.896 14.9654 12.6631C14.9647 12.4303 14.9031 12.2018 14.7867 12.0001L9.14001 2.57347C9.02117 2.37754 8.85383 2.21555 8.65414 2.10313C8.45446 1.9907 8.22917 1.93164 8.00001 1.93164C7.77086 1.93164 7.54557 1.9907 7.34588 2.10313C7.1462 2.21555 6.97886 2.37754 6.86001 2.57347Z"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path d="M8 6V8.66667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8 11.3335H8.00833" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    )}
                    <div className={`w-2.5 h-2.5 rounded-full ${getStatusColor(statusId)}`}></div>
                </div>
            )}
        </div>
    );
};
