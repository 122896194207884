import { createPortal } from 'react-dom';
import { twMerge } from 'tailwind-merge';

export const Popup = ({ children, isOpen, setIsOpen, className }) => {
    const classes = twMerge(
        `relative bg-white z-50 w-[500px] max-w-full box-border rounded-lg flex flex-col p-5 shadow-2xl transition-all duration-200 ease-in-out border border-gray-10 ${className}`
    );

    const closeHandler = () => {
        setIsOpen(false);
    };

    if (!isOpen) {
        return null;
    }

    return createPortal(
        <>
            <div className="fixed flex items-center justify-center inset-0 bg-slate-900 bg-opacity-30 z-60 transition-opacity duration-200 opacity-100">
                <div className="absolute w-full h-full z-40" onClick={closeHandler}></div>
                <div className={classes}>
                    <button
                        onClick={closeHandler}
                        className="absolute right-1 top-1 w-7 h-7 p-0 rounded-full bg-transparent hover:bg-gray-100 flex items-center justify-center"
                    >
                        <svg className="w-5 h-5 fill-current" viewBox="0 0 24 24">
                            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"></path>
                        </svg>
                    </button>
                    {children}
                </div>
            </div>
        </>,
        document.querySelector('#modal-root')
    );
};
