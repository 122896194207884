import { useTranslation } from 'react-i18next';

import { FileSelector } from '../../../ui/fileSelector/FileSelector';
import { DescriptionSection } from '../sections/descriptionSection/DescriptionSection';
import { QuestionsSection } from '../sections/questionsSection/QuestionsSection';

export const Step2 = ({ title, description, file, questions, setTitle, setDescription, setFile, setQuestions, isDisabled }) => {
    const { t } = useTranslation();

    return (
        <div className="w-full flex-col gap-10 flex">
            <div>
                <div className="mb-3 text-neutral-900 text-base font-medium">{t('TITLE')}</div>

                <input
                    type="text"
                    className="h-10 w-full text-sm rounded border-neutral-200"
                    placeholder={t('ENTER_TITLE')}
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    disabled={isDisabled}
                />
            </div>

            <DescriptionSection description={description} setDescription={setDescription} disabled={isDisabled} />

            <div>
                <div className="mb-3 text-neutral-900 text-base font-medium">{t('NEWS_AND_POLLS_POLL_COVER')}</div>

                <FileSelector
                    maxFiles={1}
                    placeholder={t('UPLOAD')}
                    callback={files => {
                        setFile(files[0]);
                    }}
                    initialFiles={file ? [file] : []}
                    accept={{ 'image/jpg': ['.png', '.jpeg', '.jpg', '.webp', '.heic', '.heif'] }}
                    disabled={isDisabled}
                />
            </div>

            <QuestionsSection questions={questions} setQuestions={setQuestions} isDisabled={isDisabled} />
        </div>
    );
};
