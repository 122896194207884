import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { resetTasksForSupervisor, tasksForSupervisorsFetchingState, tasksForSupervisorsState } from '../../../../redux/slices/hospitalitySlice';
import { toastOptions } from '../../../../api/functions';

import { Droppable } from '../../../common/dnd/Droppable';
import { EditButton } from '../components/editSection/EditButton';
import { EditPopup } from '../components/editSection/EditPopup';
import { List } from '../components/list/List';
import { Loader } from '../../../common/Loader';
import { SvgComponent } from '../../../common/svgComponent/SvgComponent';

export const SupervisorWithTasksSlot = ({ id, name, isOpenMenu, setIsOpenMenu, activeMenuRef }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const tasks = useSelector(tasksForSupervisorsState);
    const tasksFetching = useSelector(tasksForSupervisorsFetchingState);

    const supervisorsTasks = useMemo(() => tasks.filter(task => task.userId === id), [tasks, id]);

    const [isOpenTasks, setIsOpenTasks] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const clearTasksHandler = async () => {
        setIsLoading(true);
        const res = await dispatch(resetTasksForSupervisor({ id }));

        if (res.error) {
            toast.error(res.message, toastOptions);
        }

        setIsLoading(false);
        setIsOpenMenu(null);
    };

    return (
        <div className={`w-56 bg-white rounded relative box-border ${isOpenTasks ? 'shadow-3xl h-fit' : 'h-20'} ${isOpenMenu === id ? 'z-20' : 'z-10'}`}>
            <Droppable id={id}>
                <div className="w-full pl-3 flex flex-col">
                    <div className="flex flex-row items-center w-full relative border-b border-b-gray-10 h-10 pr-3">
                        <div className="w-full text-xs font-semibold text-gray-20 line-clamp-1">{name}</div>

                        <div className="h-full shrink-0" ref={isOpenMenu === id ? activeMenuRef : null}>
                            <EditButton onClick={() => setIsOpenMenu(isOpenMenu !== id ? id : null)} />

                            <EditPopup isOpen={isOpenMenu === id}>
                                <button
                                    className="flex justify-between items-center text-xs text-left px-3 py-2 whitespace-nowrap hover:text-blue"
                                    onClick={clearTasksHandler}
                                    disabled={isLoading}
                                >
                                    {t('HOSPITALITY_CLEAR_REQUESTS')}

                                    {isLoading && <Loader width={14} height={14} />}
                                </button>
                            </EditPopup>
                        </div>
                    </div>

                    <button className="w-full h-10 flex flex-row items-center text-blue" onClick={() => setIsOpenTasks(!isOpenTasks)}>
                        <div className="flex text-xs">
                            {t('HOSPITALITY_REQUESTS')}:{' '}
                            {tasksFetching ? (
                                <div className="ml-1">
                                    <Loader height={12} width={12} />
                                </div>
                            ) : (
                                supervisorsTasks.length
                            )}
                        </div>

                        <div className="w-4 h-4 ml-auto mr-3">
                            <SvgComponent name="chevron-down" />
                        </div>
                    </button>
                </div>

                <List isOpen={isOpenTasks} setIsOpen={setIsOpenTasks} items={supervisorsTasks} itemsTitle={t('HOSPITALITY_REQUESTS')} loading={tasksFetching} />
            </Droppable>
        </div>
    );
};
