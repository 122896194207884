import { SimpleSelect } from '../../../../../../../common/widgets/SimpleSelect';
const operandOptions = [
    {
        value: 1,
        label: '=',
    },
    {
        value: 2,
        label: '>',
    },
    {
        value: 3,
        label: '<',
    },
    {
        value: 4,
        label: '>=',
    },
    {
        value: 5,
        label: '<=',
    },
    {
        value: 6,
        label: '!=',
    },
];

export const OperandSelect = ({ data, setData, setValues }) => {
    return (
        <div className="flex items-center">
            <span className="text-sm mr-2">Выберите операнд:</span>
            <SimpleSelect
                isDisabled={
                    !data.dataPointControl.dataPointControlObj ||
                    data.dataPointControl.dataPointControlObj.datatype === 'boolean' ||
                    data.dataPointControl.bit ||
                    data.dataPointControl.range
                }
                isClearable={false}
                options={operandOptions}
                placeholder=""
                value={data.dataPointControl.operand}
                isSearchable={true}
                callback={val => {
                    if (val === null) {
                        return;
                    }
                    setValues([{ ...data, dataPointControl: { ...data.dataPointControl, operand: val } }]);
                    setData({ ...data, dataPointControl: { ...data.dataPointControl, operand: val } });
                }}
                className="w-20 mr-5"
            />
        </div>
    );
};
