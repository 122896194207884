import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../../api/functions';

import { SimpleSelect } from '../../../../ui/simpleSelect/SimpleSelect';

export const ExperienceSection = ({ experience, setExperience, selectedUsers, isDisabled }) => {
    const { t } = useTranslation();
    const [cookies] = useCookies();

    const lang = useMemo(() => cookies.i18next, [cookies]);

    const period = useMemo(
        () => [
            {
                value: '1',
                label: t('DAY'),
            },
            {
                value: '30',
                label: t('MONTH'),
            },
            {
                value: '365',
                label: t('YEAR'),
            },
        ],
        [lang]
    );

    const setExperienceFromHandler = e => {
        setExperience(prevValue => ({ ...prevValue, from: e.target.value ? +e.target.value.replace(/\D/g, '') : '' }));
    };

    const setExperienceToHandler = e => {
        setExperience(prevValue => ({ ...prevValue, to: e.target.value ? +e.target.value.replace(/\D/g, '') : '' }));
    };

    const checkExperienceToHandler = () => {
        if (typeof experience.to === 'number' && typeof experience.from === 'number' && experience.to < experience.from) {
            setExperience(prevValue => ({ ...prevValue, to: '' }));

            toast.error(t('NEWS_AND_POLLS_EXPERIENCE_ERROR'), toastOptions);
        }
    };

    return (
        <div>
            <div className="mb-3 text-xs text-neutral-900 font-medium">{t('NEWS_AND_POLLS_SELECT_EXPERIENCE')}</div>
            <div className="flex flex-row gap-6 items-center">
                <div className="flex flex-row items-center gap-3">
                    <span className="text-sm text-neutral-900">{t('FROM')}</span>
                    <input
                        type="text"
                        className="h-10 w-20 text-sm rounded border-neutral-200"
                        value={experience.from}
                        onChange={setExperienceFromHandler}
                        onBlur={checkExperienceToHandler}
                        disabled={!selectedUsers.length || isDisabled}
                    />
                </div>

                <div className="flex flex-row items-center gap-3">
                    <span className="text-sm text-neutral-900">{t('TO')}</span>
                    <input
                        type="text"
                        className="h-10 w-20 text-sm rounded border-neutral-200"
                        value={experience.to}
                        onChange={setExperienceToHandler}
                        onBlur={checkExperienceToHandler}
                        disabled={!selectedUsers.length || isDisabled}
                    />
                </div>

                <SimpleSelect
                    options={period}
                    value={experience.period ? period.find(el => el.value === experience.period) : period[0]}
                    callback={value => {
                        setExperience(prevValue => ({ ...prevValue, period: value.value }));
                    }}
                    isClearable={false}
                    isDisabled={!selectedUsers.length || isDisabled}
                    className="w-32"
                />
            </div>
        </div>
    );
};
