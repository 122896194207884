import { NavLink, useLocation } from 'react-router-dom';

import { SvgComponent } from '../../svgComponent/SvgComponent';

export const MenuItem = ({ link, svgName = 'check-all', title }) => {
    const { pathname } = useLocation();

    return (
        <NavLink
            to={link}
            className={`flex items-center gap-3 pr-3 pl-4 py-3 transition duration-150 hover:text-blue hover:border-r-2 hover:border-blue hover:bg-blue-10 hover:font-semibold ${
                pathname.includes(link) ? 'text-blue border-r-2 border-blue bg-blue-10 font-semibold' : 'text-gray'
            }`}
        >
            <div className="shrink-0 w-5">
                <SvgComponent name={svgName} />
            </div>

            <div className="text-sm w-full line-clamp-1 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-150">{title}</div>
        </NavLink>
    );
};
