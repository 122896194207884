import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SimpleSelect } from '../../../../../common/widgets/SimpleSelect';
import { documentState as documentState_state } from '../../../../../../redux/slices/bms/bmsSliceDocuments';

export const TriggerControl = ({ nodeOptions, setValues, setWritePoint, setCheckPopupFields, control }) => {
    const documentState = useSelector(documentState_state);

    const [data, setData] = useState({ writePoint: null, value_start: '', duration: '' });

    useEffect(() => {
        try {
            JSON.parse(control.data);
        } catch (e) {
            const values = control.data.values[0];

            setData({
                ...values,
            });
            setValues([...control.data.values]);
            setWritePoint(values.writePoint.value);
        }
    }, [control, nodeOptions]);

    useEffect(() => {
        const valuesArr = Object.values(data);
        const emptyValues = valuesArr.length && valuesArr.filter(el => el?.length === 0);

        if (emptyValues.length > 0) {
            setCheckPopupFields(false);
        } else {
            setCheckPopupFields(true);
        }
    }, [data]);

    const usedWritePoint = documentState.controls.filter(control => control.lib_data.subtype === 'trigger').map(control => control.data.write_point);
    const node_options = nodeOptions.filter(node => !usedWritePoint.includes(node.value));

    const handlerInputChange = (value, type) => {
        // проверка на ввод числа больше чем с десятыми
        if (data.writePoint && data.writePoint.datatype === 'float' && type.includes('value')) {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length > 1) {
                return;
            }
        }
        // проверка на целое число
        if (data.writePoint && data.writePoint.datatype === 'int' && type.includes('value')) {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length >= 1) {
                setData({ ...data, [type]: floatCheck[0] });
                return;
            }
        }

        //   проверка на более двух нулей подряд
        if (value.length > 1 && value[0] === '0' && value[1] !== '.') {
            setValues([{ ...data, [type]: value[1] }]);
            setData({ ...data, [type]: value[1] });
            return;
        }
        // проверка отрицательного числа на ввод более двух нулей подряд
        if (value.length === 3 && value[0] === '-' && value[1] === '0' && value[2] !== '.') {
            const val = `${value[0]}${value[2]}`;

            setData({ ...data, [type]: val });
            setValues([{ ...data, [type]: val }]);
            return;
        }

        setData({ ...data, [type]: value });
        setValues([{ ...data, [type]: value }]);
    };
    return (
        <div className="flex items-end">
            <SimpleSelect
                isClearable={false}
                options={node_options}
                placeholder="Выберите точку"
                value={data.writePoint}
                isSearchable={true}
                callback={val => {
                    if (val === null) {
                        return;
                    }
                    if (val.datatype === 'boolean') {
                        setWritePoint(val.value);
                        setValues([{ ...data, value_start: 'true', duration: '' }]);
                        setData({ value_start: 'true', writePoint: val, duration: '' });
                        return;
                    }
                    setWritePoint(val.value);
                    setValues([]);
                    setData({ value_start: '', duration: '', writePoint: val });
                }}
                className="w-56 mr-5"
            />
            <div className="flex items-center gap-3">
                <div className="flex gap-3">
                    <div className="flex flex-col gap-1">
                        <span className="text-sm">Стартовое значение </span>
                        <input
                            value={data.writePoint?.datatype === 'boolean' ? 'true' : data.value_start}
                            onChange={e => handlerInputChange(e.target.value, 'value_start')}
                            disabled={!data.writePoint || data.writePoint?.datatype === 'boolean'}
                            type={data.writePoint?.datatype === 'boolean' ? 'text' : 'number'}
                            className="h-8 text-sm w-40"
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <span className="text-sm">Задержка в мс</span>

                    <input
                        value={data.duration}
                        onChange={e => handlerInputChange(e.target.value, 'duration')}
                        disabled={!data.writePoint}
                        type="number"
                        className="h-8 text-sm w-40"
                    />
                </div>
            </div>
        </div>
    );
};
