import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { t } from 'i18next';

import { toastOptions } from '../../../api/functions';

import { SvgComponent } from '../../common/svgComponent/SvgComponent';

export const FileSelector = ({ maxFiles = 5, disabled = false, accept = {}, callback = files => {}, placeholder = '', initialFiles = [], maxSize = 20 }) => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (initialFiles.length) {
            setFiles(initialFiles);
        }
    }, [initialFiles]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            if (files.length + acceptedFiles.length > maxFiles) {
                toast.error(t('IMAGE_SELECTOR_MAX_FILES_ERROR', { count: maxFiles }), toastOptions);
                return;
            }

            setFiles(prevState => [
                ...prevState,
                ...acceptedFiles.map((file, index) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        key: `${file.name}${index}`,
                    })
                ),
            ]);
        },
        onDropRejected: fileRejections => {
            fileRejections.forEach(file => {
                file.errors.forEach(error => {
                    let errorMessage = error.message;
                    if (error.code === 'file-too-large') {
                        errorMessage = t('IMAGE_SELECTOR_FILE_TOO_LARGE_ERROR', { count: maxSize });
                    }

                    toast.error(
                        <div>
                            {file.file.name}
                            <br />
                            {errorMessage}
                        </div>,
                        toastOptions
                    );
                });
            });
        },

        disabled,
        maxSize: maxSize * 1048576,
        accept,
        multiple: maxFiles !== 1,
    });

    useEffect(() => {
        callback(files);
    }, [files]);

    const removeFile = key => setFiles(files.filter(file => file.key !== key));

    return (
        <div className="flex flex-row flex-wrap gap-2">
            {files.map(file => (
                <div
                    className="w-28 h-28 p-1 border-2 border-neutral-200 rounded-2 overflow-hidden flex flex-col gap-2 items-center justify-center relative"
                    key={file.key}
                >
                    {file.type.indexOf('image/') >= 0 && !file.type.includes('heic') && !file.type.includes('heif') ? (
                        <img src={file.preview} className="w-full h-full object-cover rounded-1" alt={file.name} />
                    ) : (
                        <>
                            <div className="w-4 h-4">
                                <SvgComponent name="document" />
                            </div>

                            <div className="text-xs text-center w-full line-clamp-2">{file.name}</div>
                        </>
                    )}

                    {!disabled && (
                        <div className="absolute w-full h-full bg-black transition-opacity duration-300 bg-opacity-30 opacity-0 hover:opacity-100 flex items-center justify-center">
                            <button
                                type="button"
                                onClick={() => removeFile(file.key)}
                                className="w-10 h-10 flex items-center justify-center text-white hover:text-red-300"
                                disabled={disabled}
                            >
                                <div className="w-4 h-4">
                                    <SvgComponent name="basket" />
                                </div>
                            </button>
                        </div>
                    )}
                </div>
            ))}

            {(files.length === 0 || maxFiles > 1) && (
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />

                    <div className="flex gap-1 w-28 h-28 p-1 bg-zinc-50 hover:bg-neutral-100 items-center justify-center flex-col text-gray rounded-2 border-2 border-dashed border-neutral-200 cursor-pointer transition-colors duration-300">
                        <div className="w-4 h-4">
                            <SvgComponent name="plus" />
                        </div>

                        <div className="text-xs text-center select-none">{placeholder || t('ADM_PLANNER_ADD_PHOTO_FILE')}</div>
                    </div>
                </div>
            )}
        </div>
    );
};
