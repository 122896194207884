import { useDispatch } from 'react-redux';
import moment from 'moment/moment';

import { ButtonRectangular } from '../../../../common/btn/ButtonRectangular';
import { uploadChartTrends } from '../../../../../redux/slices/bms/bmsSliceDocuments';

export const ReportButton = ({ isDisabled, chartSettings }) => {
    const dispatch = useDispatch();
    const handlerSaveChartReport = () => {
        const data = {
            step: chartSettings.step.value,
            period: chartSettings.period.value,
            calendar: [moment(chartSettings.calendar[0]).format('YYYY-MM-DD'), moment(chartSettings.calendar[1]).format('YYYY-MM-DD')],
            dataPoint: chartSettings.dataPoint.map(el => el.value),
            mode: 'csv',
        };

        // проверка на значения диапазона дат в календаре
        if (chartSettings.calendar.length === 2 && chartSettings.calendar[1]) {
            dispatch(uploadChartTrends({ data }));
        } else {
            if (!(chartSettings.calendar.length === 2 && !chartSettings.calendar[1])) {
                dispatch(uploadChartTrends({ data: { ...data, calendar: [] } }));
            }
        }
    };
    return (
        <ButtonRectangular type="button" className="" disabled={!isDisabled} onClick={handlerSaveChartReport}>
            Сохранить данные графика
        </ButtonRectangular>
    );
};
