import { useTranslation } from 'react-i18next';

import { Button } from '../../ui/button/Button.tsx';

export const SlidingPaneFooter = ({ closePaneHandler, callback, loading = false, children }) => {
    const { t } = useTranslation();

    return (
        <div className="h-16 w-full fixed bottom-0 left-0 bg-white border-t border-t-gray-10 z-20">
            <div className="w-full h-full px-4 flex flex-row items-center">
                {!!children ? (
                    children
                ) : (
                    <>
                        <Button type="success" loading={loading} onClick={callback} width={144}>
                            {t('HOSPITALITY_APPLY')}
                        </Button>

                        <Button type="link" onClick={closePaneHandler} width={144}>
                            {t('HOSPITALITY_CANCEL')}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};
