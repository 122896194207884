import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';

import { useCheckRole } from '../../../hooks/useCheckRole';
import { Loader } from '../../common/Loader';
import {
    getBMSServersList,
    bms_servers_state,
    bms_servers_list as bms_servers_list_state,
    bmsAlarmsState,
} from '../../../redux/slices/bms/bmsSliceServers';
import {
    getBMSUserVenuesList,
    venues_fetching as venues_fetching_state,
    bms_venues_list as bms_venues_list_state,
} from '../../../redux/slices/bms/bmsSliceVenues';
import { clear_system_list } from '../../../redux/slices/bms/bmsSliceSystems';
import { getVenueStatusColorByServerStatus } from '../../../api/functions/bms/getVenueStatusByServerStatus';

export const VenueList = ({ setVenueId, setIsOpen }) => {
    const dispatch = useDispatch();

    const bms_venues_list = useSelector(bms_venues_list_state);
    const venues_fetching = useSelector(venues_fetching_state);
    const bms_servers_list = useSelector(bms_servers_list_state);
    const bmsServers = useSelector(bms_servers_state);
    const bmsAlarms = useSelector(bmsAlarmsState);

    const isObjectEdit = useCheckRole('bms', 'bms.object.view');

    useEffect(() => {
        dispatch(clear_system_list());

        if (!bms_venues_list.length) {
            dispatch(getBMSUserVenuesList());
        }
    }, [bms_venues_list]);

    useEffect(() => {
        if (!bms_servers_list.length) {
            dispatch(getBMSServersList());
        }
    }, [bms_servers_list]);

    const handlerVenueEditClick = id => {
        setVenueId(id);
        setIsOpen(true);
    };

    const getColor = id => {
        // Если есть ошибки на точке
        if (bmsAlarms.devProblems?.bad_status?.venue_id && bmsAlarms.devProblems.bad_status.venue_id.includes(id)) {
            return 'bms-error';
        }

        // Непросмотренная ошибка на объекте
        if (bmsAlarms.blink?.venue_id && bmsAlarms.blink.venue_id.includes(id)) {
            return 'animate-pulse bms-error';
        }

        // Ошибка на объекте
        if (bmsAlarms.alarms?.venue_id && bmsAlarms.alarms.venue_id.includes(id)) {
            return 'bms-error';
        }

        // Цвет в зависимости от статуса сервера
        return getVenueStatusColorByServerStatus(id, bmsServers);
    };

    if (venues_fetching) {
        return (
            <div className="flex items-center">
                <Loader />
            </div>
        );
    }

    return (
        <>
            {bms_venues_list.length ? (
                bms_venues_list.map(el => (
                    <div className="relative" key={`bms${el.id}`}>
                        <Link
                            to={`/bms/venues/${el.id}/systems`}
                            className={`flex flex-col justify-center items-center overflow-hidden w-52 h-28 p-2 text-white border-2 border-gray-10 hover:border-blue rounded-md transition-colors ${getColor(el.id)}`}
                        >
                            <div className="uppercase text-sm leading-4 font-bold text-center line-clamp-2">
                                {el.title}
                            </div>
                        </Link>

                        <div className="absolute top-1 right-1 flex flex-row items-center">
                            {isObjectEdit && (
                                <button
                                    className="cursor-pointer transition-colors w-6 h-6 text-white hover:text-gray-800 flex justify-center items-center"
                                    onClick={() => handlerVenueEditClick(el.id)}
                                >
                                    <FaRegEdit />
                                </button>
                            )}
                        </div>
                    </div>
                ))
            ) : (
                <div className="flex flex-col justify-center items-center overflow-hidden w-64 h-28 p-2">
                    Нет доступных объектов
                </div>
            )}
        </>
    );
};
