import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomScroll from 'react-custom-scroll';
import { useTranslation } from 'react-i18next';

import { cleanersState } from '../../../../../redux/slices/hospitalitySlice';

import { Radio } from '../../../../common/widgets/Radio';
import { SvgComponent } from '../../../../common/svgComponent/SvgComponent';

export const CleanerSelectionSection = ({ setCleanerHandler, isOpen, setClose, cleanerId }) => {
    const { t } = useTranslation();

    const cleanerInputRef = useRef(null);

    const cleaners = useSelector(cleanersState);

    const [searchLine, setSearchLine] = useState('');

    useEffect(() => {
        if (cleanerInputRef.current && isOpen) {
            cleanerInputRef.current.focus();
        }

        return () => setSearchLine('');
    }, [isOpen]);

    const allocatedCleaners = useMemo(
        () => cleaners.filter(cleaner => cleaner.supervisorId && cleaner.isApplied && cleaner.name.toLowerCase().indexOf(searchLine.toLowerCase()) >= 0),
        [cleaners, searchLine]
    );

    return (
        <div className={`relative -top-[72px] -mb-[72px] shadow-3xl bg-white min-h-[128px] flex-col pl-3 rounded ${isOpen ? 'flex' : 'hidden'}`}>
            <div className="flex flex-row items-center justify-start gap-1.5 border-b border-b-gray-10 pr-3 py-3">
                <div className="w-3.5 h-3.5 shrink-0 mr-1">
                    <SvgComponent name="search" />
                </div>
                <input
                    type="text"
                    className="w-full h-5 bg-red p-0 text-xs font-medium text-ellipsis border-0 focus:outline-0 focus:border-0 focus:ring-0"
                    placeholder={t('HOSPITALITY_ADD_MAID')}
                    ref={cleanerInputRef}
                    value={searchLine}
                    onChange={e => setSearchLine(e.target.value)}
                />
                <button className="shrink-0 text-xs text-blue font-medium mt-[1px]" onClick={setClose}>
                    {t('CANCEL')}
                </button>
            </div>
            <div className="max-h-[180px] h-full">
                <CustomScroll allowOuterScroll={true}>
                    <div className="overflow-hidden">
                        <div className="flex flex-col gap-2 pr-3 py-3">
                            {allocatedCleaners.length ? (
                                allocatedCleaners.map(cleaner => (
                                    <div className="flex py-1.5 text-xs" key={cleaner.id}>
                                        <Radio
                                            name={`cleaner${cleaner.id}`}
                                            title={cleaner.name}
                                            value={cleaner.id}
                                            checked={cleaner.id === cleanerId}
                                            disabled={cleaner.isAssigned}
                                            onClick={setCleanerHandler}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div className="text-xs">{t('HOSPITALITY_NO_MAIDS_TO_DISTRIBUTE')}</div>
                            )}
                        </div>
                    </div>
                </CustomScroll>
            </div>
        </div>
    );
};
