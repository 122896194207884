import { SwitchPanel } from './switchPanel/SwitchPanel';
import { NewsTypeFilter } from './news/NewsTypeFilter';
import { NewsSearchSection } from './news/NewsSearchSection';
import { CreateNewsButton } from './news/CreateNewsButton';
import { PollsSearchSection } from './polls/PollsSearchSection';
import { CreatePollButton } from './polls/CreatePollButton';
import { ReportsTypeFilter } from './reports/ReportsTypeFilter';
import { ReportsSearchSection } from './reports/ReportsSearchSection';

export const SubHeader = ({ activeSectionId, setActiveSectionId, setCreateNewsPaneIsOpen, setCreatePollPaneIsOpen }) => {
    return (
        <div className="w-full flex justify-between flex-row shrink-0 gap-4 lg:gap-8 overflow-y-auto bg-white border-b border-b-gray-10 border-l border-l-gray-10 px-4 sm:px-6">
            <SwitchPanel activeSectionId={activeSectionId} setActiveSectionId={setActiveSectionId} />

            <div className="flex items-center gap-3.5">
                {activeSectionId === 1 && (
                    <>
                        <NewsTypeFilter />
                        <NewsSearchSection />
                        <CreateNewsButton onClick={() => setCreateNewsPaneIsOpen(true)} />
                    </>
                )}

                {activeSectionId === 2 && (
                    <>
                        <PollsSearchSection />
                        <CreatePollButton onClick={() => setCreatePollPaneIsOpen(true)} />
                    </>
                )}

                {activeSectionId === 3 && (
                    <>
                        <ReportsTypeFilter />
                        <ReportsSearchSection />
                    </>
                )}
            </div>
        </div>
    );
};
