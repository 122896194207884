export const PopupTitle = ({ setLibElemTitle, title }) => {
    return (
        <div className="flex justify-center mb-10">
            <input
                type="text"
                className="text-center w-96 h-10 border border-gray-10 rounded text-base"
                defaultValue={title}
                onChange={e => setLibElemTitle(e.target.value)}
            />
        </div>
    );
};
