import { Radio } from '../../../common/widgets/Radio';

export const RadioGroup = ({ type, setData, data }) => {
    return (
        <div className="ml-2 flex flex-col text-sm">
            <Radio
                name={type.type}
                title="период"
                value="period"
                checked={type.typeOptions === 'period'}
                onClick={() =>
                    setData(prevState => {
                        return {
                            ...prevState,
                            data: {
                                ...prevState.data,
                                typeSettings: data.map(el => (el.id === type.id ? { ...el, typeOptions: 'period', period: '', value: '' } : el)),
                            },
                        };
                    })
                }
            />
            <Radio
                name={type.type}
                title="шаг"
                value="step"
                checked={type.typeOptions === 'step'}
                onClick={() =>
                    setData(prevState => {
                        return {
                            ...prevState,
                            data: { ...prevState.data, typeSettings: data.map(el => (el.id === type.id ? { ...el, typeOptions: 'step', period: '' } : el)) },
                        };
                    })
                }
            />
        </div>
    );
};
