export const WithoutDataPointCheckbox = ({ isUseWithoutDataPoint, setIsUseWithoutDataPoint }) => {
    return (
        <div className="flex items-center justify-center mb-10">
            <label htmlFor="isUseWithoutDataPoint" className=" cursor-pointer flex items-center gap-2">
                <div className="text-base">Добавить оборудование без привязки точек данных</div>

                <input
                    type="checkbox"
                    value={isUseWithoutDataPoint}
                    checked={isUseWithoutDataPoint}
                    id="isUseWithoutDataPoint"
                    onChange={e => setIsUseWithoutDataPoint(e.target.checked)}
                    className="cursor-pointer"
                />
            </label>
        </div>
    );
};
