import { useDispatch } from 'react-redux';
import { Popup } from '../../common/popup/Popup';

import { ButtonRectangular } from '../../common/btn/ButtonRectangular';
import { confirmBMSLogEven } from '../../../redux/slices/bms/bmsSliceEventLog';

export const AcceptEventPopup = ({ popupState, setPopupState }) => {
    const dispatch = useDispatch();

    const clickHandler = answer => {
        if (answer) {
            dispatch(confirmBMSLogEven(popupState.id));
            setPopupState({ isOpen: false, id: null });
        } else {
            setPopupState({ isOpen: false, id: null });
        }
    };

    return (
        <Popup isOpen={popupState.isOpen} setIsOpen={setPopupState} className="w-96">
            <div className="flex flex-col items-center">
                <div className="text-center mt-3">Уверены что хотите подтвердить проверку события?</div>
                <div className="w-full flex justify-evenly mt-5">
                    <ButtonRectangular className="h-7 w-28" onClick={() => clickHandler(true)}>
                        Да
                    </ButtonRectangular>
                    <ButtonRectangular className="h-7 bg-red-600 hover:bg-red-700 border-red-600" onClick={() => clickHandler(false)}>
                        Отмена
                    </ButtonRectangular>
                </div>
            </div>
        </Popup>
    );
};
