import { url } from "../../../../api/config";

const getImgFiles = application => {
    let imgFiles = [];
    let otherFiles = [];

    if (!application.length > 0) {
        return null;
    } else {
        application.map(file => {
            file.type === 'image' ? imgFiles.push(`${url}/${file.file_path}`) : otherFiles.push(file.file_path);
        });
    }

    return {
        images: imgFiles,
        otherFiles,
    };
};

export default getImgFiles;
