export const Text = ({ el }) => {
    return (
        <div
            className="border pl-1 border-gray-50"
            style={{
                fontSize: el.data.states[0].fontSize,
                width: el.data.position.width,
                color: el.data.states[0].textColor,
                background: el.data.states[0].bgColor,
            }}
        >
            {el.data.states[0].value}
        </div>
    );
};
