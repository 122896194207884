import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { toastOptions } from '../../../../api/functions';
import {
    getRooms,
    getTypicalTasks,
    roomsState,
    cleanersState,
    typicalTasksState,
    createTask,
    supervisorsState,
} from '../../../../redux/slices/hospitalitySlice';

import { SimpleSelect } from '../../../common/widgets/SimpleSelect';
import { SlidingPaneHeader } from '../../../common/slidingPane/SlidingPaneHeader';
import { SlidingPaneFooter } from '../../../common/slidingPane/SlidingPaneFooter';
import { RoomStatus } from './RoomStatus';

const initialState = {
    room: null,
    task: null,
    worker: null,
    amount: '',
};

export const TaskPane = ({ isOpen, setIsOpen }) => {
    const [taskFields, setTaskFields] = useState(initialState);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const rooms = useSelector(roomsState);
    const cleaners = useSelector(cleanersState);
    const supervisors = useSelector(supervisorsState);
    const taskList = useSelector(typicalTasksState);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            dispatch(getRooms());
            dispatch(getTypicalTasks());
        }
    }, [isOpen]);

    const closePaneHandler = () => {
        setIsOpen(false);
        setTaskFields(initialState);
    };

    const createTaskHandler = async () => {
        if (taskFields.room && taskFields.task) {
            setIsLoading(true);

            const data = {
                office_id: taskFields.room.value,
                user_id: taskFields.worker && taskFields.worker.value ? taskFields.worker.value : null,
                type_id: taskFields.task.value.split('__')[0],
                type_type: taskFields.task.type,
                typical_amount: taskFields.amount,
            };

            const result = await dispatch(createTask({ data }));

            if (result.error) {
                toast.error(result.message, toastOptions);
            } else {
                toast.success(t('HOSPITALITY_CREATED_TASK'), toastOptions);
                setIsOpen(false);
                setTaskFields(initialState);
            }

            setIsLoading(false);
        } else {
            toast.error(t('HOSPITALITY_CREATED_TASK_ERROR_1'), toastOptions);
        }
    };

    const roomsOptions = useMemo(() => rooms.map(room => ({ label: room.room_number, value: room.room_id })), [rooms]);

    const taskOptions = useMemo(
        () =>
            taskList.map(task => ({
                label: task.needTranslate ? t(task.code) : task.title,
                value: `${task.id}__${task.type}`,
                numeric: task.isNumerical,
                type: task.type,
            })),
        [taskList]
    );

    const workersOptions = useMemo(() => {
        const cleanersArr = cleaners.filter(cleaner => cleaner.isApplied).map(cleaner => ({ label: cleaner.name, value: cleaner.id }));
        const supervisorsArr = supervisors.filter(supervisor => supervisor.state).map(supervisor => ({ label: supervisor.name, value: supervisor.id }));

        return [...supervisorsArr, ...cleanersArr].sort((a, b) => a.label.localeCompare(b.label));
    }, [cleaners, supervisors]);

    return (
        <SlidingPane
            isOpen={isOpen}
            hideHeader={true}
            onRequestClose={closePaneHandler}
            overlayClassName="z-40 bg-slate-900 bg-opacity-30 transition-opacity duration-200 opacity-100  h-full"
            width="25rem"
        >
            <SlidingPaneHeader closePaneHandler={closePaneHandler} title={t('HOSPITALITY_CREATE_TASK')} />

            {/* room number */}
            <div className="mb-6">
                <div className="flex flex-row justify-between w-80 mb-2">
                    <p className="text-sm font-medium">{t('HOSPITALITY_ROOM_NUMBER')}</p>
                    <RoomStatus roomName={taskFields.room ? taskFields.room.label : ''} />
                </div>

                <SimpleSelect
                    isSearchable={true}
                    options={roomsOptions}
                    placeholder={t('HOSPITALITY_SELECT_ROOM_NUMBER')}
                    value={taskFields.room}
                    callback={val => setTaskFields(taskFields => ({ ...taskFields, room: val ? { label: val.label, value: val.value } : null }))}
                    className="w-80"
                />
            </div>

            {/* task name */}
            <div className="mb-6">
                <p className="text-sm font-medium mb-2">{t('HOSPITALITY_REQUEST')}</p>

                <SimpleSelect
                    isSearchable={true}
                    options={taskOptions}
                    placeholder={t('HOSPITALITY_SELECT_REQUEST')}
                    value={taskFields.task}
                    callback={val =>
                        setTaskFields(taskFields => ({
                            ...taskFields,
                            task: val ? { label: val.label, value: val.value, numeric: val.numeric, type: val.type } : null,
                        }))
                    }
                    className="w-80"
                />
            </div>

            {/* task amount */}
            {taskFields.task && taskFields.task.numeric && (
                <div className="mb-6">
                    <p className="text-sm font-medium mb-2">{t('HOSPITALITY_QUANTITY')}</p>
                    <input
                        type="number"
                        value={taskFields.amount}
                        placeholder={t('HOSPITALITY_SELECT_QUANTITY')}
                        className="w-80 h-9 p-2 border-gray-10 border text-sm"
                        onChange={e => setTaskFields(taskFields => ({ ...taskFields, amount: e.target.value }))}
                    />
                </div>
            )}

            {/* worker name */}
            <div>
                <p className="text-sm font-medium mb-2">{t('HOSPITALITY_EXECUTOR')}</p>

                <SimpleSelect
                    isSearchable={true}
                    options={workersOptions}
                    placeholder={t('HOSPITALITY_CHOOSE_EXECUTOR')}
                    value={taskFields.worker}
                    callback={val => setTaskFields(taskFields => ({ ...taskFields, worker: val ? { label: val.label, value: val.value } : null }))}
                    className="w-80"
                />
            </div>

            <SlidingPaneFooter closePaneHandler={closePaneHandler} callback={createTaskHandler} loading={isLoading} />
        </SlidingPane>
    );
};
