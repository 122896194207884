import { MultiSelect } from '../../../common/widgets/MultiSelect';

export const VenueServersSelect = ({ setVenue, venue, options }) => {
    return (
        <MultiSelect
            options={options}
            placeholder="Выберите серверы"
            value={venue.servers}
            callback={val => setVenue(venue => ({ ...venue, servers: val }))}
            className="w-80"
        />
    );
};
