import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import CustomScroll from 'react-custom-scroll';
import { useTranslation } from 'react-i18next';

import { Draggable } from '../../../../common/dnd/Draggable';
import { Card } from '../../../card/Card';
import { Loader } from '../../../../common/Loader';
import { SvgComponent } from '../../../../common/svgComponent/SvgComponent';

export const List = ({ isOpen, setIsOpen, items, itemsTitle = '', loading = false }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (items.length) {
            ReactTooltip.rebuild();
        }
    }, [items]);

    return (
        <div
            className={`relative -top-10 -mb-10 bg-white rounded-b block transition-opacity duration-200 ${isOpen ? 'h-full w-full visible opacity-100' : 'h-0 w-0 invisible opacity-0'}`}
        >
            <div className="max-h-80 h-full">
                <CustomScroll allowOuterScroll={true}>
                    <div className="flex flex-col gap-2 pt-3 pb-4 px-3 overflow-hidden">
                        {loading ? (
                            <Loader width={12} height={12} />
                        ) : items.length ? (
                            items.map(item => (
                                <Draggable id={item.id} key={item.id} disabled={item.statusId === '3' || item.statusId === '8'}>
                                    <Card typeId={item.clearTypeId || ''} statusId={item.statusId || ''} description={item.description}>
                                        {item.title}
                                    </Card>
                                </Draggable>
                            ))
                        ) : (
                            <div className="font-medium text-xs text-gray">{t('HOSPITALITY_NO_DATA')}</div>
                        )}
                    </div>
                </CustomScroll>
            </div>

            <button onClick={() => setIsOpen(false)} className="w-full h-10 px-3 flex flex-row items-center text-blue border-t border-t-gray-10">
                <div className="flex text-xs">
                    {itemsTitle}:{' '}
                    {loading ? (
                        <div className="ml-1">
                            <Loader height={12} width={12} />
                        </div>
                    ) : (
                        items.length
                    )}
                </div>

                <div className="w-4 h-4 ml-auto">
                    <SvgComponent name="chevron-up" />
                </div>
            </button>
        </div>
    );
};
