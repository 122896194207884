import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';

import { DocumentLink } from './DocumentLink';
import { OperatorAcceptButton } from './OperatorAcceptButton';
import { Pagination } from './Pagination';

import { Loader } from '../../common/Loader';
import { AcceptEventPopup } from './AcceptEventPopup';

import {
    bmsEventLogFiltersState,
    bmsEventLogListFetchState,
    bmsEventLogListState,
    getBMSEventLogList,
    getBMSEventLogListTimeout,
    setBMSEventLogFilters,
} from '../../../redux/slices/bms/bmsSliceEventLog';
import { set_bms_doc_list } from '../../../redux/slices/bms/bmsSliceDocuments';

const initialState = { isOpen: false, id: null };

export const EventLogTable = () => {
    const dispatch = useDispatch();
    const bmsEventLogFilters = useSelector(bmsEventLogFiltersState);
    const bmsEventLogList = useSelector(bmsEventLogListState);
    const bmsEventLogListFetch = useSelector(bmsEventLogListFetchState);

    const [popupState, setPopupState] = useState(initialState);

    useEffect(() => {
        dispatch(set_bms_doc_list([]));
        dispatch(getBMSEventLogList({ data: bmsEventLogFilters }));
        const interval = setInterval(async () => {
            dispatch(getBMSEventLogListTimeout({ data: bmsEventLogFilters }));
        }, 15000);
        return () => clearInterval(interval);
    }, [bmsEventLogFilters]);

    const columns = useMemo(() => (bmsEventLogList ? bmsEventLogList.tHead.filter(item => item.accessor !== 'operator') : []), [bmsEventLogList]);

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
        columns,
        data: bmsEventLogList ? bmsEventLogList.tBody : [],
        initialState: { pageSize: bmsEventLogFilters ? bmsEventLogFilters.page_size : 100 },
    });

    if (bmsEventLogListFetch) {
        return (
            <div className="w-full h-20 flex items-center justify-center">
                <Loader />
            </div>
        );
    }
    if (bmsEventLogList && !bmsEventLogList.tBody.length) {
        return <div className="w-full text-center mt-3">Данных нет</div>;
    }

    return (
        <div className="w-full overflow-x-auto">
            <table {...getTableProps()} className="w-full relative border-separate border-spacing-0 text-sm">
                <thead className="text-black text-left">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => {
                                return (
                                    <th
                                        {...column.getHeaderProps()}
                                        className="sticky top-0 bg-white font-medium px-2 first:pl-4 last:pr-4 py-3 border-y border-gray-10 first:border-l first:rounded-tl-lg last:border-r last:rounded-tr-lg"
                                    >
                                        <div className="flex">
                                            {column.render('Header')}
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    dispatch(
                                                        setBMSEventLogFilters({ type: 'sort', value: bmsEventLogFilters.sort === '-date' ? 'date' : '-date' })
                                                    )
                                                }
                                                className="ml-1"
                                            >
                                                {column.id === 'date' ? (bmsEventLogFilters.sort === '-date' ? ' 🔽' : ' 🔼') : ''}
                                            </button>
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className="block md:table-row mb-4 md:mb-0 border md:border-0 border-gray-10 rounded-lg overflow-hidden md:overflow-visible"
                            >
                                {row.cells.map(cell => {
                                    let additionally = '';
                                    let styles = {};

                                    if (cell.column.id === 'link') {
                                        additionally = <DocumentLink link={cell.value} />;
                                    }
                                    if (cell.column.id === 'operator') {
                                        additionally =
                                            cell.value === '' ? <OperatorAcceptButton setPopupState={setPopupState} id={cell.row.original.id} /> : cell.value;
                                    }
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            data-label={cell.column.Header}
                                            className={`font-normal flex justify-between md:table-cell text-right md:text-left before:content-[attr(data-label)] before:uppercase before:font-medium md:before:hidden bg-white py-1.5 px-2 md:first:pl-4 md:last:pr-4 border-b last:border-b-0 md:last:border-b border-gray-10 md:first:border-l md:last:border-r ${
                                                rows.length === index + 1 ? 'first:rounded-bl-lg last:rounded-br-lg' : ''
                                            } ${styles}`}
                                        >
                                            {additionally || cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Pagination searchParams={bmsEventLogFilters} />
            <AcceptEventPopup popupState={popupState} setPopupState={setPopupState} />
        </div>
    );
};
