import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik, useField, useFormikContext } from 'formik';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import SlidingPane from 'react-sliding-pane';
import { t } from 'i18next';
import { useCookies } from 'react-cookie';


import { url } from "../../api/config";
import {
    addScheduledTask,
    detail_is_open,
    filter as filter_state,
    filter_fetch as filter_fetch_state,
    set_created,
    set_created_error,
    set_detail_open,
    set_task,
    task as task_state,
    task_created as task_created_state,
    task_created_error as task_created_error_state,
    tasksFetch,
    venues as venues_state,
} from '../../redux/slices/adminSchedulerSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';

import { PicsLoader } from './detail/PicsLoader';
import { Loader } from '../common/Loader';
import { Fancybox } from '../common/Fancybox';

export const Detail = () => {
    const [isPeriodic, setPeriodic] = useState(false);
    const [venues, setVenues] = useState([]);
    const [venueSearchLine, setVenueSearchLine] = useState('');
    const [minDate, setMinDate] = useState(null);
    const [files, setFiles] = useState([]);

    const dispatch = useDispatch();
    const isOpen = useSelector(detail_is_open);
    const venuesState = useSelector(venues_state);
    const filter_fetch = useSelector(filter_fetch_state);
    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const task_created = useSelector(task_created_state);
    const filter = useSelector(filter_state);
    const task_created_error = useSelector(task_created_error_state);
    const task = useSelector(task_state);

    const [cookies] = useCookies();
    const lang = cookies.i18next;

    useEffect(() => {
        if (isOpen) {
            setVenues(venuesState);
            setVenueSearchLine('');
        }
    }, [isOpen, venuesState]);

    // console.log('venuestate', venuesState);

    const closeHandler = () => {
        dispatch(set_task({}));
        dispatch(set_detail_open(false));
        dispatch(set_created_error(false));
        setFiles([]);
    };

    const options = [
        { value: '1', label: t('ADM_PLANNER_TASK_PERF_DAYS') },
        { value: '7', label: t('ADM_PLANNER_TASK_PERF_WEEKS') },
        { value: '30', label: t('ADM_PLANNER_TASK_PERF_MONTHS') },
    ];

    const options_periodic = [
        { value: '1', label: t('ADM_PLANNER_TASK_PER_IN_DAYS') },
        { value: '7', label: t('ADM_PLANNER_TASK_PER_IN_WEEKS') },
        { value: '30', label: t('ADM_PLANNER_TASK_PER_IN_MONTHS') },
    ];

    const departments_options = filter_fetch.departments.map(item => ({
        value: item.id,
        label: item.name,
    }));

    const venuesSelectAll = (setFieldValue, data) => {
        if (data.checked) {
            setFieldValue(
                'venue_ids',
                venues.map(venue => `${venue.ID}`)
            );
        } else {
            setFieldValue('venue_ids', []);
        }
    };

    const venuesSelect = (setFieldValue, venues, data) => {
        if (data.checked) {
            setFieldValue('venue_ids', [...venues, `${data.value}`]);
        } else {
            setFieldValue(
                'venue_ids',
                venues.filter(el => el !== `${data.value}`)
            );
        }
    };

    const venuesSearch = searchLine => {
        setVenueSearchLine(searchLine);
    };

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            const result = venuesState.filter(item => item.Name.toLowerCase().includes(venueSearchLine.toLowerCase()));
            setVenues(result);
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [venueSearchLine, venuesState]);

    const formSubmit = async (values, { setSubmitting }) => {
        const data = {
            ...values,
            date_start: values.date_start ? new Date(values.date_start).toLocaleDateString('ru-RU') : new Date().toLocaleDateString('ru-RU'),
            date_end: values.date_end ? new Date(values.date_end).toLocaleDateString('ru-RU') : null,
            status: 1,
            create_time_num: values.create_time_num || '1',
            create_time_unit: values.create_time_unit || '1',
            venue_ids: JSON.stringify(values.venue_ids),
            period_task: values.period_task ? 1 : 0,
            files: files,
        };

        await dispatch(addScheduledTask({ username, auth_key_calendar, data, lang }));

        setSubmitting(false);
    };

    const afterSubmit = async () => {
        await dispatch(tasksFetch({ username, auth_key_calendar, filter: { ...filter, page: 1 }, lang }));
        dispatch(set_created(false));
        closeHandler();
    };

    useEffect(() => {
        if (task_created) {
            afterSubmit();
        }
    }, [task_created]);

    const CustomNumberField = props => {
        const [field, , helpers] = useField(props);
        const { setValue } = helpers;

        useEffect(() => {
            const val = field.value;

            if (val.length > 0) {
                const formattedVal = val.substring(0, 1).replace(/[^1-9]/g, '') + val.slice(1).replace(/\D/g, '');
                setValue(formattedVal);
            }
        }, [field.value]);

        return <Field {...props} />;
    };

    const CustomSelectField = props => {
        const [field, , helpers] = useField(props);
        const { setValue } = helpers;

        return (
            <Select
                defaultValue={props.options.find(item => item.value === field.value)}
                onChange={item => setValue(item.value)}
                placeholder={t('ADM_PLANNER_PLACEHOLD_DEPART_PL')}
                {...props}
            />
        );
    };

    const CustomPeriodicCheckboxField = props => {
        const [field] = useField(props);

        useEffect(() => {
            setPeriodic(field.checked);
        }, [field.checked]);

        return <Field {...props} />;
    };
    const CustomDatePickerField = props => {
        const { setFieldValue } = useFormikContext();
        const [field] = useField(props);

        useEffect(() => {
            if (field.name === 'date_start' && field.value) {
                setMinDate(field.value);
            }
        }, [field]);

        return (
            <DatePicker
                {...field}
                {...props}
                minDate={minDate && field.name === 'date_end' ? minDate : props.minDate}
                selected={field.value || null}
                onChange={val => {
                    setFieldValue(field.name, val);
                    if (field.name === 'date_start') {
                        setFieldValue('date_end', '');
                    }
                }}
            />
        );
    };

    const initValues = task.id
        ? {
              description: task.description,
              department_id: task.department_id,
              execute_time_num: task.execute_time_num ? task.execute_time_num.toString() : '',
              execute_time_unit: task.execute_time_unit ? task.execute_time_unit.toString() : '1',
              period_task: task.period_task,
              create_time_num: task.create_time_num ? task.create_time_num.toString() : '',
              create_time_unit: task.create_time_unit ? task.create_time_unit.toString() : '1',
              date_start: task.date_start ? new Date(task.date_start) : '',
              date_end: task.date_end ? new Date(task.date_end) : '',
              venue_ids: task.venue_ids && task.venue_ids.length ? task.venue_ids.map(i => i.toString()) : [],
          }
        : {
              description: '',
              department_id: '',
              execute_time_num: '',
              execute_time_unit: '1',
              period_task: false,
              create_time_num: '',
              create_time_unit: '1',
              date_start: '',
              date_end: '',
              venue_ids: [],
          };

    // console.log('venues', venues);

    const getImgFiles = () => {
        let imgFiles = [];
        let otherFiles = [];

        if (!task?.files?.length > 0) {
            return '';
        } else {
            task.files.map(file => {
                file.type === 'image' ? imgFiles.push(file.path) : otherFiles.push(file.path);
            });
        }

        return {
            images: imgFiles,
            otherFiles,
        };
    };

    const galleryFiles = getImgFiles();

    return (
        <SlidingPane
            isOpen={isOpen}
            hideHeader={true}
            onRequestClose={closeHandler}
            overlayClassName="z-40 bg-slate-900 bg-opacity-30 transition-opacity duration-200 opacity-100"
            width="50rem"
        >
            {/*header*/}
            <div className="flex items-center justify-between w-full sticky -top-6 -mt-6 bg-white z-20 py-6">
                <div className="font-bold text-black text-2xl">{task.id ? `${t('ADM_PLANNER_TASK')} #${task.id}` : t('ADM_PLANNER_NEW_TASK')}</div>
                <button className="w-9 h-9 p-0 rounded-full bg-transparent hover:bg-gray-100 flex items-center justify-center" onClick={closeHandler}>
                    <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                    </svg>
                </button>
            </div>

            {/*content*/}
            <Formik
                initialValues={initValues}
                validate={values => {
                    const errors = {};

                    if (!values.description) {
                        errors.description = t('ADM_PLANNER_ERROR_DESCR');
                    }
                    if (!values.execute_time_num) {
                        errors.execute_time_num = t('ADM_PLANNER_ERROR_DESCR');
                    }
                    if (values.period_task && !values.create_time_num) {
                        errors.create_time_num = t('ADM_PLANNER_ERROR_DESCR');
                    }
                    if (values.period_task && !values.date_start) {
                        errors.date_start = t('ADM_PLANNER_ERROR_DESCR');
                    }
                    if (!values.venue_ids.length) {
                        errors.venue_ids = t('ADM_PLANNER_ERROR_DESCR');
                    }
                    if (!values.department_id) {
                        errors.department_id = t('ADM_PLANNER_ERROR_DESCR');
                    }

                    return errors;
                }}
                onSubmit={formSubmit}
            >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form className="flex flex-col">
                        <div>{task_created_error && <p className="text-red-500 font-semibold mb-4">{task_created_error}</p>}</div>
                        <div className="w-full flex flex-wrap md:flex-nowrap gap-6">
                            {/*content left*/}
                            <div className="w-full md:w-1/2">
                                <h3 className="font-bold text-black mb-3 text-xl">{t('ADM_PLANNER_TASK_MAIN_INFO')}</h3>

                                <div className="mb-4 last:mb-0">
                                    <p className="text-sm mb-2 text-gray-20 flex w-full">
                                        {t('ADM_PLANNER_TASK_DEPARTMENT')}
                                        {touched.department_id && errors.department_id ? (
                                            <span className="text-red-500 font-semibold ml-auto">{errors.department_id}</span>
                                        ) : (
                                            ''
                                        )}
                                    </p>
                                    <CustomSelectField
                                        name="department_id"
                                        options={departments_options}
                                        className="select text-sm w-full"
                                        isClearable={false}
                                        isSearchable={false}
                                        classNamePrefix="filter"
                                        isDisabled={!!task.id || isSubmitting}
                                    />
                                </div>

                                <div className="mb-4 last:mb-0">
                                    <p className="text-sm mb-2 text-gray-20 flex w-full">
                                        {t('ADM_PLANNER_TASK_DESCRIPTION')}
                                        {touched.description && errors.description ? (
                                            <span className="text-red-500 font-semibold ml-auto">{errors.description}</span>
                                        ) : (
                                            ''
                                        )}
                                    </p>
                                    <Field
                                        name="description"
                                        component="textarea"
                                        value={values.description}
                                        placeholder={t('ADM_PLANNER_PLACEHOLD_DESC_PL')}
                                        className="box-border w-full h-32 text-sm resize-none rounded-1 border-gray-10 p-3"
                                        disabled={!!task.id || isSubmitting}
                                    />
                                </div>

                                {task?.files?.length > 0 && (
                                    <div className="mb-3">
                                        <span>{t('ADM_PLANNER_PHOTO')}:</span>
                                        <div className="flex flex-wrap flex-row mb-3 gap-2 ">
                                            <Fancybox options={{ infinite: false }}>
                                                {galleryFiles.images.map((image, idx) => (
                                                    <div
                                                        className="w-24 h-24 border border-neutral-300 rounded-sm p-1 box-border cursor-pointer	transition flex flex-col items-center justify-center hover:border-neutral-500"
                                                        data-fancybox="gallery"
                                                        data-src={`${url}/${image}`}
                                                        key={`image${idx}`}
                                                    >
                                                        <img src={`${url}/${image}`} alt="" className="w-full h-full object-cover" />
                                                    </div>
                                                ))}
                                            </Fancybox>
                                            {galleryFiles.otherFiles.map((file, idx) => (
                                                <a
                                                    href={`${url}/${file}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="w-24 h-24 border border-neutral-300 rounded-sm p-1 box-border cursor-pointer	transition flex flex-col items-center justify-center hover:border-neutral-500"
                                                    key={`file${idx}`}
                                                >
                                                    <svg width="40" height="40" fill="#757575" viewBox="0 0 512 512">
                                                        <path d="M459.36,100.64l-96-96C360.341,1.645,356.253-0.024,352,0H96c-26.51,0-48,21.49-48,48v416c0,26.51,21.49,48,48,48h320     c26.51,0,48-21.49,48-48V112C464.025,107.747,462.355,103.66,459.36,100.64z M432,464c0,8.837-7.163,16-16,16H96     c-8.837,0-16-7.163-16-16V48c0-8.837,7.163-16,16-16h240v64c0,17.673,14.327,32,32,32h64V464z" />
                                                        <rect x="368" y="384" width="32" height="32" />
                                                        <rect x="112" y="224" width="288" height="32" />
                                                        <rect x="112" y="304" width="288" height="32" />
                                                        <rect x="112" y="384" width="224" height="32" />
                                                    </svg>
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                <div className="mb-4 last:mb-0">
                                    <PicsLoader disabled={!!task.id || isSubmitting} files={files} setFiles={setFiles} />
                                </div>

                                <div className="mb-4 last:mb-0">
                                    <p className="text-sm mb-2 text-gray-20 flex w-full">
                                        {t('ADM_PLANNER_TASK_PERFORM')}
                                        {touched.execute_time_num && errors.execute_time_num ? (
                                            <span className="text-red-500 font-semibold ml-auto">{errors.execute_time_num}</span>
                                        ) : (
                                            ''
                                        )}
                                    </p>
                                    <div className="flex gap-5">
                                        <div className="w-full">
                                            <CustomNumberField
                                                name="execute_time_num"
                                                type="text"
                                                placeholder={t('ADM_PLANNER_PLACEHOLD_PERF_PL')}
                                                className="box-border w-full h-10 text-sm rounded-1 border-gray-10 px-3"
                                                disabled={!!task.id || isSubmitting}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <CustomSelectField
                                                name="execute_time_unit"
                                                options={options}
                                                className="select text-sm w-full"
                                                isClearable={false}
                                                isSearchable={false}
                                                classNamePrefix="filter"
                                                isDisabled={!!task.id || isSubmitting}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-4 last:mb-0">
                                    <label className="inline-flex items-center cursor-pointer select-none">
                                        <CustomPeriodicCheckboxField
                                            type="checkbox"
                                            name="period_task"
                                            className="mr-2 cursor-pointer select-none"
                                            disabled={!!task.id || isSubmitting}
                                        />
                                        <p className="text-sm text-gray-20">{t('ADM_PLANNER_TASK_PERIODICAL')}</p>
                                    </label>
                                </div>

                                <div className={`mb-4 last:mb-0 ${isPeriodic ? 'block' : 'hidden'}`}>
                                    <p className="text-sm mb-2 text-gray-20 flex w-full">
                                        {t('ADM_PLANNER_TASK_PER_UNLOADING')}
                                        {touched.create_time_num && errors.create_time_num ? (
                                            <span className="text-red-500 font-semibold ml-auto">{errors.create_time_num}</span>
                                        ) : (
                                            ''
                                        )}
                                    </p>
                                    <div className="flex gap-5">
                                        <div className="w-full">
                                            <CustomNumberField
                                                name="create_time_num"
                                                type="text"
                                                placeholder={t('ADM_PLANNER_PLACEHOLD_DEPART_PL')}
                                                className="box-border w-full h-10 text-sm rounded-1 border-gray-10 px-3"
                                                disabled={!!task.id || isSubmitting}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <CustomSelectField
                                                name="create_time_unit"
                                                options={options_periodic}
                                                className="select text-sm w-full"
                                                isClearable={false}
                                                isSearchable={false}
                                                classNamePrefix="filter"
                                                isDisabled={!!task.id || isSubmitting}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={`mb-4 last:mb-0 ${isPeriodic ? 'block' : 'hidden'}`}>
                                    <div className="flex gap-5">
                                        <div className="w-full">
                                            <p className="text-sm mb-2 text-gray-20 flex w-full">{t('ADM_PLANNER_TASK_PER_ACTIVITY_SINCE')}</p>
                                            <CustomDatePickerField
                                                disabledKeyboardNavigation
                                                locale={lang === 'ru' ? ru : en}
                                                name="date_start"
                                                dateFormat="dd.MM.yyyy"
                                                placeholderText={t('ADM_PLANNER_PLACEHOLD_SELECT_DATE_PL')}
                                                minDate={new Date()}
                                                fixedHeight
                                                disabled={!!task.id || isSubmitting}
                                            />
                                            {touched.date_start && errors.date_start ? (
                                                <div className="text-sm text-red-500 font-semibold h-4 mt-2 -mb-6">{errors.date_start}</div>
                                            ) : (
                                                ''
                                            )}
                                        </div>

                                        <div className="w-full">
                                            <p className="text-sm mb-2 text-gray-20">{t('ADM_PLANNER_TASK_PER_ACTIVITY_THROUGH')}</p>
                                            <CustomDatePickerField
                                                disabledKeyboardNavigation
                                                locale={lang === 'ru' ? ru : en}
                                                name="date_end"
                                                dateFormat="dd.MM.yyyy"
                                                placeholderText={t('ADM_PLANNER_PLACEHOLD_SELECT_DATE_PL')}
                                                minDate={new Date()}
                                                fixedHeight
                                                disabled={!!task.id || isSubmitting}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*content right*/}
                            <div className="w-full md:w-1/2">
                                <h3 className="font-bold text-black mb-3 text-xl">{t('ADM_PLANNER_OBJECTS')}</h3>

                                <p className="text-sm mb-2 text-gray-20 w-full flex">
                                    {t('ADM_PLANNER_SELECT_OBJ')}
                                    {touched.venue_ids && errors.venue_ids ? (
                                        <span className="text-red-500 font-semibold ml-auto">{errors.venue_ids}</span>
                                    ) : (
                                        ''
                                    )}
                                </p>
                                <div className="w-full relative z-10">
                                    <input
                                        type="text"
                                        placeholder={t('ADM_PLANNER_PLACEHOLD_SELECT_OBJ_PL')}
                                        onChange={e => venuesSearch(e.target.value)}
                                        className="box-border w-full h-10 text-sm rounded-1 border-gray-10 py-0 pl-3 pr-9"
                                        disabled={!!task.id || isSubmitting}
                                    />
                                    <svg
                                        className="w-[20px] h-[20px] absolute right-[10px] top-[9px]"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8.33333 14.1667C11.555 14.1667 14.1667 11.555 14.1667 8.33333C14.1667 5.11167 11.555 2.5 8.33333 2.5C5.11167 2.5 2.5 5.11167 2.5 8.33333C2.5 11.555 5.11167 14.1667 8.33333 14.1667Z"
                                            stroke="#B3B3BC"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path d="M12.5 12.5L17.5 17.5" stroke="#B3B3BC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>

                                <div className="md:h-[30vh] md:overflow-y-auto p-1 border border-t-0 border-gray-10 rounded-1 relative">
                                    {venues.length ? (
                                        venues.map(venue => {
                                            if (values.venue_ids.length && values.venue_ids.indexOf(`${venue.ID}`) >= 0) {
                                                return null;
                                            }

                                            return (
                                                <label
                                                    key={`${venue.Name}${venue.ID}`}
                                                    className="mb-1 last:mb-0 cursor-pointer select-none flex items-start gap-1.5 p-1.5 bg-gray-100 rounded-sm"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        value={`${venue.ID}`}
                                                        onChange={e => venuesSelect(setFieldValue, values.venue_ids, e.target)}
                                                        className="relative top-[3px] cursor-pointer select-none"
                                                        disabled={!!task.id || isSubmitting}
                                                    />
                                                    <div className="text-sm">
                                                        <p className="mb-0.5 text-black line-clamp-1">{venue.Name}</p>
                                                        <p className="text-gray-20 line-clamp-1">
                                                            <span className="text-black">{t('CALENDAR_DETAILS_EXEC_STATUS_ACTIVE')}:</span>{' '}
                                                            {/* {venue.responsible_name.trim() === 'не указан' ? t('CALENDAR_DETAILS_EXEC_STATUS') : venue.responsible_name.trim()} */}
                                                            {values.department_id
                                                                ? venue[`responsible_name_${values.department_id}`].trim() || t('CALENDAR_DETAILS_EXEC_STATUS')
                                                                : venue.responsible_name.trim() || t('CALENDAR_DETAILS_EXEC_STATUS')}
                                                        </p>
                                                    </div>
                                                </label>
                                            );
                                        })
                                    ) : (
                                        <p className="text-center mt-4 text-sm text-gray-20">{t('CALENDAR_NOTHING_FOUND')}</p>
                                    )}
                                </div>

                                <div className="md:h-[30vh] md:overflow-y-auto p-1 border border-t-0 border-gray-10 rounded-1 relative">
                                    <p className="sticky -top-1 -mt-1 p-2.5 text-sm text-gray-20 w-full bg-white z-10">{t('ADM_PLANNER_SELECTED_OBJ')}</p>
                                    {venuesState.length && values.venue_ids.length
                                        ? venuesState.map(venue => {
                                              if (values.venue_ids.indexOf(`${venue.ID}`) < 0) {
                                                  return null;
                                              }

                                              return (
                                                  <label
                                                      key={`${venue.Name}${venue.ID}`}
                                                      className="mb-1 last:mb-0 cursor-pointer select-none flex items-start gap-1.5 p-1.5 bg-gray-100 rounded-sm"
                                                  >
                                                      <Field
                                                          type="checkbox"
                                                          name="venue_ids"
                                                          value={`${venue.ID}`}
                                                          className="relative top-[3px] cursor-pointer select-none"
                                                          disabled={!!task.id || isSubmitting}
                                                      />
                                                      <div className="text-sm">
                                                          <p className="mb-0.5 text-black line-clamp-1">{venue.Name}</p>
                                                          <p className="text-gray-20 line-clamp-1">
                                                              <span className="text-black">{t('CALENDAR_DETAILS_EXEC_STATUS_ACTIVE')}:</span>{' '}
                                                              {values.department_id
                                                                  ? venue[`responsible_name_${values.department_id}`].trim() ||
                                                                    t('CALENDAR_DETAILS_EXEC_STATUS')
                                                                  : venue.responsible_name.trim() || t('CALENDAR_DETAILS_EXEC_STATUS')}
                                                          </p>
                                                      </div>
                                                  </label>
                                              );
                                          })
                                        : ''}
                                </div>

                                <label className="inline-flex items-center cursor-pointer select-none mt-4 pl-[11px]">
                                    <input
                                        className="mr-2 cursor-pointer select-none"
                                        type="checkbox"
                                        name="select_all_venues"
                                        onChange={e => venuesSelectAll(setFieldValue, e.target)}
                                        disabled={!!task.id || isSubmitting}
                                    />
                                    <p className="text-sm text-gray-20">{t('ADM_PLANNER_SELECT_OBJ_ALL')}</p>
                                </label>
                            </div>
                        </div>

                        {/*content bottom*/}
                        <div className="flex items-center gap-3 w-full pt-4 mt-12 border-t border-t-gray-10">
                            {!task.id && (
                                <button
                                    type="submit"
                                    className="bg-blue hover:bg-blue-500 transition-colors duration-200 text-white rounded-sm font-medium text-sm px-14 h-10"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <Loader height={20} width={20} color="#ffffff" /> : t('ADM_PLANNER_BTN_SAVE')}
                                </button>
                            )}

                            {!task.id && (
                                <button
                                    type="button"
                                    className="bg-white hover:bg-gray-100 transition-colors duration-200 text-gray-20 rounded-sm font-medium text-sm px-6 h-10"
                                    disabled={isSubmitting}
                                    onClick={closeHandler}
                                >
                                    {t('ADM_PLANNER_BTN_CANCEL')}
                                </button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </SlidingPane>
    );
};
