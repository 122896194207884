import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { filterTasksState, getTasksForCleaners, setFilterTasks } from '../../../../redux/slices/hospitalitySlice';

import { StatusesFilter } from './StatusesFilter';
import { ClearTypesFilter } from './ClearTypesFilter';
import { RoomsFilter } from './RoomsFilter';

const areArraysEqual = (firstArray, secondArray) => {
    return !!(firstArray.length === secondArray.length && firstArray.every((element, index) => element === secondArray[index]));
};

const areObjectsEqual = (firstObject, secondObject) => {
    if (Object.keys(firstObject).length !== Object.keys(secondObject).length) {
        return false;
    }

    for (let key of Object.keys(firstObject)) {
        if (firstObject[key] !== secondObject[key]) {
            return false;
        }
    }

    return true;
};

export const CleanersFilterSection = () => {
    const dispatch = useDispatch();

    const filter = useSelector(filterTasksState);

    useEffect(() => {
        if (filter.isTouched) {
            dispatch(setFilterTasks({ isTouched: false }));
            dispatch(getTasksForCleaners());
        }
    }, [filter]);

    const setFilter = ({ type, val }) => {
        let isEqual = false;
        if (Array.isArray(val) && areArraysEqual(filter[type], val)) {
            isEqual = true;
        }
        if (typeof val === 'object' && !Array.isArray(val) && areObjectsEqual(filter[type], val)) {
            isEqual = true;
        }

        if (!isEqual) {
            dispatch(setFilterTasks({ isTouched: true, [type]: val }));
        }
    };

    return (
        <div className="flex flex-row flex-wrap gap-4">
            <ClearTypesFilter setFilter={setFilter} value={filter.clearTypes} />
            <StatusesFilter setFilter={setFilter} value={filter.statuses} />
            <RoomsFilter setFilter={setFilter} value={filter.rooms} />
        </div>
    );
};
