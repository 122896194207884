export const SwitchButton = ({ title, setVenue, venue, newVenue }) => {
    return (
        <button
            onClick={() => setVenue({ ...venue, newVenue, kaizen_venue: '', employees: [] })}
            type="button"
            className={`mr-5 border rounded-2 px-4 py-1 text-sm cursor-pointer  ${
                venue.newVenue !== newVenue ? 'hover:bg-sky-200 hover:text-black' : 'bg-slate-400 text-white '
            }`}
        >
            {title}
        </button>
    );
};
