import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import {
    getHospitalityObjects,
    objectsFetchingState,
    objectsState,
    objectState,
    getSettings,
    setFilterTasks,
    setObjects,
    setObject,
    getTasksForCleaners,
    getTasksForSupervisors,
} from '../redux/slices/hospitalitySlice';

import { Loader } from '../components/common/Loader';
import { Header } from '../components/hospitality/header/Header';
import { SubHeader } from '../components/hospitality/subheader/SubHeader';
import { MainSection } from '../components/hospitality/mainSection/MainSection';
import { TaskPane } from '../components/hospitality/panes/taskPane/TaskPane';
import { SettingsPane } from '../components/hospitality/panes/settingsPane/SettingsPane';
import { WorkTypesPane } from '../components/hospitality/panes/workTypesPane/WorkTypesPane';
import { SupervisorsPane } from '../components/hospitality/panes/supervisorsPane/SupervisorsPane';
import { InformationPopup } from '../components/hospitality/panes/informationPopup/InformationPopup';

export const HospitalityPage = () => {
    const dispatch = useDispatch();

    const objectsFetching = useSelector(objectsFetchingState);
    const objects = useSelector(objectsState);
    const object = useSelector(objectState);

    const [supervisorPaneIsOpen, setSupervisorPaneIsOpen] = useState(false);
    const [taskPaneIsOpen, setTaskPaneIsOpen] = useState(false);
    const [settingsPaneIsOpen, setSettingsPaneIsOpen] = useState(false);
    const [workTypesPaneIsOpen, setWorkTypesPaneIsOpen] = useState(false);
    const [infoPopupIsOpen, setInfoPopupIsOpen] = useState(false);
    const [activeSectionId, setActiveSectionId] = useState(1);

    const informationButtonRef = useRef(null);

    useEffect(() => {
        if (!objects.length) {
            dispatch(getHospitalityObjects());
        }
        return () => {
            dispatch(setObjects([]));
            dispatch(setObject(null));
        };
    }, []);

    useEffect(() => {
        if (object && object.id) {
            dispatch(getSettings());
        }

        return () => {
            setActiveSectionId(1);
            dispatch(
                setFilterTasks({
                    isTouched: false,
                    clearTypes: [],
                    statuses: [],
                    rooms: {
                        from: '',
                        to: '',
                    },
                })
            );
        };
    }, [object]);

    //автообновление раз в 5 мин
    useEffect(() => {
        const intervalHandler = () => {
            dispatch(getTasksForCleaners());
            dispatch(getTasksForSupervisors());
        };
        let interval = setInterval(intervalHandler, 5 * 60 * 1000);

        const listener = () => {
            if (interval) {
                clearInterval(interval);
            }
            interval = setInterval(intervalHandler, 5 * 60 * 1000);
        };

        document.addEventListener('click', listener);

        return () => {
            document.removeEventListener('click', listener);
            clearInterval(interval);
        };
    }, []);

    return (
        <>
            <Header
                setTaskPaneIsOpen={setTaskPaneIsOpen}
                setWorkTypesPaneIsOpen={setWorkTypesPaneIsOpen}
                setSettingsPaneIsOpen={setSettingsPaneIsOpen}
                setInfoPopupIsOpen={setInfoPopupIsOpen}
                informationButtonRef={informationButtonRef}
            />

            <SubHeader setSupervisorPaneIsOpen={setSupervisorPaneIsOpen} activeSectionId={activeSectionId} setActiveSectionId={setActiveSectionId} />

            {objectsFetching ? (
                <div className="w-full h-full flex justify-center items-center">
                    <Loader />
                </div>
            ) : (
                <MainSection activeSectionId={activeSectionId} />
            )}

            <SupervisorsPane isOpen={supervisorPaneIsOpen} setIsOpen={setSupervisorPaneIsOpen} />
            <TaskPane isOpen={taskPaneIsOpen} setIsOpen={setTaskPaneIsOpen} />
            <SettingsPane isOpen={settingsPaneIsOpen} setIsOpen={setSettingsPaneIsOpen} />
            <WorkTypesPane isOpen={workTypesPaneIsOpen} setIsOpen={setWorkTypesPaneIsOpen} />
            <InformationPopup isOpen={infoPopupIsOpen} setIsOpen={setInfoPopupIsOpen} buttonRef={informationButtonRef} />

            <ReactTooltip html={true} type="dark" place="top" effect="solid" delayShow={500} className="max-w-xs" />
        </>
    );
};
