import { Link } from 'react-router-dom';

export const DocumentLink = ({ link }) => {
    return (
        <>
            <Link to={link} className="text-xs italic hover:underline hover:text-green transition-colors">
                Перейти к системе
            </Link>
        </>
    );
};
