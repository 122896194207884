import { SimpleSelect } from '../../../../../common/widgets/SimpleSelect';

export const NodeStatusSelect = ({ node, statuses, callback }) => {
    const options = statuses
        ? statuses.map(status => ({
              label: status.name,
              value: status.name,
              ...status,
          }))
        : [];

    return (
        <SimpleSelect
            isClearable={false}
            options={options}
            placeholder="Выберите статус"
            value={[{ label: node.name, value: node.name }]}
            callback={callback}
            className="w-[12%] shrink-0 pr-1"
        />
    );
};
