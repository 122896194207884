import { useRef, useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { t } from 'i18next';

import { Sidebar } from './sidebar/Sidebar';
import { Header } from './Header';
import { ScrollToTop } from './ScrollToTop';

export const MainLayout = () => {
    const [cookie] = useCookies();

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const sidebarTrigger = useRef(null);

    useEffect(() => {
        document.title = t('KAIZEN_TITLE');
    }, [cookie]);

    return (
        <div className="flex h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} sidebarTrigger={sidebarTrigger} />

            <div className="relative w-full flex flex-col overflow-y-scroll overflow-x-hidden" id="wrapper">
                <Header setSidebarOpen={setSidebarOpen} sidebarTrigger={sidebarTrigger} />
                <Outlet />
                <ScrollToTop />
            </div>

            <ToastContainer />
        </div>
    );
};
