import { useTranslation } from 'react-i18next';

import { DatePicker } from '../../../../ui/datePicker/DatePicker';

export const PeriodOfActivitySection = ({ periodOfActivity, setPeriodOfActivity, isDisabled }) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="mb-3 text-xs text-neutral-900 font-medium">{t('NEWS_AND_POLLS_PERIOD_OF_ACTIVITY_OF_NEWS_OR_AGREEMENT')}</div>

            <div className="flex flex-row gap-6 items-center">
                <div className="flex flex-row items-center gap-3">
                    <span className="text-sm text-neutral-900">{t('FROM')}</span>

                    <div className="h-10 w-44">
                        <DatePicker
                            selectedDate={periodOfActivity.from}
                            callback={value => {
                                setPeriodOfActivity(prevValue => ({
                                    from: value,
                                    to: prevValue.to ? (prevValue.to.getTime() > value.getTime() ? prevValue.to : null) : null,
                                }));
                            }}
                            minDate={new Date()}
                            showTimeSelect
                            disabled={isDisabled}
                        />
                    </div>
                </div>

                <div className="flex flex-row items-center gap-3">
                    <span className="text-sm text-neutral-900">{t('TO')}</span>

                    <div className="h-10 w-44">
                        <DatePicker
                            selectedDate={periodOfActivity.to}
                            callback={value => {
                                setPeriodOfActivity(prevValue => ({
                                    from: prevValue.from ? (prevValue.from.getTime() < value.getTime() ? prevValue.from : null) : null,
                                    to: value,
                                }));
                            }}
                            minDate={new Date()}
                            showTimeSelect
                            disabled={isDisabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
