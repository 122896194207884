import { useState, useEffect } from 'react';

import { SimpleSelect } from '../../../../../common/widgets/SimpleSelect';

export const ButtonControl = ({ nodeOptions, setValues, setWritePoint, setCheckPopupFields, control }) => {
    const [data, setData] = useState({ writePoint: null, value: '' });

    useEffect(() => {
        try {
            JSON.parse(control.data);
        } catch (e) {
            const writePoint = nodeOptions.length > 0 && nodeOptions.find(el => el.value === control.data.write_point);

            setData({ writePoint, value: control.data.values[0].value });
            setValues([{ value: control.data.values[0].value, writePoint }]);
            setWritePoint(writePoint.value);
        }
    }, [control, nodeOptions]);

    useEffect(() => {
        data.value !== '' && data.writePoint ? setCheckPopupFields(true) : setCheckPopupFields(false);
    }, [data]);
    return (
        <>
            <SimpleSelect
                isClearable={false}
                options={nodeOptions}
                placeholder="Выберите точку"
                value={data.writePoint}
                isSearchable={true}
                callback={val => {
                    if (val === null) {
                        return;
                    }
                    setWritePoint(val.value);
                    setValues([]);
                    setData({ value: '', writePoint: val });
                }}
                className="w-56 mr-5"
            />

            {data?.writePoint?.datatype !== 'boolean' ? (
                <input
                    type="text"
                    value={data.value}
                    disabled={!data.writePoint}
                    placeholder="Значение"
                    className="w-24 h-10 mr-5 text-sm"
                    onChange={e => {
                        setValues([{ label: '', value: e.target.value.trim() }]);
                        setData({ ...data, value: e.target.value.trim() });
                    }}
                />
            ) : (
                <SimpleSelect
                    isClearable={false}
                    options={[
                        { value: 1, label: 'true' },
                        { value: 0, label: 'false' },
                    ]}
                    placeholder="Выберите значение"
                    value={data.value}
                    callback={val => {
                        setData({ ...data, value: val });
                        setValues([{ label: '', value: val.label }]);
                    }}
                    className="w-24 mr-5"
                />
            )}
        </>
    );
};
