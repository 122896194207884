export const Checkbox = ({ name = 'checkbox', value = '', title = '', onClick = () => {}, checked = false, disabled = false }) => (
    <div className="inline-flex">
        <label className={`flex items-center gap-2 select-none text-inherit text-gray-20 ${!disabled ? 'cursor-pointer hover:text-blue' : ''}`}>
            <input
                name={name}
                type="checkbox"
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={onClick}
                className={`shrink-0 ${!disabled ? 'cursor-pointer' : 'text-gray-10'}`}
            />
            {!!title && <p className="line-clamp-1 w-auto">{title}</p>}
        </label>
    </div>
);
