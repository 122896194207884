import { SimpleSelect } from '../../../common/widgets/SimpleSelect';

export const FilterSelect = ({ data, setData, setFilter, options, type, placeholder }) => {
    return (
        <SimpleSelect
            value={data[type]}
            options={options}
            callback={val => {
                setData(prev => ({ ...prev, [type]: val }));
                setFilter({ type, value: val ? val.value : null });
            }}
            isClearable={true}
            isSearchable={true}
            className="w-48 text-sm"
            placeholder={placeholder}
            isDisabled={false}
        />
    );
};
