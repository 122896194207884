import { ButtonRectangular } from '../../../common/btn/ButtonRectangular';

export const CreateVenuePopupButton = ({ disabled, onClick, title }) => {
    return (
        <ButtonRectangular
            className="w-40 ml-auto mr-auto justify-center"
            // disabled={venue.venueName.length === 0 || venue.servers.length === 0}
            disabled={disabled}
            onClick={onClick}
        >
            {title}
        </ButtonRectangular>
    );
};
