import { SimpleSelect } from '../../../../common/widgets/SimpleSelect';

export const ListControl = ({ controlsSelectsValues, control, handlerSetControlValue, isControlPanelControl }) => {
    return (
        <SimpleSelect
            value={controlsSelectsValues.length !== 0 ? controlsSelectsValues.find(el => el.cn_id === control.cn_id)?.values : ''}
            options={control.data.values}
            callback={val => handlerSetControlValue(control, val)}
            isClearable={false}
            className="w-36 text-xs controlSelect"
            placeholder="Выберете значение"
            isDisabled={!isControlPanelControl}
        />
    );
};
