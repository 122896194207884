import { root } from '../config';

export const hospitalityAPI = Object.freeze({
    // Получить список объектов
    getHospitalityObjects: {
        fetch: (username, auth_key_calendar, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/hotels/get-objects`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список статусов задач
    getTasksStatuses: {
        fetch: (username, auth_key_calendar, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/hotels/get-task-statuses`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список помещений (комнат)
    getRooms: {
        fetch: (username, auth_key_calendar, lang, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/get-rooms`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список типовых работ
    getTypicalTasks: {
        fetch: (username, auth_key_calendar, lang, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/get-typical-task-types`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список настроек для объекта
    getSettings: {
        fetch: (username, auth_key_calendar, lang, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in data) {
                if (data[item]) {
                    formData.append(item, data[item]);
                }
            }

            return fetch(`${root}?r=api/hotels/get-settings`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список горничных
    getCleaners: {
        fetch: (username, auth_key_calendar, lang, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in data) {
                if (data[item]) {
                    formData.append(item, data[item]);
                }
            }

            return fetch(`${root}?r=api/hotels/get-today-workers`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список супервайзеров
    getSupervisors: {
        fetch: (username, auth_key_calendar, lang, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/get-today-supervisors`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список слотов
    getSlots: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                if (data[item]) {
                    formData.append(item, data[item]);
                }
            }

            return fetch(`${root}?r=api/hotels/get-today-slots`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список задач для горничных
    getTasksForCleaners: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                if (data[item]) {
                    formData.append(item, data[item]);
                }
            }

            return fetch(`${root}?r=api/hotels/get-tasks`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список задач для супервайзеров
    getTasksForSupervisors: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('for_supervisor', '1');

            for (const item in data) {
                if (data[item]) {
                    formData.append(item, data[item]);
                }
            }

            return fetch(`${root}?r=api/hotels/get-tasks`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Назначить супервайзеров на объект
    setSupervisorsAtWork: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/set-today-supervisor`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Назначить горничную на слот
    assignWorkerToSlot: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();

            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                if (data[item]) {
                    formData.append(item, data[item]);
                }
            }

            return fetch(`${root}?r=api/hotels/assign-worker-to-slot`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Перемещение горничной внутри шахматки
    moveCleaners: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }
            return fetch(`${root}?r=api/hotels/move-workers`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Перемещение задачи внутри шахматки
    moveTask: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/move-task`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Применить распределение горничных на супервайзеров
    applyWorkersAndSupervisors: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-apply-all-supervisors`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Применить распределение задач на слоты
    applyTasksAndSlots: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-apply-all`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Применить распределение задач на супервайзеров
    applyTasksAndSupervisors: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-apply-supervisor-all`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Сбросить распределение горничных на супервайзеров
    resetWorkersAndSupervisors: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-reset-all-supervisors`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Сбросить распределения задач на слоты
    resetTasksAndSlots: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-reset-all`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Сбросить распределения задач на супервайзеров
    resetTasksAndSupervisors: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-reset-supervisor-all`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Автоматическое распределение задач на слоты
    autoDistributeTaskForSlots: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                if (data[item]) {
                    formData.append(item, data[item]);
                }
            }

            return fetch(`${root}?r=api/hotels/act-do-auto`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Автоматическое распределение задач на супервайзеров
    autoDistributeTaskForSupervisors: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-do-auto-supervisor-tasks`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Сбросить распределение горничных для супервайзера
    resetWorkersForSupervisor: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-reset-all-supervisors`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Сбросить распределения задач для слота
    resetTasksForSlot: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-reset-for-slot`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Сбросить распределения задач для супервайзора
    resetTasksForSupervisor: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/act-reset-for-supervisor-tasks`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Обновить список настроек для объекта
    updateSettings: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                if (data[item]) {
                    formData.append(item, data[item]);
                }
            }

            return fetch(`${root}?r=api/hotels/set-settings`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Сортировка типов уборки
    sortTypesOfWorks: {
        fetch: data => {
            const formData = new FormData();

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/set-task-types-sort-order`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить статус номера (чистый/грязный)
    getRoomStatus: {
        fetch: data => {
            const formData = new FormData();

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/get-ecvi-room-status`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Создать заявку
    createTask: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                formData.append(item, data[item]);
            }

            return fetch(`${root}?r=api/hotels/create-task`, {
                method: 'POST',
                body: formData,
            });
        },
    },
});
