import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { CancelCopiedDocumentButton } from './CancelCopiedDocumentButton';
import { InsertDocumentWithDataPointAndPositionButton } from './insertButtons/InsertDocumentWithDataPointAndPositionButton';
import { InsertDocumentWithDataPointsButton } from './insertButtons/InsertDocumentWithDataPointsButton';
import { InsertDocumentWithPositionButton } from './insertButtons/InsertDocumentWithPositionButton';
import { InsertDocumentWithoutInfoButton } from './insertButtons/InsertDocumentWithoutInfoButton';

import { copyBMSDocument, selectedDocumentForCopyState } from '../../../../redux/slices/bms/bmsSliceDocuments';

export const CopiedDocument = ({}) => {
    const { venueId, systemId } = useParams();

    const dispatch = useDispatch();
    const selectedDocumentForCopy = useSelector(selectedDocumentForCopyState);

    const handlerCopyDocument = option => {
        const data = { venueId, systemId, docId: selectedDocumentForCopy };
        switch (option) {
            case 'withoutAll':
                data.options = ['reset_points', 'reset_positions', 'mark_as_not_checked'];
                dispatch(copyBMSDocument({ data }));
                return;
            case 'position':
                data.options = ['reset_points', 'mark_as_not_checked'];
                dispatch(copyBMSDocument({ data }));
                return;
            case 'dataPoints':
                data.options = ['reset_positions', 'mark_as_not_checked'];
                dispatch(copyBMSDocument({ data }));
                return;
            case 'withAll':
                data.options = ['mark_as_not_checked'];
                dispatch(copyBMSDocument({ data }));
                return;
            default:
                break;
        }
    };

    return (
        <div className="relative flex flex-wrap justify-center items-center overflow-hidden w-52 h-28 p-2 border-gray-10 rounded-md bg-white">
            <CancelCopiedDocumentButton />
            <div className="flex">
                <InsertDocumentWithoutInfoButton callback={handlerCopyDocument} />

                <InsertDocumentWithPositionButton callback={handlerCopyDocument} />
            </div>
            <div className="flex">
                <InsertDocumentWithDataPointsButton callback={handlerCopyDocument} />

                <InsertDocumentWithDataPointAndPositionButton callback={handlerCopyDocument} />
            </div>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#707183E5" effect="solid" className="!rounded-md !py-2 !px-2.5 max-w-prose" />
        </div>
    );
};
