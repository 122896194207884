import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { toastOptions } from '../../../../api/functions';
import {
    resetWorkersForSupervisor,
    setSupervisorsAtWork,
    supervisorsState,
    cleanersState,
    cleanersFetchingState,
} from '../../../../redux/slices/hospitalitySlice';

import { Droppable } from '../../../common/dnd/Droppable';
import { EditButton } from '../components/editSection/EditButton';
import { EditPopup } from '../components/editSection/EditPopup';
import { List } from '../components/list/List';
import { Loader } from '../../../common/Loader';
import { SvgComponent } from '../../../common/svgComponent/SvgComponent';

export const SupervisorWithCleanersSlot = ({ id, name, isOpenMenu, setIsOpenMenu, activeMenuRef }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const cleaners = useSelector(cleanersState);
    const cleanersFetching = useSelector(cleanersFetchingState);
    const supervisors = useSelector(supervisorsState);

    const supervisorCleaners = useMemo(
        () => cleaners.filter(worker => worker.supervisorId === id).map(worker => ({ ...worker, title: worker.name, description: worker.name })),
        [cleaners, id]
    );

    const [isOpenTasks, setIsOpenTasks] = useState(false);
    const [isLoading, setIsLoading] = useState('');

    const deleteSupervisorHandler = async () => {
        setIsLoading('delete');

        const supervisorIds = supervisors.filter(supervisor => supervisor.state && supervisor.id !== id).map(supervisor => supervisor.id);
        const result = await dispatch(setSupervisorsAtWork({ supervisorIds }));

        if (result.error) {
            toast.error(result.message, toastOptions);
        }

        setIsLoading('');
        setIsOpenMenu(null);
    };

    const resetWorkersHandler = async () => {
        setIsLoading('reset');

        const result = await dispatch(resetWorkersForSupervisor({ id }));

        if (result.error) {
            toast.error(result.message, toastOptions);
        }

        setIsLoading('');
        setIsOpenMenu(null);
    };

    return (
        <div className={`w-56 bg-white rounded relative box-border ${isOpenTasks ? 'shadow-3xl h-fit' : 'h-20'} ${isOpenMenu === id ? 'z-20' : 'z-10'}`}>
            <Droppable id={id}>
                <div className="w-full pl-3 flex flex-col">
                    <div className="flex flex-row items-center w-full relative border-b border-b-gray-10 h-10 pr-3">
                        <div className="w-full text-xs font-semibold text-gray-20 line-clamp-1">{name}</div>

                        <div className="h-full shrink-0" ref={isOpenMenu === id ? activeMenuRef : null}>
                            <EditButton onClick={() => setIsOpenMenu(isOpenMenu !== id ? id : null)} />

                            <EditPopup isOpen={isOpenMenu === id}>
                                <button
                                    className="flex justify-between items-center text-xs text-left px-3 py-2 whitespace-nowrap hover:text-blue"
                                    disabled={!!isLoading}
                                    onClick={deleteSupervisorHandler}
                                >
                                    {t('HOSPITALITY_REMOVE_SUPERVISOR')}
                                    {isLoading === 'delete' && <Loader width={14} height={14} />}
                                </button>
                                <button
                                    className="flex justify-between items-center text-xs text-left px-3 py-2 whitespace-nowrap hover:text-blue"
                                    disabled={!!isLoading}
                                    onClick={resetWorkersHandler}
                                >
                                    {t('HOSPITALITY_CLEAR_EMPLOYEES')}
                                    {isLoading === 'reset' && <Loader width={14} height={14} />}
                                </button>
                            </EditPopup>
                        </div>
                    </div>

                    <button className="w-full h-10 flex flex-row items-center text-blue" onClick={() => setIsOpenTasks(!isOpenTasks)}>
                        <div className="flex text-xs">
                            {t('HOSPITALITY_EMPLOYEES')}:{' '}
                            {cleanersFetching ? (
                                <div className="ml-1">
                                    <Loader height={12} width={12} />
                                </div>
                            ) : (
                                supervisorCleaners.length
                            )}
                        </div>

                        <div className="w-4 h-4 ml-auto mr-3">
                            <SvgComponent name="chevron-down" />
                        </div>
                    </button>
                </div>

                <List
                    isOpen={isOpenTasks}
                    setIsOpen={setIsOpenTasks}
                    items={supervisorCleaners}
                    itemsTitle={t('HOSPITALITY_EMPLOYEES')}
                    loading={cleanersFetching}
                />
            </Droppable>
        </div>
    );
};
