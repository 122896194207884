import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { tasksForSupervisorsFetchingState, tasksForSupervisorsState } from '../../../../../../redux/slices/hospitalitySlice';

import { Loader } from '../../../../../common/Loader';
import { useTranslation } from 'react-i18next';

export const TasksCounter = () => {
    const { t } = useTranslation();

    const tasksFetching = useSelector(tasksForSupervisorsFetchingState);
    const tasks = useSelector(tasksForSupervisorsState);

    const allocatedTasks = useMemo(() => tasks.filter(task => task.userId), [tasks]);

    return (
        <div className="flex flex-row items-center gap-1 font-medium text-gray-500 text-sm px-6 pt-3 pb-1">
            {t('HOSPITALITY_ALLOCATED_TASKS')}:{' '}
            {tasksFetching ? <Loader width={12} height={12} /> : <span className="text-gray-20 font-semibold">{allocatedTasks.length}</span>}
        </div>
    );
};
