import { MultiSelect } from '../../../../../common/widgets/MultiSelect';

export const DataPointSelect = ({ options, data, setData }) => {
    return (
        <div className="flex items-center text-sm">
            <span className="mr-2">Точки</span>
            <MultiSelect
                value={data.dataPoint}
                options={options}
                callback={val => setData({ ...data, dataPoint: val })}
                isClearable={false}
                className="w-48 text-sm controlSelect hideRemoveSelectButton"
                placeholder="Выберите точки"
                maxSelect={10}
                isSearchable={true}
            />
            <div className="ml-2" data-tip="Выбрать можно не более 10 точек данных">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                        stroke="#1890FF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </div>
    );
};
