import { createSlice } from '@reduxjs/toolkit';
import { url } from '../../api/config';
import { api } from '../../api';
import { set_user_role } from './qualificationSlice';

export const loginSlice = createSlice({
    name: 'login',

    initialState: {
        auth_fetching: false,
        username: '',
        auth_key_calendar: '',
        name: '',
        fullName: '',
        lang: '',
        lang_fetching: false,
        lang_data: {},
        user: {
            roles: {
                bms: {
                    role: '',
                    actions: [],
                },
            },
            id: '',
        },
    },

    reducers: {
        auth: (state, action) => {
            state.username = action.payload.username;
            state.auth_key_calendar = action.payload.auth_key_calendar;
            state.name = action.payload.name;
        },

        auth_fetching_start: state => {
            state.auth_fetching = true;
        },

        auth_fetching_end: state => {
            state.auth_fetching = false;
        },

        change_lang: (state, { payload }) => {
            state.lang = payload;
        },

        update_user_roles: (state, action) => {
            state.user.roles = { ...state.user.roles, ...action.payload };
        },
        setUserFullName: (state, action) => {
            state.fullName = action.payload;
        },
    },
});

export const { setUserFullName, auth, auth_fetching_start, auth_fetching_end, change_lang, update_user_roles } = loginSlice.actions;

export const authFetching =
    ({ username, auth_key_calendar, langCookies }) =>
    async dispatch => {
        await dispatch(auth_fetching_start());

        try {
            if (username && auth_key_calendar) {
                const response = await api.isAuth.fetch(username, auth_key_calendar, langCookies);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 58) {
                        document.location.href = url;
                    } else {
                        const role = res.data.roles.find(el => el.includes('qualification'))?.split('.')[1];
                        await dispatch(
                            auth({
                                name: username,
                                username,
                                auth_key_calendar,
                            })
                        );
                        await dispatch(set_user_role(role));

                        localStorage.setItem('username', username);
                        localStorage.setItem('auth_key_calendar', auth_key_calendar);
                    }
                }
            } else {
                document.location.href = url;
            }
        } catch (e) {
            console.log('authFetching error: ', e);
        } finally {
            await dispatch(auth_fetching_end());
        }
    };

// задать вопрос относительно обёртки async dispatch
export const setLanguageFetch =
    ({ username, auth_key_calendar, lang }) =>
    async dispatch => {
        try {
            if (lang) {
                const response = await api.setLanguage.fetch(username, auth_key_calendar, lang);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 58) {
                        // alert('The language you installed is not supported');
                        // document.location.href = url;
                    }
                    await dispatch(change_lang(lang));
                }
            } else {
                // document.location.href = url;
            }
        } catch (e) {
            console.log('setLangFetching error:', e);
        }
    };

export const getLanguage =
    ({ username, auth_key_calendar }) =>
    async dispatch => {
        try {
            if (username && auth_key_calendar) {
                const response = await api.getLanguageData.fetch(username, auth_key_calendar);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 58) {
                        document.location.href = url;
                    }

                    await dispatch(change_lang(res.data.lang));
                }
            } else {
                document.location.href = url;
            }
        } catch (e) {
            console.log('setLangFetching error:', e);
        }
    };

export const auth_fetching = state => state.login.auth_fetching;
export const username = state => state.login.username;
export const auth_key_calendar = state => state.login.auth_key_calendar;
export const name = state => state.login.name;
export const lang = state => state.login.lang;
export const userRolesState = state => state.login.user.roles;

export default loginSlice.reducer;
