export const RangeCheckbox = ({ nodeStatusInfo, callback }) => {
    return (
        <div className="w-[6%] shrink-0 px-1 flex justify-center">
            <input
                disabled={!nodeStatusInfo.node || nodeStatusInfo.type === 'boolean'}
                className="cursor-pointer hover:border-gray-30"
                name="range"
                type="checkbox"
                value={nodeStatusInfo.range}
                checked={nodeStatusInfo.range}
                onChange={() => {}}
                onClick={e => callback('range', e.target.checked)}
            />
        </div>
    );
};
