import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import './i18n';

import { store } from './redux/store.ts';
import { router } from './navigation/router';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </CookiesProvider>
);
