import { Link } from 'react-router-dom';

export const OpenEventLogPageButton = () => {
    return (
        <Link
            to={'/bms/eventLog'}
            target="_blank"
            className="w-auto h-10 px-6 flex items-center justify-around whitespace-nowrap transition-colors border rounded-md text-white border-green bg-green hover:bg-green-600"
        >
            Журнал событий
        </Link>
    );
};
