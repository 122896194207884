import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { CalendarSelect } from './CalendarSelect';
import { DataPointSelect } from './DataPointSelect';
import { PeriodSelect } from './PeriodSelect';
import { StepSelect } from './StepSelect';
import { bmsDataTypeTimeIntervalsState, getBMSTypesTimeInterval } from '../../../../../../redux/slices/bms/bmsSliceServers';

const initialState = {
    step: [],
    period: [],
};

export const SettingsPane = ({ data, setData, dataPoints }) => {
    const dispatch = useDispatch();
    const bmsDataTypeTimeIntervals = useSelector(bmsDataTypeTimeIntervalsState);

    const [dataOptions, setDataOptions] = useState(initialState);

    const dataPointOptions = dataPoints.map(dataPoint => ({ value: dataPoint.id, label: dataPoint.path }));

    useEffect(() => {
        const stepOptions = bmsDataTypeTimeIntervals
            .filter(step => step.use_step === 1)
            .map(step => ({
                ...step,
                // отключил запрет на выбор маленьких шагов при большом периоде
                // isDisabled: (data.period.value >= 10 || data.calendar.length > 0) && step.value < 5 ? true : false,
            }));
        const periodOptions = bmsDataTypeTimeIntervals
            .filter(period => period.use_period === 1)
            .map(period => ({
                ...period,
                // отключил запрет на выбор больших периодов при маленьком шаге
                // isDisabled: data.step.value < 5 && period.value >= 10 ? true : false,
            }));
        setDataOptions({ stepOptions, periodOptions });
    }, [data]);

    useEffect(() => {
        if (!bmsDataTypeTimeIntervals.length) {
            dispatch(getBMSTypesTimeInterval());
        }
    }, []);

    return (
        <div className="bg-slate-300 flex justify-around p-2">
            <DataPointSelect data={data} setData={setData} options={dataPointOptions} />
            <PeriodSelect options={dataOptions.periodOptions} data={data} setData={setData} />
            <StepSelect options={dataOptions.stepOptions} data={data} setData={setData} />
            <CalendarSelect data={data} setData={setData} />
        </div>
    );
};
