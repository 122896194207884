import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PopupContextMenu } from '../../../../common/popup/PopupContextMenu';
import { PopupActionConfirmation } from '../PopupActionConfirmation';

import { useCheckRole } from '../../../../../hooks/useCheckRole';
import {
    update_bms_document_node,
    documentState as documentState_state,
    setHistoryRemovedNodes,
    setSelectedNodesForCopy,
} from '../../../../../redux/slices/bms/bmsSliceDocuments';

export const EditNodeContextMenu = ({ isOpen, setIsOpen, nodeId, buttonRef, node, editNodeHandler, selectedNodes }) => {
    const dispatch = useDispatch();
    const documentState = useSelector(documentState_state);

    const [isOpenActionPopup, setIsOpenActionPopup] = useState(false);

    const isDocumentNodeEdit = useCheckRole('bms', 'bms.document_nodes.edit');
    const isDocumentNodeDelete = useCheckRole('bms', 'bms.document_nodes.del');

    const handlerTopLayout = () => {
        const maxZIdx = documentState.nodes.reduce((acc, cur) => (acc > cur.position?.zIndex ? acc : cur.position.zIndex), 0);
        dispatch(update_bms_document_node({ ...node, position: { ...node.position, zIndex: maxZIdx ? maxZIdx + 1 : 1 } }));

        setIsOpen(false);
    };

    const handlerBottomLayout = () => {
        const minZIdx = documentState.nodes.reduce((acc, cur) => (acc < cur.position?.zIndex ? acc : cur.position.zIndex), 0);
        dispatch(update_bms_document_node({ ...node, position: { ...node.position, zIndex: minZIdx ? minZIdx - 1 : -1 } }));

        setIsOpen(false);
    };

    const handlerEditNode = () => {
        editNodeHandler(node);
        setIsOpen(false);
    };

    const handlerRemoveNode = () => {
        dispatch(setHistoryRemovedNodes(nodeId));
        setIsOpenActionPopup(false);
    };

    const handlerCopyNode = () => {
        if (selectedNodes.current.length > 0) {
            dispatch(setSelectedNodesForCopy(selectedNodes.current));
            setIsOpen(false);
        } else {
            dispatch(setSelectedNodesForCopy([nodeId]));
            setIsOpen(false);
        }
    };

    return (
        <>
            <PopupContextMenu className="w-auto" isOpen={isOpen} setIsOpen={setIsOpen} buttonRef={buttonRef}>
                {isDocumentNodeEdit && (
                    <>
                        <div className=" border-b w-48">
                            <button onClick={handlerTopLayout} className="w-full text-left text-xs mb-1 cursor-pointer hover:bg-slate-200 pl-2 p-1 rounded-md">
                                Поместить на передний план
                            </button>
                            <button
                                onClick={handlerBottomLayout}
                                className="w-full text-left text-xs mb-1 cursor-pointer hover:bg-slate-200 pl-2 p-1 rounded-md"
                            >
                                Поместить на задний план
                            </button>
                        </div>
                        <button onClick={handlerEditNode} className="w-full text-left text-xs cursor-pointer  hover:bg-slate-200 pl-2 p-1 mt-1 rounded-md">
                            Редактировать
                        </button>

                        <button
                            onClick={handlerCopyNode}
                            className="preventEvent w-full text-left text-xs cursor-pointer hover:bg-slate-200 pl-2 p-1 rounded-md"
                        >
                            Скопировать
                        </button>
                    </>
                )}

                {isDocumentNodeDelete && (
                    <button
                        onClick={() => setIsOpenActionPopup(true)}
                        className="w-full text-left text-xs cursor-pointer hover:bg-slate-200 pl-2 p-1 rounded-md"
                    >
                        Удалить
                    </button>
                )}
            </PopupContextMenu>
            <PopupActionConfirmation
                isOpen={isOpenActionPopup}
                setIsOpen={setIsOpenActionPopup}
                callback={handlerRemoveNode}
                element={node.name}
                title="Вы действительно хотите удалить"
            />
        </>
    );
};
