import { useEffect, useState } from 'react';
import Select, { components, createFilter } from 'react-select';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

const Option = props => (
    <components.Option {...props}>
        <div className="flex items-center gap-2">
            <input type="checkbox" checked={props.isSelected} onChange={() => null} />
            <p>{props.label}</p>
        </div>
    </components.Option>
);

export const SimpleSelect = ({
    options = [],
    placeholder = 'Выберите опцию',
    isClearable = true,
    isSearchable = false,
    isLoading = false,
    isDisabled = false,
    value = [],
    callback,
    className,
    closeMenuOnSelect,
    menuPosition = 'absolute',
    menuPlacement = 'auto',
}) => {
    const { t } = useTranslation();

    const [values, setValues] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setValues(value);
    }, [value]);

    const classes = twMerge(`select text-sm w-48 ${className}`);

    const changeHandler = value => {
        setValues(value);
        callback(value);
    };

    const closeMenuHandler = () => {
        if (isOpen) {
            setIsOpen(false);
        }
    };

    const openMenuHandler = () => {
        setIsOpen(true);
    };

    return (
        <Select
            options={options}
            value={values}
            filterOption={createFilter({
                matchFrom: 'any',
                stringify: option => `${option.label}`,
            })}
            onChange={changeHandler}
            isLoading={isLoading}
            isDisabled={isDisabled}
            onMenuClose={closeMenuHandler}
            onMenuOpen={openMenuHandler}
            noOptionsMessage={() => t('SELECT_NO_DATA')}
            isClearable={isClearable}
            isSearchable={isSearchable}
            placeholder={placeholder}
            classNamePrefix="filter"
            className={classes}
            closeMenuOnSelect={closeMenuOnSelect}
            hideSelectedOptions={false}
            components={{ Option }}
            menuPosition={menuPosition}
            menuPlacement={menuPlacement}
        />
    );
};
