import { twMerge } from 'tailwind-merge';

import { Loader } from '../Loader';

export const ButtonRectangular = ({ children, onClick, className = '', buttonRef = null, disabled = false, loading = false }) => {
    const paddingClasses = Array.isArray(children) && children[0].type === 'svg' ? 'pl-4 pr-6' : 'px-6';
    const disabledClasses = disabled ? 'border-gray-30 bg-gray-30 hover:bg-gray-30 text-white' : '';

    const buttonClasses = twMerge(
        `relative overflow-hidden w-auto h-10 text-sm font-medium whitespace-nowrap transition-colors border rounded-md text-white border-green bg-green hover:bg-green-600 ${paddingClasses} ${className} ${disabledClasses}`
    );

    return (
        <button onClick={onClick} className={buttonClasses} ref={buttonRef} disabled={disabled || loading}>
            {loading && (
                <div className="absolute left-0 top-0 flex justify-center items-center w-full h-full">
                    <Loader height={16} width={16} color="#7e7e7e" />
                </div>
            )}
            <div className={`flex items-center justify-center gap-2 ${loading ? 'opacity-0' : ''}`}>{children}</div>
        </button>
    );
};
