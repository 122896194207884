export const DeleteEmployeeButton = ({ title, callback, employee }) => {
    return (
        <button
            onClick={() => callback(employee.employeeId)}
            className="text-xs ml-3 border rounded-md px-1 py-0.5 bg-slate-200 hover:bg-red-500 hover:text-black"
            type="button"
        >
            {title}
        </button>
    );
};
