export const UseBitCheckbox = ({ data, setData, setValues }) => {
    return (
        <div className="flex items-center mt-3">
            <span className="text-sm mr-2">Использовать битную маску</span>
            <input
                disabled={!data.writePoint || data.writePoint.type === 'boolean' || data.writePoint.type === 'float' || !data.writePoint.bitSize}
                className="cursor-pointer hover:border-gray-30"
                name="range"
                type="checkbox"
                value={data.bit}
                checked={data.bit}
                onChange={() => {}}
                onClick={e => {
                    setData({ ...data, bit: e.target.checked, originValue: '', passedValue: '' });
                    setValues([{ ...data, bit: e.target.checked, originValue: '', passedValue: '' }]);
                }}
            />
        </div>
    );
};
