import { IoIosRemoveCircle } from 'react-icons/io';

export const RemoveStatusButton = ({ setNodeStates, node }) => {
    return (
        <button
            type="button"
            className="w-5 h-5 flex justify-center items-center text-red-500 hover:text-red-700"
            onClick={() => {
                setNodeStates(prev => prev.filter(el => el.id !== node.id));
            }}
        >
            <IoIosRemoveCircle color="inherit" size={20} />
        </button>
    );
};
