import { useState } from 'react';
import { t } from 'i18next';

import { book } from '../../navigation/book';
import { useCheckRole } from '../../hooks/useCheckRole';
import { SubHeader } from '../../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../../components/common/subheader/SubHeaderLeft';
import { ContentContainer } from '../../components/common/ContentContainer';
import { Title } from '../../components/common/subheader/Title';
import { CreateSystemButton } from '../../components/bms/systems/CreateSystemButton';
import { SystemList } from '../../components/bms/systems/SystemList';
import { CreateSystemPopup } from '../../components/bms/systems/CreateSystemPopup';
import { VenueName } from '../../components/bms/systems/VenueName';
import { BackButton } from '../../components/common/subheader/BackButton';
import { NoAccessPage } from '../../components/common/noAccess/NoAccessPage';

export const BMSSystemPage = () => {
    const [popupIsOpen, setPopupIsOpen] = useState(false);

    const isObjectView = useCheckRole('bms', 'bms.object.view');
    const isObjectDirectionsAdd = useCheckRole('bms', 'bms.object_directions.add');

    if (!isObjectView) {
        return <NoAccessPage />;
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <BackButton link="/bms/venues" />
                    <Title>{t(book.bms_system.key)}</Title>
                </SubHeaderLeft>
            </SubHeader>
            <ContentContainer>
                <VenueName />
                <div className="flex flex-wrap gap-8">
                    {isObjectDirectionsAdd && <CreateSystemButton setPopupIsOpen={setPopupIsOpen} />}

                    <SystemList />
                </div>
            </ContentContainer>
            {isObjectDirectionsAdd && <CreateSystemPopup isOpen={popupIsOpen} setIsOpen={setPopupIsOpen} />}
        </>
    );
};
