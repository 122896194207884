import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import CustomScroll from 'react-custom-scroll';
import { useTranslation } from 'react-i18next';

import { getTasksForCleaners, objectState, tasksState, tasksFetchingState } from '../../../../../redux/slices/hospitalitySlice';

import { Draggable } from '../../../../common/dnd/Draggable';
import { Droppable } from '../../../../common/dnd/Droppable';
import { Loader } from '../../../../common/Loader';
import { Card } from '../../../card/Card';

export const TasksSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const object = useSelector(objectState);
    const tasksFetching = useSelector(tasksFetchingState);
    const tasks = useSelector(tasksState);

    useEffect(() => {
        if (object) {
            dispatch(getTasksForCleaners());
        }
    }, [object]);

    useEffect(() => {
        if (tasks.length) {
            ReactTooltip.rebuild();
        }
    }, [tasks]);

    const unallocatedTasks = useMemo(() => tasks.filter(task => !task.slotId), [tasks]);

    return (
        <div className="w-52 bg-white shrink-0">
            {tasksFetching ? (
                <div className="w-full p-6 flex justify-center items-center">
                    <Loader height={30} width={30} />
                </div>
            ) : (
                <>
                    <div className="font-medium text-gray-500 text-sm px-3 pt-3 pb-1">
                        {unallocatedTasks.length ? (
                            <div className="whitespace-nowrap">
                                {t('HOSPITALITY_UNALLOCATED_TASKS')}: <span className="text-gray-20 font-semibold">{unallocatedTasks.length}</span>
                            </div>
                        ) : (
                            t('HOSPITALITY_NO_UNALLOCATED_TASKS')
                        )}
                    </div>

                    <CustomScroll allowOuterScroll={true} addScrolledClass={true}>
                        <Droppable id="default">
                            <div className="flex flex-col gap-2 bg-white px-3 pt-3 box-border overflow-hidden pb-[50px]">
                                {!!unallocatedTasks.length &&
                                    unallocatedTasks.map(task => (
                                        <Draggable id={task.id} key={task.id} disabled={task.statusId === '3' || task.statusId === '8'}>
                                            <Card typeId={task.clearTypeId} statusId={task.statusId} description={task.description}>
                                                {task.title}
                                            </Card>
                                        </Draggable>
                                    ))}
                            </div>
                        </Droppable>
                    </CustomScroll>
                </>
            )}
        </div>
    );
};
