import { useState } from 'react';
import { t } from 'i18next';

import { book } from '../../navigation/book';
import { useCheckRole } from '../../hooks/useCheckRole';

import { SubHeader } from '../../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../../components/common/subheader/SubHeaderLeft';
import { SubHeaderRight } from '../../components/common/subheader/SubHeaderRight';
import { ContentContainer } from '../../components/common/ContentContainer';
import { Title } from '../../components/common/subheader/Title';
import { TableServers } from '../../components/bms/servers/TableServers';
import { CreateServerButton } from '../../components/bms/servers/CreateServerButton';
import { ServerPopup } from '../../components/bms/servers/serverPopup/ServerPopup';
import { NoAccessPage } from '../../components/common/noAccess/NoAccessPage';
import { BackButton } from '../../components/common/subheader/BackButton';

export const BMSServersPage = () => {
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [serverId, setServerId] = useState(null);

    const isServersView = useCheckRole('bms', 'bms.servers.view');
    const isServersAdd = useCheckRole('bms', 'bms.servers.add');

    if (!isServersView) {
        return <NoAccessPage />;
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <BackButton link="/bms" />
                    <Title>{t(book.bms_server.key)}</Title>
                </SubHeaderLeft>

                <SubHeaderRight>{isServersAdd && <CreateServerButton setPopupIsOpen={setPopupIsOpen} />}</SubHeaderRight>
            </SubHeader>

            <ContentContainer>
                <TableServers setServerId={setServerId} />
            </ContentContainer>

            {isServersAdd && <ServerPopup isOpen={popupIsOpen} setIsOpen={setPopupIsOpen} serverId={serverId} setServerId={setServerId} />}
        </>
    );
};
