import { useRef } from 'react';

import { ControlBox } from './ControlBox';
import { ListControl } from './controlTypes/ListControl';
import { ButtonControl } from './controlTypes/ButtonControl';
import { PresetValueControl } from './controlTypes/PresetValueControl';
import { ToggleControl } from './controlTypes/ToggleControl';
import { TriggerControl } from './controlTypes/TriggerControl';
import { FeedbackButtonControl } from './controlTypes/FeedbackButtonControl';

export const ControlsSection = ({ controls, editNodeHandler }) => {
    const containerSize = useRef({
        width: 1920,
        height: 540,
    });

    return (
        <div
            className="border mb-1 p-2.5 flex gap-4 flex-wrap place-content-start bg-red-200"
            style={{ width: containerSize.current.width, maxHeight: containerSize.current.height, minHeight: '100px' }}
        >
            {controls &&
                controls.length !== 0 &&
                controls.map(control => (
                    <ControlBox editNodeHandler={editNodeHandler} key={control.cn_id} control={control}>
                        <span className="text-sm">{control.data.title}</span>

                        {control.lib_data.subtype === 'list' && <ListControl control={control} />}
                        {control.lib_data.subtype === 'button' && <ButtonControl control={control} />}
                        {control.lib_data.subtype === 'input' && <PresetValueControl control={control} />}
                        {control.lib_data.subtype === 'toggle' && <ToggleControl control={control} />}
                        {control.lib_data.subtype === 'trigger' && <TriggerControl />}
                        {control.lib_data.subtype === 'button_with_feedback' && <FeedbackButtonControl />}
                    </ControlBox>
                ))}
        </div>
    );
};
