import { url } from '../../../../../api/config';

export const Equipment = ({ el, src }) => {
    // Цвет оборудования, если есть какие-то проблемы с ним
    let statusColor = '';
    if (el.problem_classes.includes('bad_status')) {
        statusColor = 'filter-red';
    }

    return (
        <img
            data-tip={`${el.name}, Статус: ${el.state}`}
            alt={el.name}
            src={`${url}${src}`}
            className={`w-full h-full object-contain block ${statusColor}`}
        />
    );
};
