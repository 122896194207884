import { Toggle } from '../../../../../common/widgets/Toggle';

export const ToggleControl = ({ control }) => {
    return (
        <div className="flex items-center">
            <span className="text-sm mr-2">{control.data.values[0].title_off}</span>
            <Toggle disabled={true} />
            <span className="text-sm ml-2">{control.data.values[0].title_on}</span>
        </div>
    );
};
