import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { toastOptions } from '../../../../api/functions';

import { Loader } from '../../../common/Loader';
import { PicsLoader } from '../../../admin_scheduler/detail/PicsLoader';
import { createBMSDoc, set_document, update_bms_doc_list } from '../../../../redux/slices/bms/bmsSliceDocuments';

const SignupSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Поле "Название" слишком короткое').max(100, 'Поле "Название" слишком длинное').required('Поле "Название" обязательное'),
    description: Yup.string().min(2, 'Поле "Описание" слишком короткое').max(1000, 'Поле "Описание" слишком длинное'),
});

export const DocumentCreateForm = ({ setDocId }) => {
    const dispatch = useDispatch();
    const { venueId, systemId } = useParams();

    const [files, setFiles] = useState([]);

    const accept = { 'image/*': [] };

    const onSubmit = async values => {
        const data = { ...values, object_id: venueId, direction_id: systemId, background_image: files[0] };
        const result = await dispatch(createBMSDoc({ data }));

        if (!result.error) {
            const doc = {
                ...data,
                direction_id: +data.direction_id,
                id: result.id,
                venue_id: null,
                nodes: [],
                controls: [],
                background_image: result.background_image,
            };

            dispatch(set_document(doc));
            dispatch(update_bms_doc_list(doc));
            setDocId(result.id);
        } else {
            toast.error(result.msg, toastOptions);
        }
    };

    return (
        <Formik initialValues={{ title: '', description: '' }} onSubmit={onSubmit} validationSchema={SignupSchema}>
            {({ errors, isValidating, isSubmitting }) => {
                if (isSubmitting && !isValidating) {
                    for (const err in errors) {
                        toast.error(errors[err].toString(), toastOptions);
                    }
                }

                return (
                    <Form>
                        <div className="mb-6">
                            <div className="mb-1 text-sm font-bold">Название BMS системы*</div>
                            <Field
                                name="title"
                                type="text"
                                placeholder="Введите название BMS системы"
                                className="w-[33rem] h-12 p-2 border rounded box-border text-sm border-gray-10"
                            />
                        </div>
                        <div className="mb-6">
                            <div className="mb-1 text-sm font-bold">Описание BMS системы</div>
                            <Field
                                name="description"
                                as="textarea"
                                placeholder="Введите описание BMS системы"
                                className="w-[33rem] h-32 p-2 border rounded box-border text-sm border-gray-10 resize-none"
                            />
                        </div>

                        <div className="mt-4 mb-6 w-full ">
                            <div className="mb-1 text-sm font-bold">Фон системы</div>
                            <div className="flex gap-6">
                                <PicsLoader disabled={isSubmitting} files={files} setFiles={setFiles} maxFiles={1} accept={accept} />
                                <div>
                                    <div className="text-sm mb-2">Параметры фона:</div>
                                    <ul className="text-xs">
                                        <li> Форматы: jpg, jpeg, png, svg</li>
                                        <li> Объем: 20 Мб</li>
                                        <li>Размер картинки: 16 Х 9</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <button
                            disabled={isSubmitting}
                            type="submit"
                            className="bg-blue hover:bg-blue-500 transition-colors duration-200 text-white rounded-sm font-medium text-sm h-10 min-w-44 box-border px-4 flex justify-center items-center"
                        >
                            {isSubmitting ? <Loader height={20} width={20} color="#ffffff" /> : 'Продолжить'}
                        </button>
                    </Form>
                );
            }}
        </Formik>
    );
};
