export const VenueTitleInput = ({ setVenue, venue }) => {
    return (
        <input
            type="text"
            className="w-96 h-10 border-gray-70 hover:border-gray-30 text-sm px-2 mr-2"
            onChange={e => setVenue({ ...venue, title: e.target.value })}
            value={venue.title}
            placeholder="Наименование объекта"
        />
    );
};
