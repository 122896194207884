export const SlidingPaneHeader = ({ closePaneHandler, title, textRight = '' }) => {
    return (
        <div className="flex items-center gap-6 w-full sticky -top-6 -mt-6 bg-white z-20 py-6">
            <div className="font-bold text-black text-xl w-full">{title}</div>

            {!!textRight && <div className="shrink-0 mr-2 font-medium text-neutral-800">{textRight}</div>}

            <button className="w-9 h-9 p-0 rounded-full bg-transparent hover:bg-gray-100 flex items-center justify-center shrink-0" onClick={closePaneHandler}>
                <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                    <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                </svg>
            </button>
        </div>
    );
};
