import { CleanersFilterSection } from './cleanersFilter/CleanersFilterSection';
import { SwitchPanel } from './switchPanel/SwitchPanel';
import { ApplyButton } from './buttons/ApplyButton';
import { ResetButton } from './buttons/ResetButton';
import { SupervisorsButton } from './buttons/SupervisorsButton';
import { DistributeButton } from './buttons/DistributeButton';

export const SubHeader = ({ setSupervisorPaneIsOpen, activeSectionId, setActiveSectionId }) => {
    return (
        <div className="flex flex-col">
            <div className="w-full shrink-0 flex flex-row bg-white border-b border-b-gray-10 border-l border-l-gray-10">
                <div className="w-52 shrink-0 flex justify-center px-1.5 py-3 border-r border-r-gray-10 box-border">
                    {activeSectionId === 1 && <SupervisorsButton setSupervisorPaneIsOpen={setSupervisorPaneIsOpen} />}
                    {activeSectionId === 2 && <DistributeButton sectionId={activeSectionId} />}
                    {activeSectionId === 3 && <DistributeButton sectionId={activeSectionId} />}
                </div>

                <div className="flex gap-12 overflow-y-auto justify-between items-center w-full px-4 sm:px-6">
                    <SwitchPanel activeSectionId={activeSectionId} setActiveSectionId={setActiveSectionId} />

                    <div className="flex gap-3 py-3">
                        <ResetButton sectionId={activeSectionId} />
                        <ApplyButton sectionId={activeSectionId} />
                    </div>
                </div>
            </div>

            {activeSectionId === 2 && (
                <div className="w-full z-20 shrink-0 flex flex-row bg-white border-b border-b-gray-10 border-l border-l-gray-10">
                    <div className="w-52 h-full shrink-0 border-r border-r-gray-10 box-border"></div>
                    <div className="w-full h-full flex flex-row flex-wrap px-4 sm:px-6 py-3">
                        <CleanersFilterSection />
                    </div>
                </div>
            )}
        </div>
    );
};
