import { RadioGroup } from './RadioGroup';
import { InputValue } from './InputValue';
import { SelectPeriod } from './SelectPeriod';

export const DataSettings = ({ server, setServer, timeOptions }) => {
    return (
        <div className="mt-3">
            <p className="text-center ">Настройки хранения данных</p>
            <div>
                {server &&
                    server.data.typeSettings &&
                    server.data.typeSettings.map(type => {
                        return (
                            <div key={type.id} className="mt-3 text-sm flex items-center w-[450px] justify-between ml-auto mr-auto">
                                <span className="w-24">{type.type}</span>

                                <RadioGroup type={type} setData={setServer} data={server.data.typeSettings} />
                                <InputValue type={type} setData={setServer} data={server.data.typeSettings} />
                                <SelectPeriod type={type} setData={setServer} data={server.data.typeSettings} options={timeOptions} />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
