import { useEffect, useState } from 'react';

export const Toggle = ({ children, onChange, checked = false, disabled = false }) => {
    const [checkedState, setCheckedState] = useState(checked);

    useEffect(() => {
        setCheckedState(checked);
    }, [checked]);

    const changeHandler = () => {
        onChange(+!checkedState);
        setCheckedState(!checkedState);
    };

    return (
        <label className="relative flex items-center cursor-pointer select-none">
            <input disabled={disabled} type="checkbox" className="sr-only peer" checked={checkedState} onChange={changeHandler} />
            <div className="w-11 h-6 flex flex-row justify-around items-center rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all bg-gray-30 peer-checked:bg-blue">
                {children}
            </div>
        </label>
    );
};
