export const BitCheckbox = ({ nodeStatusInfo, callback }) => {
    return (
        <div className="w-[6%] shrink-0 px-1 flex justify-center">
            <input
                disabled={
                    !nodeStatusInfo.node ||
                    nodeStatusInfo.type === 'boolean' ||
                    nodeStatusInfo.type === 'float' ||
                    !nodeStatusInfo.bitsize
                }
                className="block cursor-pointer hover:border-gray-30"
                name="range"
                type="checkbox"
                value={nodeStatusInfo.bit}
                checked={nodeStatusInfo.bit}
                onChange={() => {}}
                onClick={e => callback('bit', e.target.checked)}
            />
        </div>
    );
};
