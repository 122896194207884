import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { listenForOutsideClick } from '../../../../../api/functions';
import { getSlots, objectState, slotsFetchingState, slotsState } from '../../../../../redux/slices/hospitalitySlice';

import { Loader } from '../../../../common/Loader';
import { CleanerWithTasksSlot } from '../../../slots/cleanerWithTasksSlot/CleanerWithTasksSlot';
import { TasksCounter } from './tasksCounter/TasksCounter';

export const SlotsSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const object = useSelector(objectState);
    const slots = useSelector(slotsState);
    const slotsFetching = useSelector(slotsFetchingState);

    const [isOpenCleanerMenu, setIsOpenCleanerMenu] = useState(null);
    const [listeningCleanerMenu, setListeningCleanerMenu] = useState(false);
    const [isOpenCleanerSelection, setIsOpenCleanerSelection] = useState(null);

    const activeCleanerMenuRef = useRef(null);

    useEffect(listenForOutsideClick(listeningCleanerMenu, setListeningCleanerMenu, activeCleanerMenuRef, () => setIsOpenCleanerMenu(null)));

    useEffect(() => {
        if (object) {
            dispatch(getSlots());
        }
    }, [object]);

    return (
        <>
            {slotsFetching ? (
                <div className="w-full flex justify-center items-center">
                    <Loader height={30} width={30} />
                </div>
            ) : (
                <div className="flex flex-col w-full h-full">
                    <TasksCounter />

                    <div className="flex flex-row flex-wrap gap-6 w-full box-border pt-3 pb-10 px-6 overflow-y-auto">
                        {slots.length ? (
                            slots.map(slot => (
                                <CleanerWithTasksSlot
                                    key={slot.key}
                                    item={slot}
                                    isOpenMenu={isOpenCleanerMenu}
                                    setIsOpenMenu={setIsOpenCleanerMenu}
                                    isOpenCleanerSelection={isOpenCleanerSelection}
                                    setIsOpenCleanerSelection={setIsOpenCleanerSelection}
                                    activeMenuRef={activeCleanerMenuRef}
                                />
                            ))
                        ) : (
                            <p className="font-medium text-gray-500 text-sm">{t('HOSPITALITY_NO_EMPLOYEES')}</p>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
