import { createSlice, createSelector } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../api/functions';
import { bmsAPI } from '../../../api/modules/bmsAPI';
import { store } from '../../store.ts';

export const bmsSliceSystems = createSlice({
    name: 'bmsSystems',

    initialState: {
        bms_system_list: [],
        bms_systems_fetching: false,
    },

    reducers: {
        set_bms_system_list: (state, action) => {
            state.bms_system_list = action.payload;
        },
        set_bms_system_IN_venue: (state, action) => {
            state.bms_system_list = state.bms_system_list.map(system => (system.id === action.payload ? { ...system, state: 1 } : system));
        },
        remove_bms_system_IN_venue: (state, action) => {
            state.bms_system_list = state.bms_system_list.map(system => (system.id === action.payload ? { ...system, state: 0 } : system));
        },
        clear_system_list: (state, _) => {
            state.bms_system_list = [];
        },
        set_bms_systems_fetching: (state, action) => {
            state.bms_systems_fetching = action.payload;
        },
    },
});

export const { set_bms_system_IN_venue, remove_bms_system_IN_venue, clear_system_list, set_bms_systems_fetching, set_bms_system_list } =
    bmsSliceSystems.actions;

// Получение списка систем объекта
export const getBMSSystemList =
    ({ venueId }) =>
    async dispatch => {
        dispatch(set_bms_systems_fetching(true));

        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;
        const lang = store.getState().login.lang;

        try {
            const response = await bmsAPI.getBMSSystemOfVenue.fetch(username, auth_key_calendar, venueId, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(set_bms_system_list(res.data));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method getBMSSystemList \n ${e}`, toastOptions);
        } finally {
            dispatch(set_bms_systems_fetching(false));
        }
    };

// Удаление системы из объекта
export const removeBMSSystemInVenue =
    ({ object_id, direction_id }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.removeBMSSystemInVenue.fetch(username, auth_key_calendar, object_id, direction_id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(remove_bms_system_IN_venue(direction_id));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method removeBMSSystemInVenue \n ${e}`, toastOptions);
        }
    };

// Добавление системы в объект
export const addBMSSystemInVenue =
    ({ object_id, direction_id }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.addBMSSystemInVenue.fetch(username, auth_key_calendar, object_id, direction_id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(set_bms_system_IN_venue(direction_id));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method addBMSSystemInVenue \n ${e}`, toastOptions);
        }
    };

export const active_system = createSelector(
    state => state.bmsSystems.bms_system_list,
    bms_system_list => bms_system_list.filter(system => system.state === 1)
);
export const not_active_system = createSelector(
    state => state.bmsSystems.bms_system_list,
    bms_system_list => bms_system_list.filter(system => system.state === 0)
);
export const bms_systems_fetching = state => state.bmsSystems.bms_systems_fetching;

export default bmsSliceSystems.reducer;
