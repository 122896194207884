import { CleanersToSupervisorsSection } from '../sections/cleanersToSupervisorsSection/CleanersToSupervisorsSection';
import { TasksToSlotsSection } from '../sections/tasksToSlotsSection/TasksToSlotsSection';
import { TasksToSupervisorsSection } from '../sections/tasksToSupervisorsSection/TasksToSupervisorsSection';

export const MainSection = ({ activeSectionId }) => {
    return (
        <>
            {activeSectionId === 1 && <CleanersToSupervisorsSection />}
            {activeSectionId === 2 && <TasksToSlotsSection />}
            {activeSectionId === 3 && <TasksToSupervisorsSection />}
        </>
    );
};
