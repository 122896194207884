import { url } from '../config';

export const newsAndPollsAPI = Object.freeze({
    // Получить список объектов
    getObjects: {
        fetch: (username, auth_key_calendar) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            return fetch(`${url}/api/v1/poll/get-objects`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список должностей
    getPositions: {
        fetch: (username, auth_key_calendar) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            return fetch(`${url}/api/v1/poll/get-staff-positions`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список сотрудников
    getUsers: {
        fetch: (username, auth_key_calendar) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            return fetch(`${url}/api/v1/poll/get-staffs`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Получить список новостей, опросов, соглашений
    getList: {
        fetch: (username, auth_key_calendar, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);

            for (const item in data) {
                if (data[item]) {
                    if (item === 'type') {
                        formData.append(item, JSON.stringify(data[item]));
                    } else if (item === 'searchLine') {
                        formData.append('search_string', data[item]);
                    } else {
                        formData.append(item, data[item]);
                    }
                }
            }

            return fetch(`${url}/api/v1/poll/get-list`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    // Создать новость/соглашение/опрос
    createItem: {
        fetch: (username, auth_key_calendar, lang, data) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            for (const item in data) {
                if (data[item]) {
                    if (item === 'data' || item === 'questions') {
                        formData.append(item, JSON.stringify(data[item]));
                    } else if (item === 'file') {
                        formData.append('file', data[item]);
                    } else if (item === 'image') {
                        formData.append('cover', data[item]);
                    } else if (item === 'dateActiveFrom') {
                        formData.append('date_active_from', data[item]);
                    } else if (item === 'dateActiveTo') {
                        formData.append('date_active_to', data[item]);
                    } else if (item === 'type') {
                        formData.append('nap_type', data[item]);
                    } else if (item === 'description') {
                        formData.append('nap_description', data[item]);
                    } else {
                        formData.append(item, data[item]);
                    }
                }
            }

            return fetch(`${url}/api/v1/poll/save-nap`, {
                method: 'POST',
                body: formData,
            });
        },
    },
});
