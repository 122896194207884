import { useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { createPortal } from 'react-dom';

export const PopupContextMenu = ({ children, isOpen, setIsOpen, buttonRef, className }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (buttonRef.current && ref.current && isOpen) {
            ref.current.style.top = `${
                buttonRef.current.getBoundingClientRect().top - ref.current.offsetHeight <= 300
                    ? buttonRef.current.getBoundingClientRect().bottom - 300 + ref.current.offsetHeight > window.innerHeight - 300
                        ? buttonRef.current.getBoundingClientRect().bottom - ref.current.offsetHeight / 2
                        : buttonRef.current.getBoundingClientRect().bottom
                    : buttonRef.current.getBoundingClientRect().top - ref.current.offsetHeight
            }px`;
            ref.current.style.left = `${
                buttonRef.current.getBoundingClientRect().left - ref.current.offsetWidth + buttonRef.current.getBoundingClientRect().width > 0
                    ? buttonRef.current.getBoundingClientRect().left + ref.current.offsetWidth > window.innerWidth - 48
                        ? window.innerWidth - 48 - ref.current.offsetWidth
                        : buttonRef.current.getBoundingClientRect().left - ref.current.offsetWidth + buttonRef.current.getBoundingClientRect().width
                    : 14
            }px`;
        }
    }, [ref, buttonRef, isOpen]);

    const classes = twMerge(
        `fixed bg-white z-40 w-[500px] max-w-full box-border rounded flex flex-col py-2 px-1 shadow-2xl transition-all duration-500 ease-in-out border border-gray-10 ${
            className ? className : ''
        }`
    );

    const closeHandler = () => {
        setIsOpen(false);
    };

    if (!isOpen) {
        return null;
    }

    return createPortal(
        <>
            <div className="fixed inset-0  z-40 transition-opacity duration-500 opacity-100" onClick={closeHandler}></div>
            <div className={classes} ref={ref}>
                {children}
            </div>
        </>,
        document.querySelector('#modal-root')
    );
};
