import { ButtonRectangular } from '../../../common/btn/ButtonRectangular';

export const DeleteVenuePopupButton = ({ disabled, onClick }) => {
    return (
        <ButtonRectangular disabled={disabled} className="w-40 ml-auto mr-auto bg-red-400 border-red-400 hover:bg-red-600 justify-center" onClick={onClick}>
            <svg width="18" height="18" viewBox="0 0 18 18" className="fill-current">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.75 6C13.1642 6 13.5 6.33579 13.5 6.75V14.25C13.5 15.4926 12.4926 16.5 11.25 16.5H6.75C5.50736 16.5 4.5 15.4926 4.5 14.25V6.75C4.5 6.33579 4.83579 6 5.25 6H12.75ZM12 7.5H6V14.25C6 14.6642 6.33579 15 6.75 15H11.25C11.6642 15 12 14.6642 12 14.25V7.5ZM6.75 2.25C6.75 1.83579 7.08579 1.5 7.5 1.5H10.5C10.9142 1.5 11.25 1.83579 11.25 2.25V3H14.25C14.6642 3 15 3.33579 15 3.75C15 4.16421 14.6642 4.5 14.25 4.5H3.75C3.33579 4.5 3 4.16421 3 3.75C3 3.33579 3.33579 3 3.75 3H6.75V2.25Z"
                />
            </svg>
            Удалить
        </ButtonRectangular>
    );
};
