export const DataPointControlCheckbox = ({ data, setData, setValues, initialState }) => {
    return (
        <div className="flex items-center mt-3">
            <span className="text-sm mr-2">Ответ по контрольной точке</span>
            <input
                disabled={!data.writePoint}
                className="cursor-pointer hover:border-gray-30"
                name="range"
                type="checkbox"
                value={data.isUseDataPointControl}
                checked={data.isUseDataPointControl}
                onChange={() => {}}
                onClick={e => {
                    setData({
                        ...data,
                        isUseDataPointControl: e.target.checked,
                        dataPointControl: {
                            ...initialState.dataPointControl,
                        },
                    });
                    setValues([
                        {
                            ...data,
                            isUseDataPointControl: e.target.checked,
                            dataPointControl: {
                                ...initialState.dataPointControl,
                            },
                        },
                    ]);
                }}
            />
        </div>
    );
};
