export const BitDataPointValue = ({ data, setBitPopupIsOpen, setBitData }) => {
    return (
        <div className="w-24 h-10 mr-5 text-xs flex items-center relative">
            Побитная маска
            <div
                onClick={() => {
                    setBitPopupIsOpen(true);
                    setBitData({
                        bitValue: data.dataPointControl.dataPointControlValue,
                        bitsize: data.dataPointControl.dataPointControlObj.bitSize,
                        field: 'dataPointControlValue',
                    });
                }}
            >
                <svg
                    width="512"
                    height="512"
                    viewBox="0 0 64 64"
                    className="fill-slate-600 w-4 h-4 hover:fill-green-500 cursor-pointer absolute -top-1 right-0 "
                >
                    <path d="M11.105 43.597a2 2 0 0 1-1.414-3.414L40.945 8.929a2 2 0 1 1 2.828 2.828L12.519 43.011c-.39.39-.902.586-1.414.586z" />
                    <path d="M8.017 58a2 2 0 0 1-1.957-2.42l3.09-14.403a2 2 0 1 1 3.911.839l-3.09 14.403A2 2 0 0 1 8.017 58zM22.418 54.91a2 2 0 0 1-1.414-3.414l31.254-31.253a2 2 0 1 1 2.828 2.828L23.833 54.324a1.994 1.994 0 0 1-1.415.586z" />
                    <path d="M8.013 58a2.001 2.001 0 0 1-.418-3.956l14.403-3.09a2 2 0 0 1 .839 3.911l-14.403 3.09a1.958 1.958 0 0 1-.421.045zM48.015 29.313a1.99 1.99 0 0 1-1.414-.586L35.288 17.414a2 2 0 1 1 2.828-2.828l11.313 11.313a2 2 0 0 1-1.414 3.414zM53.672 23.657a2 2 0 0 1-1.415-3.415c1.113-1.113 1.726-2.62 1.726-4.242s-.613-3.129-1.726-4.242c-1.114-1.114-2.621-1.727-4.243-1.727s-3.129.613-4.242 1.727a2 2 0 1 1-2.829-2.829c1.868-1.869 4.379-2.898 7.071-2.898 2.691 0 5.203 1.029 7.071 2.898 1.869 1.868 2.898 4.379 2.898 7.071s-1.029 5.203-2.898 7.071a1.99 1.99 0 0 1-1.413.586z" />
                </svg>
            </div>
        </div>
    );
};
