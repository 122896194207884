import React, { useMemo, memo } from 'react';
import { useTable } from 'react-table';

const TrendsTableComponent = ({ data }) => {
    const columns = useMemo(
        () => [
            {
                Header: 'Время',
                accessor: 'time',
            },
            ...data.values.map(el => ({
                Header: el.label.replace(/^Root\/Objects\/Data\//, ''),
                accessor: el.label,
            })),
        ],
        [data]
    );

    const tableData = useMemo(
        () =>
            data.data.map((el, id) => {
                const res = {};
                res.time = el;
                data.values.map(el => {
                    el.data[id][1]
                        ? ((res[el.label] = el.data[id][1].toFixed(2)), (res.id = el.data[id][0]))
                        : ((res[el.label] = 'данных нет'), (res.id = el.data[id][0]));
                });

                return res;
            }),
        [data]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: tableData });

    return (
        <div className="w-full overflow-x-auto h-96 mt-5" id="chartTable">
            <table {...getTableProps()} className="w-full relative border-separate border-spacing-0 text-sm">
                <thead className="text-black text-left" id="chartTableHead">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps()}
                                    className="sticky top-0 bg-white font-medium px-2 first:pl-4 last:pr-4 py-3 border-y border-gray-10 first:border-l first:rounded-tl-lg last:border-r last:rounded-tr-lg"
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <tr
                                id={row.original.id}
                                {...row.getRowProps()}
                                className="block md:table-row mb-4 md:mb-0 border md:border-0 border-gray-10 rounded-lg overflow-hidden md:overflow-visible"
                            >
                                {row.cells.map(cell => {
                                    let additionally = '';
                                    let styles = {};
                                    if (cell.column.id === 'time') {
                                        additionally = <div>{cell.value}</div>;
                                        styles = '!bg-slate-300 ';
                                    }
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            data-label={cell.column.Header}
                                            className={`font-normal flex justify-between md:table-cell text-right md:text-left before:content-[attr(data-label)] before:uppercase before:font-medium md:before:hidden py-1.5 px-2 md:first:pl-4 md:last:pr-4 border-b last:border-b-0 md:last:border-b border-gray-10 md:first:border-l md:last:border-r ${
                                                rows.length === index + 1 ? 'first:rounded-bl-lg last:rounded-br-lg' : ''
                                            } ${styles}`}
                                        >
                                            {additionally || cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export const TrendsTable = memo(TrendsTableComponent);
