export const OperatorAcceptButton = ({ setPopupState, id }) => {
    const handlerButtonClick = () => {
        setPopupState({ id, isOpen: true });
    };
    return (
        <button
            type="button"
            onClick={() => handlerButtonClick()}
            className="border rounded-lg bg-slate-50 py-0.5 px-1 text-xs hover:bg-green hover:text-white transition-color"
        >
            Подтвердить
        </button>
    );
};
