export const DataInput = ({ data, handlerInputChange, type, title }) => {
    return (
        <>
            <span className="text-sm mr-2">{title}:</span>
            <input
                type="number"
                value={data[type]}
                className="h-8 text-sm w-44"
                onChange={e => handlerInputChange(e.target.value, type)}
                disabled={!data.writePoint}
            />
        </>
    );
};
