export const PopupHead = ({ nodeStates }) => {
    const range = nodeStates.find(el => el.range);

    return (
        <div className="flex items-center text-sm leading-5 mb-4 font-semibold">
            <div className="w-[12%] shrink-0 pr-1">Статус</div>
            <div className="w-[6%] text-center shrink-0 px-1">Фото</div>
            <div className="w-[18%] shrink-0 px-1">Выбор точки</div>
            <div className="w-[6%] text-center shrink-0 px-1">Бит</div>
            <div className="w-[6%] text-center shrink-0 px-1">
                <p className="line-clamp-1">Диапазон значений</p>
            </div>
            <div className="w-[9%] shrink-0 px-1">Значение {range && '(Мин.)'}</div>
            <div className="w-[9%] shrink-0 px-1">Значение (Макс.)</div>
            <div className="w-[14%] shrink-0 px-1">Наименование точки</div>
            <div className="w-[5%] text-center shrink-0 px-1">Kaizen</div>
            <div className="w-[5%] text-center shrink-0 px-1">Email</div>
            <div className="w-[5%] text-center shrink-0 px-1">Push</div>
            <div className="w-[5%] shrink-0"></div>
        </div>
    );
};
