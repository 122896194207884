import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useCheckRole } from '../../../../hooks/useCheckRole';

import { Popup } from '../../../common/popup/Popup';
import { ServerPopupButton } from './ServerPopupButton';
import { RemoveServerPopupButton } from './RemoveServerPopupButton';
import { OPCFields } from '../OPCFields';
import { VPNFields } from '../VPNFields';
import { DataSettings } from '../dataSettings/DataSettings';
import { PopupActionConfirmation } from '../../document/edit/PopupActionConfirmation';

import {
    addBMSServer,
    bmsDataTypeTimeIntervalsState,
    bmsDataTypesFromOpsServerState,
    bms_servers_list as bms_servers_list_state,
    editBMSServer,
    getBMSTypesFromOpsServer,
    getBMSTypesTimeInterval,
    removeBMSServer,
} from '../../../../redux/slices/bms/bmsSliceServers';

const initValue = {
    title: '',
    is_enabled: '',
    data: {
        port: '',
        server: '',
        password: '',
        username: '',
        vpn_port: '',
        vpn_server: '',
        vpn_password: '',
        vpn_username: '',
        typeSettings: [],
    },
};

export const ServerPopup = ({ isOpen, setIsOpen, serverId, setServerId }) => {
    const dispatch = useDispatch();
    const bms_servers_list = useSelector(bms_servers_list_state);
    const bmsDataTypeTimeIntervals = useSelector(bmsDataTypeTimeIntervalsState);
    const bmsDataTypesFromOpsServer = useSelector(bmsDataTypesFromOpsServerState);

    const [server, setServer] = useState(initValue);
    const [isOpenActionPopup, setIsOpenActionPopup] = useState(false);

    const isServersDelete = useCheckRole('bms', 'bms.servers.del');

    useEffect(() => {
        if (serverId && bms_servers_list.length !== 0) {
            let server = bms_servers_list.find(server => server.id === serverId);

            if (!server.data.hasOwnProperty('typeSettings')) {
                server = { ...server, data: { ...server.data, typeSettings: bmsDataTypesFromOpsServer } };
            }
            setServer(server);
            setIsOpen(true);
        }

        if (!bmsDataTypeTimeIntervals.length) {
            dispatch(getBMSTypesTimeInterval());
        }

        if (!bmsDataTypesFromOpsServer.length) {
            dispatch(getBMSTypesFromOpsServer());
        }
    }, [bms_servers_list, serverId]);

    useEffect(() => {
        if (server.data.typeSettings.length === 0) {
            setServer({ ...initValue, data: { ...initValue.data, typeSettings: bmsDataTypesFromOpsServer } });
        }

        if (!isOpen) {
            setServerId(null);
            setServer(initValue);
        }
    }, [isOpen]);

    const handleEditServer = () => {
        serverId ? dispatch(editBMSServer({ data: server })) : dispatch(addBMSServer({ data: server }));
        setIsOpen(false);
        setServerId(null);
        setServer(initValue);
    };

    const handleRemoveServer = () => {
        dispatch(removeBMSServer({ id: serverId }));
        setIsOpen(false);
        setServerId(null);
        setServer(initValue);
        setIsOpenActionPopup(false);
    };

    return (
        <>
            <Popup isOpen={isOpen} setIsOpen={setIsOpen} className="w-[700px] h-[95%] overflow-auto">
                <div>
                    <div className="flex flex-col items-center mb-4">
                        <label className="block mb-2">Название сервера</label>
                        <input
                            onInput={e => setServer({ ...server, title: e.target.value })}
                            value={server.title}
                            placeholder="введите название сервера"
                            type="text"
                            className="w-96 h-10 border-gray-70 hover:border-gray-30 text-sm px-2 mr-2"
                        />
                    </div>
                    <div className="flex">
                        <div className="w-1/2 flex flex-col items-center">
                            <OPCFields server={server} setServer={setServer} />
                        </div>
                        <div className="w-1/2 flex flex-col items-center">
                            <VPNFields server={server} setServer={setServer} />
                        </div>
                    </div>

                    <DataSettings server={server} setServer={setServer} timeOptions={bmsDataTypeTimeIntervals} />
                </div>
                <div className="flex justify-center gap-8">
                    <ServerPopupButton
                        title={`${serverId ? 'Сохранить' : 'Создать'}`}
                        onClick={handleEditServer}
                        disabled={server.title.length === 0 || server.data?.port.length === 0 || server.data?.server.length === 0}
                    />
                    {isServersDelete && serverId && <RemoveServerPopupButton onClick={() => setIsOpenActionPopup(true)} />}
                </div>
            </Popup>
            <PopupActionConfirmation
                isOpen={isOpenActionPopup}
                setIsOpen={setIsOpenActionPopup}
                callback={handleRemoveServer}
                element={server.title}
                title="Вы действительно хотите удалить сервер"
            />
        </>
    );
};
