import { useTranslation } from 'react-i18next';

import { Button } from '../../../ui/button/Button.tsx';

export const SupervisorsButton = ({ setSupervisorPaneIsOpen }) => {
    const { t } = useTranslation();

    return (
        <Button type="success" className="w-full" onClick={() => setSupervisorPaneIsOpen(true)}>
            {t('HOSPITALITY_ADD_SUPERVISORS')}
        </Button>
    );
};
