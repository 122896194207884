import { SimpleSelect } from '../../../../../common/widgets/SimpleSelect';

export const BooleanValueSelect = ({ nodeStatusInfo, callback }) => {
    return (
        <SimpleSelect
            isClearable={false}
            options={[
                { value: 1, label: 'true' },
                { value: 0, label: 'false' },
            ]}
            placeholder="Выберите значение"
            value={nodeStatusInfo.value}
            callback={val => callback(val)}
            className="w-full"
        />
    );
};
