import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SlidingPane from 'react-sliding-pane';
import moment from 'moment';

import { getFileFromUrl, toastOptions } from '../../../../api/functions';
import {
    createItem,
    createItemFetchingState,
    getObjects,
    getPolls,
    getPositions,
    getUsers,
    pollsFilterState,
    setObjects,
    setPositions,
    setUsers,
} from '../../../../redux/slices/newsAndPollsSlice';

import { SlidingPaneHeader } from '../../../common/slidingPane/SlidingPaneHeader';
import { SlidingPaneFooter } from '../../../common/slidingPane/SlidingPaneFooter';
import { Button } from '../../../ui/button/Button.tsx';
import { Step1 } from './Step1';
import { Step2 } from './Step2';

export const CreatePollPane = ({ isOpen, setIsOpen, item, setItem }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const now = new Date();

    const [step, setStep] = useState('1');
    const [selectedObjects, setSelectedObjects] = useState([]);
    const [selectedPositions, setSelectedPositions] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [experience, setExperience] = useState({ from: '', to: '', period: '' });
    const [periodOfActivity, setPeriodOfActivity] = useState({
        from: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
        to: new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()),
    });
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [questions, setQuestions] = useState([]);

    const createItemFetching = useSelector(createItemFetchingState);
    const filter = useSelector(pollsFilterState);

    useEffect(() => {
        (async () => {
            if (isOpen) {
                await dispatch(getObjects());
                await dispatch(getPositions());
                await dispatch(getUsers());

                if (item) {
                    setSelectedObjects(item.objects);
                    setSelectedPositions(item.positions);
                    setSelectedUsers(item.users);
                    if (item.experience) {
                        setExperience(item.experience);
                    }
                    setPeriodOfActivity({
                        from: new Date(item.dateFrom),
                        to: new Date(item.dateTo),
                    });
                    setTitle(item.title);
                    setDescription(item.description);

                    const match = item.file.match(/\/([^\/?#]+\.[a-zA-Z0-9]+)$/);
                    if (item.file) {
                        getFileFromUrl(item.file, match[1] || 'file').then(file => {
                            setFile(file);
                        });
                    }
                    setQuestions(item.questions);
                }
            }
        })();
    }, [isOpen]);

    const closePaneHandler = () => {
        setIsOpen(false);
    };

    return (
        <SlidingPane
            isOpen={isOpen}
            hideHeader={true}
            onRequestClose={closePaneHandler}
            overlayClassName="z-40 bg-slate-900 bg-opacity-30 transition-opacity duration-200 opacity-100"
            width="58rem"
            onAfterClose={() => {
                setStep('1');
                setSelectedObjects([]);
                setSelectedPositions([]);
                setSelectedUsers([]);
                setExperience({ from: '', to: '', period: '' });
                setPeriodOfActivity({
                    from: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
                    to: new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()),
                });
                setTitle('');
                setDescription('');
                setFile(null);
                setQuestions([]);
                setItem(null);
                dispatch(setObjects([]));
                dispatch(setPositions([]));
                dispatch(setUsers([]));
            }}
        >
            <SlidingPaneHeader closePaneHandler={closePaneHandler} title={!item && t('NEWS_AND_POLLS_CREATE_POLL')} textRight={`${t('STEP')}: ${step}/2`} />

            {step === '1' && (
                <Step1
                    selectedObjects={selectedObjects}
                    selectedPositions={selectedPositions}
                    selectedUsers={selectedUsers}
                    experience={experience}
                    periodOfActivity={periodOfActivity}
                    setSelectedObjects={setSelectedObjects}
                    setSelectedPositions={setSelectedPositions}
                    setSelectedUsers={setSelectedUsers}
                    setExperience={setExperience}
                    setPeriodOfActivity={setPeriodOfActivity}
                    isDisabled={item && item.id}
                />
            )}

            {step === '2' && (
                <Step2
                    title={title}
                    description={description}
                    file={file}
                    questions={questions}
                    setTitle={setTitle}
                    setDescription={setDescription}
                    setFile={setFile}
                    setQuestions={setQuestions}
                    isDisabled={item && item.id}
                />
            )}

            <SlidingPaneFooter closePaneHandler={closePaneHandler}>
                {step === '1' && (
                    <>
                        <Button
                            type="success"
                            onClick={() => setStep('2')}
                            disabled={
                                !item &&
                                (!selectedUsers.filter(item => {
                                    const period = experience.period ? +experience.period : 1;
                                    let disabled = false;

                                    if (typeof experience.from === 'number' && experience.from * period > +item.experience) {
                                        disabled = true;
                                    }
                                    if (typeof experience.to === 'number' && experience.to * period < +item.experience) {
                                        disabled = true;
                                    }
                                    if (typeof experience.to === 'number' && typeof experience.from === 'number' && experience.to < experience.from) {
                                        disabled = false;
                                    }

                                    return !disabled;
                                }).length ||
                                    !periodOfActivity.from)
                            }
                        >
                            {t('CONTINUE')}
                        </Button>
                        <Button type="link" onClick={closePaneHandler}>
                            {t('CANCEL')}
                        </Button>
                    </>
                )}

                {step === '2' && (
                    <>
                        {!item && (
                            <Button
                                type="success"
                                onClick={async () => {
                                    const period = experience.period ? +experience.period : 1;

                                    const data = {
                                        data: {
                                            objects: selectedObjects.map(item => ({ id: item.id, name: item.name })),
                                            positions: selectedPositions.map(item => ({ id: item.id, name: item.name })),
                                            users: selectedUsers
                                                .filter(item => {
                                                    let disabled = false;

                                                    if (typeof experience.from === 'number' && experience.from * period > +item.experience) {
                                                        disabled = true;
                                                    }
                                                    if (typeof experience.to === 'number' && experience.to * period < +item.experience) {
                                                        disabled = true;
                                                    }
                                                    if (
                                                        typeof experience.to === 'number' &&
                                                        typeof experience.from === 'number' &&
                                                        experience.to < experience.from
                                                    ) {
                                                        disabled = false;
                                                    }

                                                    return !disabled;
                                                })
                                                .map(item => ({ id: item.id, name: item.name })),
                                            experience: experience,
                                        },
                                        title: title,
                                        description: description,
                                        file: file || null,
                                        type: 'p',
                                        dateActiveFrom: periodOfActivity.from ? moment(periodOfActivity.from).format('YYYY-MM-DD HH:mm:ss') : '',
                                        dateActiveTo: periodOfActivity.to ? moment(periodOfActivity.to).format('YYYY-MM-DD HH:mm:ss') : '',
                                        questions,
                                    };

                                    const res = await dispatch(createItem({ data }));

                                    if (res.error) {
                                        toast.error(res.message, toastOptions);
                                    } else {
                                        closePaneHandler();
                                        toast.success(res.message, toastOptions);
                                        dispatch(getPolls(filter));
                                    }
                                }}
                                disabled={!title || !questions.length}
                                loading={createItemFetching}
                            >
                                {t('SUBMIT')}
                            </Button>
                        )}
                        <Button type="link" onClick={() => setStep('1')}>
                            {t('BACK')}
                        </Button>
                    </>
                )}
            </SlidingPaneFooter>
        </SlidingPane>
    );
};
